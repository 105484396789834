import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import {
  PageDesignConstants,
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
  DefaultDate,
  SupportiveCareEpcoritamab
} from "../../../Helpers/Enums";
import SideBarMenu from "../../../Components/SideBarMenu";
import ErrorField from "../../../Components/ErrorField";
//import { CommonError_Msgs, demographicScreen_Msgs } from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  AllowOnlyIntegers,
  DisplaySelectedText,
  GetDMUID,
  GetDisplayDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  GetOrdinalInWord,
  IsCARTCellTherapySelected,
  IsDateEmpty,
  IsDateValid,
  IsValidDate1,
  Is_DateBefore,
  ParseDate,
  SetDateState,
  ValueInRange,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import DateControl from "../../../Components/DateControl";
import { useLocation, useParams } from "react-router-dom";
import {
  CommonError_Msgs,
  Epcoritamab_Msgs,
  HRU_MSGS,
  TreatmentPriorEpco_Msgs,
} from "../../../Helpers/HelperText";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";
//import DateDropdown from 'react-date-dropdown';

const TreatmentsPriorEpcoritamab = () => {
  const { lot } = useParams();
  const { appState, ShowCartPage } = useContext(AppContext);
  const { tmtPriorEpcoLOTCnt } = appState ?? 0;

  const prevLot = Number(lot) - 1;
  const nextLot = Number(lot) + 1;
  const isFirstLot = lot == 1;
  const isLastLot = tmtPriorEpcoLOTCnt == lot;
  // const location = useLocation();
  // const { lot } = location?.state ?? false;

  const navigatePaths = {
    prevPage: isFirstLot
      ? "/Epcoritamab"
      : `/TreatmentsPriorEpcoritamab/${prevLot}`,
    currPage: `/TreatmentsPriorEpcoritamab/${lot}`,
    nextPage: isLastLot
      ? "/TreatmentsAfterEpcoritamab/1"
      : `/TreatmentsPriorEpcoritamab/${nextLot}`,
  };
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } =
    useContext(AppContext);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const patientId = GetLocalStorageData("patientId") ?? 0;
  const [LBCL_dx_date, setLBCL_dx_date] = useState("[LBCL_dx_date]");
  const [index_date, setIndexDate] = useState("[index_date]");


        //New Chanegs Q74.
        const [medicationTypes, setMedicationTypes] = useState([]);
        const [otherInput, setOtherInput] = useState("");
        const [otherInputs, setOtherInputs] = useState({});
        const [subOtherInputs, setSubOtherInputs] = useState("");
        const [radioSelection, setRadioSelection] = useState("");
        const [selectedOptions, setSelectedOptions] = useState({
            parent: {},
            sub: {},
            subSub: {},
          });
    
      
    
    
          const initialSelections = (patientTreatment, treatmentMedicationBridges) => {
           
        
            if (
              patientTreatment.medicationNone === null &&
              patientTreatment.medicationUnk === null
            ) {
              const newSelectedOptions = { parent: {}, sub: {}, subSub: {} };
              const newOtherInputs = { parent: "", sub: "" };
        
              treatmentMedicationBridges.forEach((bridge) => {
                newSelectedOptions.parent[bridge.medicationTypeId] = true;
        
                if (bridge.medicationTypeId === 8 && bridge.medicationTypeOth) {
                  newOtherInputs.parent = bridge.medicationTypeOth;
                  setOtherInput(bridge.medicationTypeOth);
                  // setSubOtherInput(value);
                }
        
                if (bridge.subMedicationTypeId) {
                  if (!newSelectedOptions.sub[bridge.medicationTypeId]) {
                    newSelectedOptions.sub[bridge.medicationTypeId] = {};
                  }
                  newSelectedOptions.sub[bridge.medicationTypeId][
                    bridge.subMedicationTypeId
                  ] = true;
        
                  if (bridge.subMedicationTypeId === 6 && bridge.subMedicationTypeOth) {
                    newOtherInputs.sub = bridge.subMedicationTypeOth;
                    setSubOtherInputs(bridge.subMedicationTypeOth);
                  }
                }
        
                if (bridge.subSubMedicationTypeId) {
                  if (!newSelectedOptions.subSub[bridge.subMedicationTypeId]) {
                    newSelectedOptions.subSub[bridge.subMedicationTypeId] = {};
                  }
                  newSelectedOptions.subSub[bridge.subMedicationTypeId][
                    bridge.subSubMedicationTypeId
                  ] = true;
                }
              });
             
              setSelectedOptions(newSelectedOptions);
              setOtherInputs(newOtherInputs);
            } else {
              if (patientTreatment.medicationNone) {
                setRadioSelection("None");
              } else {
                setRadioSelection("Unknown");
              }
            }
          };

  const InitialSoftErrList = {
    q49_tmt_received_unk: false,
    q51_apheresis_date_unk: false,
    q52_receive_bridging_therapy_unk: false,
    q53_bridging_therapy_unk: false,
    q54_initiate_bridging_therapy_date_unk: false,
    q55_bridging_therapy_cycles_unk: false,
    q56_receive_lympho_therapy_unk: false,
    q57_tmt_lympho_therapy_unk: false,
    q58_initiate_lympho_therapy_date_unk: false,
    q59_initiate_q49_tx_date_unk: false,
    q60_ipi_risk_score_unk: false,
    q61_ipi_risk_score_date_unk: false,
    q62_discontinue_q49_tx_date_unk: false,
    q63_last_known_admin_q49_tx_date_unk: false,
    dosesOfTocilizumabNumOfDoses_unk: false,
    epco_parent_unknown: false,
    epco_doses_of_tocilizumab_unknown: false,
  };
  const InitialHardErrList = {
    q49_tmt_received_empty: false,
    q49_tmt_received_oth_txt_empty: false,
    q50_cart_type_empty: false,
    q50_cart_type_oth_txt_empty: false,
    q51_apheresis_empty: false,
    q51_apheresisDateValid:false,
    q52_receive_bridging_therapy_empty: false,
    q53_bridging_therapy_empty: false,
    q53_bridging_therapy_oth_txt_empty: false,
    q54_initiate_bridging_therapy_empty: false,
    q54_initiate_bridging_therapy_dateValidate:false,
    q55_bridging_therapy_cycles_empty: false,
    q55_bridging_therapy_cycles_range_limit: false,
    q55_bridging_therapy_cycles_txt_empty: false,
    q56_receive_lympho_therapy_empty: false,
    q57_tmt_lympho_therapy_empty: false,
    q57_tmt_lympho_therapy_oth_txt_empty: false,
    q58_initiate_lympho_therapy_empty: false,
    q58_initiate_lympho_therapy_datevalidate:false,
    q59_initiate_q49_tx_empty: false,
    q59_initiate_before_index_date: false,
    q60_ipi_risk_score_empty: false,
    q61_ipi_risk_score_date_empty: false,
    q61_ipi_risk_score_datevalidate :false,
    q62_discontinue_q49_tx_date_empty: false,
    q62_discontinue_after_q59_date: false,
    q63_last_known_admin_q49_tx_date_empty: false,
    q63_last_known_admin_after_q59_date: false,
    dosesOfTocilizumabNumOfDoses_empty: false,
    //Q74. checkbox hard error validation
    selectedOptions_Empty : false,
    selectedOptions_Sub_Empty : false,
    // selectedOptions_SubSub_Empty: false,

    [SupportiveCareEpcoritamab.Dexamethasone] : false,
    [SupportiveCareEpcoritamab.Hydrocortisone] : false,
    [SupportiveCareEpcoritamab.Prednisone] : false,
    [SupportiveCareEpcoritamab.Prednisolone] : false,
    [SupportiveCareEpcoritamab.Methylprednisolone] : false,
    
    [SupportiveCareEpcoritamab.Other] : false,
    
    epco_parent_othertext_empty: false,
    epco_sub_othertext_empty: false,


    //Q75. hard error message
    dosesOfTocilizumabNumOfDoses_Empty: false,
  };
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const InitialQ49TmtReceivedChkBoxFields = {
    chk_q49_anti_cd20_mab: false,
    chk_q49_anthracyclines: false,
    chk_q49_alkylating_agents: false,
    chk_q49_autologous_stem_cell_transplant: false,
    chk_q49_allogenic_stem_cell_transplant: false,
    chk_q49_car_t_cell_therapy: false,
    chk_q49_bendamustine: false,
    chk_q49_glofitamab: false,
    chk_q49_imvotamab: false,
    chk_q49_lenalidomide: false,
    chk_q49_loncastatuximab: false,
    chk_q49_mosunetuzumab: false,
    chk_q49_odronextamab: false,
    chk_q49_plamotamab: false,
    chk_q49_polatuzumab_vedotin: false,
    chk_q49_rituximab: false,
    chk_q49_selinexor: false,
    chk_q49_tafasitamab: false,
    chk_q49_tmt_received_oth: false,
    txt_q49_tmt_received_oth: "",

    chk_q49_car_t_cell_therapy_before: false,
  };
  const InitialQ53BridgingTherapy = {
    chk_q53_acalabrutinib: false,
    chk_q53_cisplatin: false,
    chk_q53_cyclophosphamide: false,
    chk_q53_cytarabine: false,
    chk_q53_dexamethasone: false,
    chk_q53_doxorubicin: false,
    chk_q53_etoposide: false,
    chk_q53_fludarabine: false,
    chk_q53_gemcitabine: false,
    chk_q53_hydroxyurea: false,
    chk_q53_ibrutinib: false,
    chk_q53_idarubicin: false,
    chk_q53_lenalidomide: false,
    chk_q53_mercaptopurine: false,
    chk_q53_methotrexate: false,
    chk_q53_rituximab: false,
    chk_q53_steroids: false,
    chk_q53_tyrosine: false,
    chk_q53_vincristine: false,
    chk_q53_bridging_therapy_oth: false,
    txt_q53_bridging_therapy_oth: "",
  };
  const [tpeDetails, setTPEDetails] = useState({
    lotId: 0,
    ...InitialQ49TmtReceivedChkBoxFields,
    rb_q49_tmt_received_unk: false,
    chk_q50_axicabtagene: false,
    chk_q50_brexucabtagene: false,
    chk_q50_ciltacabtagene: false,
    chk_q50_idecabtagene: false,
    chk_q50_lisocabtagene: false,
    chk_q50_tisagenlecleucel: false,
    chk_q50_cart_type_oth: false,
    txt_q50_cart_type_oth: "",
    rbl_q51_apheresis: null,
    q51_apheresis_date: DefaultDate,
    rbl_q52_receive_bridging_therapy: null,
    ...InitialQ53BridgingTherapy,
    rb_q53_bridging_therapy_unk: false,
    rbl_q54_initiate_bridging_therapy: null,
    q54_initiate_bridging_therapy_date: DefaultDate,
    rbl_q55_bridging_therapy_cycles: null,
    txt_q55_bridging_therapy_cycles: "",
    rbl_q56_receive_lympho_therapy: null,
    chk_q57_cyclo: false,
    chk_q57_fluda: false,
    chk_q57_oth: false,
    txt_q57_oth: "",
    rb_q57_tmt_lympho_therapy_unk: false,
    rbl_q58_initiate_lympho_therapy: null,
    q58_initiate_lympho_therapy_date: DefaultDate,
    rbl_q59_initiate_q49_tx: null,
    q59_initiate_q49_tx_date: DefaultDate,
    rbl_q60_ipi_risk_score: null,
    rbl_q61_ipi_risk_score_date: null,
    q61_ipi_risk_score_date: DefaultDate,
    rbl_q62_discontinue_q49_tx: null,
    q62_discontinue_q49_tx_date: DefaultDate,
    rbl_q63_last_known_admin_date: null,
    q63_last_known_admin_date: DefaultDate,
    dosesOfTocilizumabNumOfDoses: null,
  });
  //Q64-68 Radiological Assessment rpt
  const RadioAssessmentRow = {
    lotRadiologicalAssessmentId: 0,
    //lotId: 0,

    rbl_q64_radio_assess: null,
    q64_radio_assess_date: DefaultDate,
    rbl_q65_lbcl_status: null,
    txt_q65_lbcl_status_oth: "",
    rbl_q66_criteria: null,
    rbl_q67_lyric_assess: null,
    rbl_q68_radiology_type: null,
    txt_q68_radiology_type_oth: "",

    q64_radio_assess_date_unk_soft: false,
    q65_lbcl_status_unk_soft: false,
    q68_radiology_type_unk_soft: false,

    q64_after_q59_hard: false,
    q64_before_q62_hard: false,
    q64_radio_assess_date_empty_hard: false,
    q65_lbcl_status_empty_hard: false,
    q65_lbcl_status_oth_txt_empty_hard: false,
    q66_criteria_empty_hard: false,
    q67_lyric_assess_empty_hard: false,
    q68_radiology_type_empty_hard: false,
    q68_radiology_type_oth_txt_empty_hard: false,
  };

  const DoseOfTocilizumabRow = {
    lotsitecarebridgeid:0,
   // lotid:0,
    siteOfCare_rd: null,
    siteOfCare_other: "",
    siteOfCare_rd_empty: false,
    siteOfCare_other_empty: false,
    siteOfCare_other_unk_soft: false,
  };

  const DeleteDoseOfTocilizumabRow = (id) => {
    const newArray = [...doseOfTocilizumabDetails];
    newArray.splice(id, 1);
    setDoseOfTocilizumabDetails(newArray);
  };
  const AddDoseOfTocilizumabRow = () => {
    setDoseOfTocilizumabDetails((prev) => [
      ...prev,
      {
        ...DoseOfTocilizumabRow,
      },
    ]);
  };
  const [doseOfTocilizumabDetails, setDoseOfTocilizumabDetails] = useState([
    DoseOfTocilizumabRow,
  ]);
  const [radioAssessmentDetails, setRadioAssessmentDetails] = useState([
    RadioAssessmentRow,
  ]);

  const [selectedReceivedTmts, setSelectedReceivedTmts] = useState(new Map());
  const SelReceivedTmtsDefaultText = "[treatment]";
  const [selectedReceivedTmtsStr, setSelectedReceivedTmtsStr] = useState(
    SelReceivedTmtsDefaultText
  );
  const HandleFieldChange = (e) => {
    const { name, id, checked, value } = e.target;
    const id_int = Number(id);
    switch (name) {
      //checkbox
      case "chk_q49_anti_cd20_mab":
      case "chk_q49_anthracyclines":
      case "chk_q49_alkylating_agents":
      case "chk_q49_autologous_stem_cell_transplant":
      case "chk_q49_allogenic_stem_cell_transplant":
      case "chk_q49_bendamustine":
      case "chk_q49_glofitamab":
      case "chk_q49_imvotamab":
      case "chk_q49_lenalidomide":
      case "chk_q49_loncastatuximab":
      case "chk_q49_mosunetuzumab":
      case "chk_q49_odronextamab":
      case "chk_q49_plamotamab":
      case "chk_q49_polatuzumab_vedotin":
      case "chk_q49_rituximab":
      case "chk_q49_selinexor":
      case "chk_q49_tafasitamab":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            rb_q49_tmt_received_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q49_tmt_received_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q49_tmt_received_empty: false,
          }));
          if (checked) {
            // Add if checked
            setSelectedReceivedTmts(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          } else {
            // Remove if unchecked
            selectedReceivedTmts.delete(id_int);
            setSelectedReceivedTmts(new Map(selectedReceivedTmts));
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          }
        }
        break;
      case "chk_q49_car_t_cell_therapy":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            rb_q49_tmt_received_unk: false,
            chk_q50_axicabtagene: false,
            chk_q50_brexucabtagene: false,
            chk_q50_ciltacabtagene: false,
            chk_q50_idecabtagene: false,
            chk_q50_lisocabtagene: false,
            chk_q50_tisagenlecleucel: false,
            chk_q50_cart_type_oth: false,
            txt_q50_cart_type_oth: "",
            rbl_q51_apheresis: null,
            q51_apheresis_date: DefaultDate,
            rbl_q52_receive_bridging_therapy: null,
            rbl_q56_receive_lympho_therapy: null,

            ...InitialQ53BridgingTherapy,
            rb_q53_bridging_therapy_unk: false,
            rbl_q54_initiate_bridging_therapy: null,
            q54_initiate_bridging_therapy_date: DefaultDate,
            rbl_q55_bridging_therapy_cycles: null,
            txt_q55_bridging_therapy_cycles: "",
            chk_q57_cyclo: false,
            chk_q57_fluda: false,
            chk_q57_oth: false,
            txt_q57_oth: "",
            rb_q57_tmt_lympho_therapy_unk: false,
            rbl_q58_initiate_lympho_therapy: null,
            q58_initiate_lympho_therapy_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q49_tmt_received_unk: false,
            q51_apheresis_date_unk: false,
            q52_receive_bridging_therapy_unk: false,
            q56_receive_lympho_therapy_unk: false,

            q53_bridging_therapy_unk: false,
            q54_initiate_bridging_therapy_date_unk: false,
            q55_bridging_therapy_cycles_unk: false,
            q57_tmt_lympho_therapy_unk: false,
            q58_initiate_lympho_therapy_date_unk: false,
            q59_initiate_q49_tx_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q49_tmt_received_empty: false,
            q50_cart_type_empty: false,
            q50_cart_type_oth_txt_empty: false,
            q51_apheresis_empty: false,
            q51_apheresisDateValid:false,
            q52_receive_bridging_therapy_empty: false,
            q56_receive_lympho_therapy_empty: false,

            q53_bridging_therapy_empty: false,
            q53_bridging_therapy_oth_txt_empty: false,
            q54_initiate_bridging_therapy_empty: false,
            q54_initiate_bridging_therapy_dateValidate:false,
            q55_bridging_therapy_cycles_empty: false,
            q55_bridging_therapy_cycles_range_limit: false,
            q55_bridging_therapy_cycles_txt_empty: false,
            q57_tmt_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_oth_txt_empty: false,
            q58_initiate_lympho_therapy_empty: false,
            q58_initiate_lympho_therapy_datevalidate:false,
          }));
          if (checked) {
            // Add if checked
            setSelectedReceivedTmts(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          } else {
            // Remove if unchecked
            selectedReceivedTmts.delete(id_int);
            setSelectedReceivedTmts(new Map(selectedReceivedTmts));
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          }
        }
        break;
      //checkbox with text
      case "chk_q49_tmt_received_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            txt_q49_tmt_received_oth: "",
            rb_q49_tmt_received_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q49_tmt_received_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q49_tmt_received_empty: false,
            q49_tmt_received_oth_txt_empty: false,
          }));
          //if (checked) {
          // Add if checked
          //setSelectedReceivedTmts(new Map(selectedReceivedTmts).set(id_int, value));
          //const selTmtStr = DisplaySelectedText(new Map(selectedReceivedTmts).set(id_int, value))
          //  if (isStringEmpty(selTmtStr)) {
          //    setSelectedReceivedTmtsStr(SelReceivedTmtsDefaultText)
          //  } else {
          //    setSelectedReceivedTmtsStr(selTmtStr)
          //  }
          //} else {
          // Remove if unchecked
          selectedReceivedTmts.delete(id_int);
          setSelectedReceivedTmts(new Map(selectedReceivedTmts));
          const selTmtStr = DisplaySelectedText(new Map(selectedReceivedTmts));
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
          //}
        }
        break;
      //text
      case "txt_q49_tmt_received_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value,
            chk_q49_tmt_received_oth: true,
            rb_q49_tmt_received_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q49_tmt_received_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q49_tmt_received_empty: false,
            q49_tmt_received_oth_txt_empty: false,
          }));
          if (!isStringEmpty(value)) {
            // Add if checked
            setSelectedReceivedTmts(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts).set(id_int, value)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          } else {
            // Remove if unchecked
            selectedReceivedTmts.delete(id_int);
            setSelectedReceivedTmts(new Map(selectedReceivedTmts));
            const selTmtStr = DisplaySelectedText(
              new Map(selectedReceivedTmts)
            );
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          }
        }
        break;
      //radio
      case "rb_q49_tmt_received_unk":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            ...InitialQ49TmtReceivedChkBoxFields,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q49_tmt_received_unk: true,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q49_tmt_received_empty: false,
            q49_tmt_received_oth_txt_empty: false,
          }));
          // Empty selected tmt list when unknown is checked
          selectedReceivedTmts.clear();
          setSelectedReceivedTmts(new Map());
          setSelectedReceivedTmtsStr(SelReceivedTmtsDefaultText);
        }
        break;
      case "chk_q50_axicabtagene":
      case "chk_q50_brexucabtagene":
      case "chk_q50_ciltacabtagene":
      case "chk_q50_idecabtagene":
      case "chk_q50_lisocabtagene":
      case "chk_q50_tisagenlecleucel":
      case "chk_q50_cart_type_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            txt_q50_cart_type_oth:
              name === "chk_q50_cart_type_oth"
                ? ""
                : prev.txt_q50_cart_type_oth,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q50_cart_type_empty: false,
            q50_cart_type_oth_txt_empty:
              name === "chk_q50_cart_type_oth"
                ? false
                : prev.q50_cart_type_oth_txt_empty,
          }));
        }
        break;
      case "txt_q50_cart_type_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            txt_q50_cart_type_oth: value,
            chk_q50_cart_type_oth: true,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q50_cart_type_empty: false,
            q50_cart_type_oth_txt_empty: false,
          }));
        }
        break;
      case "rbl_q51_apheresis":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q51_apheresis_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q51_apheresis_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q51_apheresis_empty: false,
            q51_apheresisDateValid:false,
          }));
        }
        break;
      case "rbl_q52_receive_bridging_therapy":
        {
          const value_int = Number(value);
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value_int,
            rbl_q55_bridging_therapy_cycles: null,
            txt_q55_bridging_therapy_cycles: "",
            ...InitialQ53BridgingTherapy,
            rb_q53_bridging_therapy_unk: false,
            q54_initiate_bridging_therapy_date: DefaultDate,
            rbl_q54_initiate_bridging_therapy: null,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q52_receive_bridging_therapy_unk: value_int === 99,
            q55_bridging_therapy_cycles_unk: false,
            q53_bridging_therapy_unk: false,
            q54_initiate_bridging_therapy_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q52_receive_bridging_therapy_empty: false,
            q55_bridging_therapy_cycles_empty: false,
            q55_bridging_therapy_cycles_range_limit: false,
            q55_bridging_therapy_cycles_txt_empty: false,
            q53_bridging_therapy_empty: false,
            q53_bridging_therapy_oth_txt_empty: false,
            q54_initiate_bridging_therapy_empty: false,
            q54_initiate_bridging_therapy_dateValidate:false,
          }));
        }
        break;
      case "chk_q53_acalabrutinib":
      case "chk_q53_cisplatin":
      case "chk_q53_cyclophosphamide":
      case "chk_q53_cytarabine":
      case "chk_q53_dexamethasone":
      case "chk_q53_doxorubicin":
      case "chk_q53_etoposide":
      case "chk_q53_fludarabine":
      case "chk_q53_gemcitabine":
      case "chk_q53_hydroxyurea":
      case "chk_q53_ibrutinib":
      case "chk_q53_idarubicin":
      case "chk_q53_lenalidomide":
      case "chk_q53_mercaptopurine":
      case "chk_q53_methotrexate":
      case "chk_q53_rituximab":
      case "chk_q53_steroids":
      case "chk_q53_tyrosine":
      case "chk_q53_vincristine":
      case "chk_q53_bridging_therapy_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            txt_q53_bridging_therapy_oth:
              name === "chk_q53_bridging_therapy_oth"
                ? ""
                : prev.txt_q53_bridging_therapy_oth,
            rb_q53_bridging_therapy_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_empty: false,
            //...(name === "chk_q53_bridging_therapy_oth" && { q53_bridging_therapy_oth_txt_empty: false }),
            q53_bridging_therapy_oth_txt_empty:
              name === "chk_q53_bridging_therapy_oth"
                ? false
                : prev.q53_bridging_therapy_oth_txt_empty,
          }));
        }
        break;
      case "txt_q53_bridging_therapy_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value,
            chk_q53_bridging_therapy_oth: true,
            rb_q53_bridging_therapy_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_empty: false,
            q53_bridging_therapy_oth_txt_empty: false,
          }));
        }
        break;
      case "rb_q53_bridging_therapy_unk":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            ...InitialQ53BridgingTherapy,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_unk: true,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_empty: false,
            q53_bridging_therapy_oth_txt_empty: false,
          }));
        }
        break;
      case "rbl_q54_initiate_bridging_therapy":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q54_initiate_bridging_therapy_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q54_initiate_bridging_therapy_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q54_initiate_bridging_therapy_empty: false,
            q54_initiate_bridging_therapy_dateValidate:false,
          }));
        }
        break;
      case "rbl_q55_bridging_therapy_cycles":
        {
          const value_int = Number(value);
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value_int,
            txt_q55_bridging_therapy_cycles: "",
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q55_bridging_therapy_cycles_unk: value_int === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q55_bridging_therapy_cycles_empty: false,
            q55_bridging_therapy_cycles_range_limit: false,
            q55_bridging_therapy_cycles_txt_empty: false,
          }));
        }
        break;
      case "txt_q55_bridging_therapy_cycles":
        {
          if (value !== "" && !AllowOnlyIntegers(value)) {
            break;
          }
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value,
            rbl_q55_bridging_therapy_cycles: 1,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q55_bridging_therapy_cycles_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q55_bridging_therapy_cycles_empty: false,
            q55_bridging_therapy_cycles_range_limit: false,
            q55_bridging_therapy_cycles_txt_empty: false,
          }));
        }
        break;
      case "rbl_q56_receive_lympho_therapy":
        {
          const value_int = Number(value);
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value_int,
            chk_q57_cyclo: false,
            chk_q57_fluda: false,
            chk_q57_oth: false,
            txt_q57_oth: "",
            rb_q57_tmt_lympho_therapy_unk: false,
            rbl_q58_initiate_lympho_therapy: null,
            q58_initiate_lympho_therapy_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q56_receive_lympho_therapy_unk: value_int === 99,
            q57_tmt_lympho_therapy_unk: false,
            q58_initiate_lympho_therapy_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q56_receive_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_oth_txt_empty: false,
            q58_initiate_lympho_therapy_empty: false,
            q58_initiate_lympho_therapy_datevalidate:false,
          }));
        }
        break;
      case "chk_q57_cyclo":
      case "chk_q57_fluda":
      case "chk_q57_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            txt_q57_oth: "",
            rb_q57_tmt_lympho_therapy_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_oth_txt_empty:
              name === "chk_q57_oth"
                ? false
                : prev.q57_tmt_lympho_therapy_oth_txt_empty,
          }));
        }
        break;
      case "txt_q57_oth":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: value,
            chk_q57_oth: true,
            rb_q57_tmt_lympho_therapy_unk: false,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_oth_txt_empty: false,
          }));
        }
        break;
      case "rb_q57_tmt_lympho_therapy_unk":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: !prev[name],
            chk_q57_cyclo: false,
            chk_q57_fluda: false,
            chk_q57_oth: false,
            txt_q57_oth: "",
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_unk: true,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_empty: false,
            q57_tmt_lympho_therapy_oth_txt_empty: false,
          }));
        }
        break;
      case "rbl_q58_initiate_lympho_therapy":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q58_initiate_lympho_therapy_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q58_initiate_lympho_therapy_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q58_initiate_lympho_therapy_empty: false,
            q58_initiate_lympho_therapy_datevalidate:false,
          }));
        }
        break;
      case "rbl_q59_initiate_q49_tx":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q59_initiate_q49_tx_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q59_initiate_q49_tx_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q59_initiate_q49_tx_empty: false,
            q59_initiate_before_index_date: false,
          }));
        }
        break;
      case "rbl_q60_ipi_risk_score":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            ...(Number(value) === 99 && { rbl_q61_ipi_risk_score_date: null }),
            ...(Number(value) === 99 && {
              q61_ipi_risk_score_date: DefaultDate,
            }),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q60_ipi_risk_score_unk: Number(value) === 99,
            ...(Number(value) === 99 && { q61_ipi_risk_score_date_unk: false }),
          }));
          setHardErrList((prev) => ({
            ...prev,
            q60_ipi_risk_score_empty: false,
            ...(Number(value) === 99 && {
              q61_ipi_risk_score_date_empty: false,
              q61_ipi_risk_score_datevalidate:false,
            }),
          }));
        }
        break;
      case "rbl_q61_ipi_risk_score_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q61_ipi_risk_score_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_date_empty: false,
            q61_ipi_risk_score_datevalidate:false,
          }));
        }
        break;
      case "rbl_q62_discontinue_q49_tx":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q62_discontinue_q49_tx_date: DefaultDate,
            rbl_q63_last_known_admin_date: null,
            q63_last_known_admin_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q62_discontinue_q49_tx_date_unk: Number(value) === 99,
            q63_last_known_admin_q49_tx_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q62_discontinue_q49_tx_date_empty: false,
            q62_discontinue_after_q59_date: false,
            q63_last_known_admin_q49_tx_date_empty: false,
            q63_last_known_admin_after_q59_date: false,
          }));
        }
        break;
      case "rbl_q63_last_known_admin_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            q63_last_known_admin_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q63_last_known_admin_q49_tx_date_unk: Number(value) === 99,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q63_last_known_admin_q49_tx_date_empty: false,
            q63_last_known_admin_after_q59_date: false,
          }));
        }
        break;

      case "dosesOfTocilizumabNumOfDoses": {
        setTPEDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_unk: Number(value) === 99,
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);

        break;
      }

      default:
        break;
    }
  };
  const HandleDateChange = (name, date, datePart) => {
    switch (name) {
      case "q51_apheresis_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q51_apheresis: 1,
            [name]: SetDateState(prev.q51_apheresis_date, date, datePart),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q51_apheresis_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q51_apheresis_empty: false,
            q51_apheresisDateValid:false,
          }));
        }
        break;
      case "q54_initiate_bridging_therapy_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q54_initiate_bridging_therapy: 1,
            [name]: SetDateState(
              prev.q54_initiate_bridging_therapy_date,
              date,
              datePart
            ),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q54_initiate_bridging_therapy_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q54_initiate_bridging_therapy_empty: false,
            q54_initiate_bridging_therapy_dateValidate:false,
          }));
        }
        break;
      case "q58_initiate_lympho_therapy_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q58_initiate_lympho_therapy: 1,
            [name]: SetDateState(
              prev.q58_initiate_lympho_therapy_date,
              date,
              datePart
            ),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q58_initiate_lympho_therapy_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q58_initiate_lympho_therapy_empty: false,
            q58_initiate_lympho_therapy_datevalidate:false,
          }));
        }
        break;
      case "q59_initiate_q49_tx_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q59_initiate_q49_tx: 1,
            [name]: SetDateState(prev.q59_initiate_q49_tx_date, date, datePart),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q59_initiate_q49_tx_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q59_initiate_q49_tx_empty: false,
            q59_initiate_before_index_date: false,
          }));
        }
        break;
      case "q61_ipi_risk_score_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q61_ipi_risk_score_date: 1,
            [name]: SetDateState(prev.q61_ipi_risk_score_date, date, datePart),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_date_empty: false,
            q61_ipi_risk_score_datevalidate:false,
          }));
        }
        break;
      case "q62_discontinue_q49_tx_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q62_discontinue_q49_tx: 1,
            [name]: SetDateState(
              prev.q62_discontinue_q49_tx_date,
              date,
              datePart
            ),
            rbl_q63_last_known_admin_date: null,
            q63_last_known_admin_date: DefaultDate,
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q62_discontinue_q49_tx_date_unk: false,
            q63_last_known_admin_q49_tx_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q62_discontinue_q49_tx_date_empty: false,
            q62_discontinue_after_q59_date: false,
            q63_last_known_admin_q49_tx_date_empty: false,
            q63_last_known_admin_after_q59_date: false,
          }));
        }
        break;
      case "q63_last_known_admin_date":
        {
          setTPEDetails((prev) => ({
            ...prev,
            rbl_q63_last_known_admin_date: 1,
            [name]: SetDateState(
              prev.q63_last_known_admin_date,
              date,
              datePart
            ),
          }));
          setSoftErrList((prev) => ({
            ...prev,
            q63_last_known_admin_q49_tx_date_unk: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            q63_last_known_admin_q49_tx_date_empty: false,
            q63_last_known_admin_after_q59_date: false,
          }));
        }
        break;
      default:
        break;
    }
  };


  const handleParentCheckboxChange = (medicationTypeID) => {
    
    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
       
        selectedOptions_SubSub_Empty: false    ,
    }));

    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: false,
      }));
    
    if(medicationTypeID === 7) {
        setHardErrList((prev) => ({
            ...prev,
           
            selectedOptions_Sub_Empty: false    ,
        epco_sub_othertext_empty: false,

            
        }));
    }
    if(medicationTypeID === 8) {
        setHardErrList((prev) => ({
            ...prev,
           
            epco_parent_othertext_empty: false    ,
            
        }));
    }
    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      parent: {
        ...prevState.parent,
        [medicationTypeID]: !prevState.parent[medicationTypeID],
      },
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {},
      },
    }));
    if (medicationTypeID === 8 && selectedOptions.parent[medicationTypeID]) {
      setOtherInputs((prevState) => ({
        ...prevState,
        parent: "",
      }));
    }

    if (medicationTypeID === 6 ){
    setDoseOfTocilizumabDetails([{
        ...DoseOfTocilizumabRow
    }]);

    setTPEDetails((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses: null,
      }));
      setHardErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_empty: false,
      }));
      setSoftErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_unk: false,
      }));
}
  };
  const handleSubCheckboxChange = (medicationTypeID, subMedicationTypeID) => {

    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Sub_Empty: false    ,
        selectedOptions_SubSub_Empty: false    ,
        [subMedicationTypeID]: false,
    }));

    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {
          ...prevState.sub[medicationTypeID],
          [subMedicationTypeID]:
            !prevState.sub[medicationTypeID]?.[subMedicationTypeID],
        },
      },
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {},
      },
    }));
    if (
      subMedicationTypeID === 6 &&
      selectedOptions.sub[medicationTypeID]?.[subMedicationTypeID]
    ) {
      setOtherInputs((prevState) => ({
        ...prevState,
        sub: "",
      }));
      setHardErrList((prev) => ({
        ...prev,
       
        epco_sub_othertext_empty: false,
        
    }));
      
      
      
    }
  };
  const handleOtherInputChange = (key, value) => {
   
    
    if (key === "parent") {
      setOtherInput(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_parent_othertext_empty: false,
    }));

    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: false,
      }));

    
    
    }
    if (key === "sub") {
      setSubOtherInputs(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_sub_othertext_empty: false,
        
    }));
    }
    setOtherInputs((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleRadioChange = (e, name) => {
    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_parent_othertext_empty: false,
        epco_sub_othertext_empty: false,


    }));
    
    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: name === "Unknown",
      }));


    setRadioSelection(name);
    setSelectedOptions({
      parent: {},
      sub: {},
      subSub: {},
    });
    setOtherInputs((prevState) => ({
        ...prevState,
        parent: "",
        sub: ""
      }));
  };
  const handleSubSubCheckboxChange = (
    subMedicationTypeID,
    subSubMedicationTypeID,
    index
  ) => {

    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {
          ...prevState.subSub[subMedicationTypeID],
          [subSubMedicationTypeID]:
            !prevState.subSub[subMedicationTypeID]?.[subSubMedicationTypeID],
        },
      },
    }));
    setHardErrList((prev) => ({
        ...prev,
        [subMedicationTypeID]: false,
      }));

      
  };


  const SavePage = async (validate) => {

    try {
      ToggleLoader(true);
      //remove soft errors on click of Save
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      // if (!Validate()) {
      //   setShowTopErrMsg(true);
      //   ToggleLoader(false);
      //   return false;
      // }
      // setShowTopErrMsg(false);
      let isValid = validate ? Validate() : true;

      //51 date
      if (
        !IsDateEmpty(tpeDetails.q51_apheresis_date) &&
        !IsDateValid(tpeDetails.q51_apheresis_date)
      ) {
        isValid = false;
      }

      //54 date
      if (
        !IsDateEmpty(tpeDetails.q54_initiate_bridging_therapy_date) &&
        !IsDateValid(tpeDetails.q54_initiate_bridging_therapy_date)
      ) {
        isValid = false;
      }

      //
      //54 date
      if (
        !IsDateEmpty(tpeDetails.q58_initiate_lympho_therapy_date) &&
        !IsDateValid(tpeDetails.q58_initiate_lympho_therapy_date)
      ) {
        isValid = false;
      }

      //59
      if (
        !IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date) &&
        !IsDateValid(tpeDetails.q59_initiate_q49_tx_date)
      ) {
        isValid = false;
      }

      //61
      if (
        !IsDateEmpty(tpeDetails.q61_ipi_risk_score_date) &&
        !IsDateValid(tpeDetails.q61_ipi_risk_score_date)
      ) {
        isValid = false;
      }

      //62
      if (
        !IsDateEmpty(tpeDetails.q62_discontinue_q49_tx_date) &&
        !IsDateValid(tpeDetails.q62_discontinue_q49_tx_date)
      ) {
        isValid = false;
      }

      //63
      if (
        !IsDateEmpty(tpeDetails.q63_last_known_admin_date) &&
        !IsDateValid(tpeDetails.q63_last_known_admin_date)
      ) {
        isValid = false;
      }

      if (radioAssessmentDetails && radioAssessmentDetails.length > 0) {
        radioAssessmentDetails.map((item) => {
          if (
            !IsDateEmpty(item.q64_radio_assess_date) &&
            !IsDateValid(item.q64_radio_assess_date)
          ) {
            isValid = false;
          }

          return item;
        });
      }

            
            //new code Q74.
            const saveData = [];
    for (const [medicationTypeId, isParentSelected] of Object.entries(
      selectedOptions.parent
    )) {
      if (isParentSelected) {
        const parentRecord = {
          patientId,
          MedicationTypeId: parseInt(medicationTypeId),
          SubMedicationTypeId: null,
          SubSubMedicationTypeId: null,
          MedicationTypeOther: medicationTypeId === "8" ? otherInput : null,
        };

        saveData.push(parentRecord);

        if (selectedOptions.sub[medicationTypeId]) {
          for (const [subMedicationTypeId, isSubSelected] of Object.entries(
            selectedOptions.sub[medicationTypeId]
          )) {
            if (isSubSelected) {
              const subRecord = {
                patientId,
                MedicationTypeId: parseInt(medicationTypeId),
                SubMedicationTypeId: parseInt(subMedicationTypeId),
                SubSubMedicationTypeId: null,
                SubMedicationTypeOther:
                  subMedicationTypeId === "6" ? subOtherInputs : null,
              };

              saveData.push(subRecord);

              if (selectedOptions.subSub[subMedicationTypeId]) {
                for (const [
                  subSubMedicationTypeId,
                  isSubSubSelected,
                ] of Object.entries(
                  selectedOptions.subSub[subMedicationTypeId]
                )) {
                  if (isSubSubSelected) {
                    const subSubRecord = {
                      patientId,
                      MedicationTypeId: parseInt(medicationTypeId),
                      SubMedicationTypeId: parseInt(subMedicationTypeId),
                      SubSubMedicationTypeId: parseInt(subSubMedicationTypeId),
                      MedicationTypeOther: null,
                    };

                    saveData.push(subSubRecord);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Add radio button data (None or Unknown)
    saveData.push({
      patientId,
      Medication_none: radioSelection === "None",
      Medication_Unk: radioSelection === "Unknown",
      MedicationTypeId: null,
      SubMedicationTypeId: null,
      SubSubMedicationTypeId: null,
      MedicationTypeOther: null,
      SubMedicationTypes: null,
      SubSubMedicationTypes: null,
    });
    const filteredSaveData = saveData.filter(
      (record) =>
        record.MedicationTypeId !== null ||
        record.MedicationTypeOther !== null ||
        record.Medication_Unk ||
        record.Medication_none ||
        record.SubMedicationTypeId !== null ||
        record.SubSubMedicationTypeId !== null ||
        (record.SubMedicationTypes && record.SubMedicationTypes.length > 0) ||
        (record.SubSubMedicationTypes &&
          record.SubSubMedicationTypes.length > 0)
    );

      if (isValid) {
        setShowTopErrMsg(false);
        const dataModel = {
          LotDetails: {
            LotId: tpeDetails.lotId,
           

            TocilizumabDosesReceived:tpeDetails.dosesOfTocilizumabNumOfDoses,

            //Q49
            TreatmentAnticd20: tpeDetails.chk_q49_anti_cd20_mab,
            TreatmentAnthracyclines: tpeDetails.chk_q49_anthracyclines,
            TreatmentAlkylating: tpeDetails.chk_q49_alkylating_agents,
            TreatmentAutologous:
              tpeDetails.chk_q49_autologous_stem_cell_transplant,
            TreatmentAllogenic:
              tpeDetails.chk_q49_allogenic_stem_cell_transplant,
            TreatmentCartcelltherapy: tpeDetails.chk_q49_car_t_cell_therapy,
            TreatmentBendamustine: tpeDetails.chk_q49_bendamustine,
            TreatmentGlofitamab: tpeDetails.chk_q49_glofitamab,
            TreatmentImvotamab: tpeDetails.chk_q49_imvotamab,
            TreatmentLenalidomide: tpeDetails.chk_q49_lenalidomide,
            TreatmentLoncastatuximab: tpeDetails.chk_q49_loncastatuximab,
            TreatmentMosunetuzumab: tpeDetails.chk_q49_mosunetuzumab,
            TreatmentOdronextamab: tpeDetails.chk_q49_odronextamab,
            TreatmentPlamotamab: tpeDetails.chk_q49_plamotamab,
            TreatmentPolatuzumab: tpeDetails.chk_q49_polatuzumab_vedotin,
            TreatmentRituximab: tpeDetails.chk_q49_rituximab,
            TreatmentSelinexor: tpeDetails.chk_q49_selinexor,
            TreatmentTafasitamab: tpeDetails.chk_q49_tafasitamab,
            TreatmentOth: tpeDetails.chk_q49_tmt_received_oth,
            TreatmentOthTxt: tpeDetails.txt_q49_tmt_received_oth,
            TreatmentUnk: tpeDetails.rb_q49_tmt_received_unk,

            ...(tpeDetails.chk_q49_car_t_cell_therapy && {
              //Q50
              CartTherapyAxicabtagene: tpeDetails.chk_q50_axicabtagene,
              CartTherapyBrexucabtagene: tpeDetails.chk_q50_brexucabtagene,
              CartTherapyCiltacabtagene: tpeDetails.chk_q50_ciltacabtagene,
              CartTherapyIdecabtagene: tpeDetails.chk_q50_idecabtagene,
              CartTherapyLisocabtagene: tpeDetails.chk_q50_lisocabtagene,
              CartTherapyTisagenlecleucel: tpeDetails.chk_q50_tisagenlecleucel,
              CartTherapyOth: tpeDetails.chk_q50_cart_type_oth,
              CartTherapyOthTxt: tpeDetails.txt_q50_cart_type_oth,

              //Q51
              ...(tpeDetails.rbl_q51_apheresis === 1 &&
                !IsDateEmpty(tpeDetails.q51_apheresis_date) && {
                  UndergoApheresisDate: ParseDate(
                    tpeDetails.q51_apheresis_date
                  ),
                  UndergoApheresisDateDmuid: GetDMUID(
                    tpeDetails.q51_apheresis_date
                  ),
                }),
              UndergoApheresisDateUnk: tpeDetails.rbl_q51_apheresis === 99,

              //Q52
              ReceiveBridgingTherapy:
                tpeDetails.rbl_q52_receive_bridging_therapy,

              ...(tpeDetails.rbl_q52_receive_bridging_therapy === 1 && {
                //Q53
                BridgingTreatmentAcalabrutinib:
                  tpeDetails.chk_q53_acalabrutinib,
                BridgingTreatmentCisplatin: tpeDetails.chk_q53_cisplatin,
                BridgingTreatmentCyclophosphamide:
                  tpeDetails.chk_q53_cyclophosphamide,
                BridgingTreatmentCytarabine: tpeDetails.chk_q53_cytarabine,
                BridgingTreatmentDexamethasone:
                  tpeDetails.chk_q53_dexamethasone,
                BridgingTreatmentDoxorubicin: tpeDetails.chk_q53_doxorubicin,
                BridgingTreatmentEtoposide: tpeDetails.chk_q53_etoposide,
                BridgingTreatmentFludarabine: tpeDetails.chk_q53_fludarabine,
                BridgingTreatmentGemcitabine: tpeDetails.chk_q53_gemcitabine,
                BridgingTreatmentHydroxyurea: tpeDetails.chk_q53_hydroxyurea,
                BridgingTreatmentIbrutinib: tpeDetails.chk_q53_ibrutinib,
                BridgingTreatmentIdarubicin: tpeDetails.chk_q53_idarubicin,
                BridgingTreatmentLenalidomide: tpeDetails.chk_q53_lenalidomide,
                BridgingTreatmentMercaptopurine:
                  tpeDetails.chk_q53_mercaptopurine,
                BridgingTreatmentMethotrexate: tpeDetails.chk_q53_methotrexate,
                BridgingTreatmentRituximab: tpeDetails.chk_q53_rituximab,
                BridgingTreatmentSteroids: tpeDetails.chk_q53_steroids,
                BridgingTreatmentTyrosineKinaseInhibitor:
                  tpeDetails.chk_q53_tyrosine,
                BridgingTreatmentVincristine: tpeDetails.chk_q53_vincristine,
                BridgingTreatmentOth: tpeDetails.chk_q53_bridging_therapy_oth,
                BridgingTreatmentOthTxt:
                  tpeDetails.txt_q53_bridging_therapy_oth,
                BridgingTreatmentUnk: tpeDetails.rb_q53_bridging_therapy_unk,

                //Q54
                ...(tpeDetails.rbl_q54_initiate_bridging_therapy === 1 &&
                  !IsDateEmpty(
                    tpeDetails.q54_initiate_bridging_therapy_date
                  ) && {
                    BridgingTherapyDate: ParseDate(
                      tpeDetails.q54_initiate_bridging_therapy_date
                    ),
                    BridgingTherapyDateDmuid: GetDMUID(
                      tpeDetails.q54_initiate_bridging_therapy_date
                    ),
                  }),
                BridgingTherapyDateUnk:
                  tpeDetails.rbl_q54_initiate_bridging_therapy === 99,

                //Q55
                BridgingTherapyCycleReceived:
                  tpeDetails.rbl_q55_bridging_therapy_cycles,
                BridgingTherapyNoOfCycleTxt:
                  tpeDetails.txt_q55_bridging_therapy_cycles,
              }),

              //Q56
              ReceiveLymphodepletingTherapy:
                tpeDetails.rbl_q56_receive_lympho_therapy,

              ...(tpeDetails.rbl_q56_receive_lympho_therapy === 1 && {
                //Q57
                LymphodepletingTherapyCyclophosphamide:
                  tpeDetails.chk_q57_cyclo,
                LymphodepletingTherapyFludarabine: tpeDetails.chk_q57_fluda,
                LymphodepletingTherapyOth: tpeDetails.chk_q57_oth,
                LymphodepletingTherapyOthTxt: tpeDetails.txt_q57_oth,
                LymphodepletingTherapyUnk:
                  tpeDetails.rb_q57_tmt_lympho_therapy_unk,

                //Q58
                ...(tpeDetails.rbl_q58_initiate_lympho_therapy === 1 &&
                  !IsDateEmpty(tpeDetails.q58_initiate_lympho_therapy_date) && {
                    LymphodepletingTherapyDate: ParseDate(
                      tpeDetails.q58_initiate_lympho_therapy_date
                    ),
                    LymphodepletingTherapyDateDmuid: GetDMUID(
                      tpeDetails.q58_initiate_lympho_therapy_date
                    ),
                  }),
                LymphodepletingTherapyDateUnk:
                  tpeDetails.rbl_q58_initiate_lympho_therapy === 99,
              }),
            }),

            //Q59
            ...(tpeDetails.rbl_q59_initiate_q49_tx === 1 &&
              !IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date) && {
                LotInitiationDate: ParseDate(
                  tpeDetails.q59_initiate_q49_tx_date
                ),
                LotInitiationDateDmuid: GetDMUID(
                  tpeDetails.q59_initiate_q49_tx_date
                ),
              }),
            LotInitiationDateUnk: tpeDetails.rbl_q59_initiate_q49_tx === 99,
            //Q60
            IpiRiskScore: tpeDetails.rbl_q60_ipi_risk_score,

            //Q61
            ...(tpeDetails.rbl_q60_ipi_risk_score !== 99 && {
              ...(tpeDetails.rbl_q61_ipi_risk_score_date === 1 &&
                !IsDateEmpty(tpeDetails.q61_ipi_risk_score_date) && {
                  LotRiskAssessmentDate: ParseDate(
                    tpeDetails.q61_ipi_risk_score_date
                  ),
                  LotRiskAssessmentDateDmuid: GetDMUID(
                    tpeDetails.q61_ipi_risk_score_date
                  ),
                }),
              LotRiskAssessmentDateUnk:
                tpeDetails.rbl_q61_ipi_risk_score_date === 99,
            }),

            //Q62
            ...(tpeDetails.rbl_q62_discontinue_q49_tx === 1 &&
              !IsDateEmpty(tpeDetails.q62_discontinue_q49_tx_date) && {
                DiscontinueLotDate: ParseDate(
                  tpeDetails.q62_discontinue_q49_tx_date
                ),
                DiscontinueLotDateDmuid: GetDMUID(
                  tpeDetails.q62_discontinue_q49_tx_date
                ),
              }),
            DiscontinueLotDateUnk: tpeDetails.rbl_q62_discontinue_q49_tx === 99,
            TreatmentStillOn: tpeDetails.rbl_q62_discontinue_q49_tx === 2,

            ...(tpeDetails.rbl_q62_discontinue_q49_tx === 99 && {
              //Q63
              ...(tpeDetails.rbl_q63_last_known_admin_date === 1 &&
                !IsDateEmpty(tpeDetails.q63_last_known_admin_date) && {
                  LastknownLotAdminDate: ParseDate(
                    tpeDetails.q63_last_known_admin_date
                  ),
                  LastknownLotAdminDateDmuid: GetDMUID(
                    tpeDetails.q63_last_known_admin_date
                  ),
                }),
              LastknownLotAdminDateUnk:
                tpeDetails.rbl_q63_last_known_admin_date === 99,
            }),
          },
          LotRadiologicalAssessmentDetailsList: [],
          SiteofcareDetails:[],
          PatientId: patientId,
          UserId: GetLoggedInUserID(),
          lotNumber: lot,
          isValidated: validate,
          SaveMedication: filteredSaveData,
        };
        
        //Q64-68 Radiological Assessment rpt
        if (radioAssessmentDetails && radioAssessmentDetails.length > 0) {
          dataModel.LotRadiologicalAssessmentDetailsList =
            radioAssessmentDetails.map((a) => ({
              LotRadiologicalAssessmentId: a.lotRadiologicalAssessmentId,
              //LotId: a.lotId,
              RadiologicalAssessmentDate:
                a.rbl_q64_radio_assess === 1 &&
                !IsDateEmpty(a.q64_radio_assess_date)
                  ? ParseDate(a.q64_radio_assess_date)
                  : null,
              RadiologicalAssessmentDateDmuid:
                a.rbl_q64_radio_assess === 1
                  ? GetDMUID(a.q64_radio_assess_date)
                  : null,
              RadiologicalAssessmentDateUnk: a.rbl_q64_radio_assess === 99,
              LbclStatus: a.rbl_q65_lbcl_status,
              LbclStatusOthTxt: a.txt_q65_lbcl_status_oth,
              DiseaseStageAssessedPerLyricLugano: a.rbl_q66_criteria,
              DiseaseAssessmentPerLyric: a.rbl_q67_lyric_assess,
              RadiologicalAssessmentType: a.rbl_q68_radiology_type,
              RadiologicalAssessmentTypeOthTxt: a.txt_q68_radiology_type_oth,
            }));
        }


        if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
          dataModel.SiteofcareDetails = doseOfTocilizumabDetails.map(
            (a) => ({
              Lotsitecarebridgeid:
                a.lotsitecarebridgeid,
                // Lotid:a.lotid,
                TreatmentpriorSiteofcare:a.siteOfCare_rd,
                TreatmentpriorSiteofcareOthTxt: a.siteOfCare_other,
            })
          );
        }


        const response =
          await TreatmentResponseService.SaveTreatmentPriorEpcoritamabData(
            dataModel
          );

        if (response?.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized)
            HandleSessionTimeout();
          else throw response.error;
        } else {
          await LoadData();
          ToggleLoader(false);
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        
          //CAR Therapy is selected
          if (tpeDetails.chk_q49_car_t_cell_therapy) {
            ShowCartPage(false);
          }
          //if not selected, chk if it was selected earlier
          else if (tpeDetails.chk_q49_car_t_cell_therapy_before) {
            //CAR Therapy was selected now unchecked

            const response =
              await TreatmentResponseService.IsCARTCellTherapySelected(
                patientId
              );
            if (response?.status === HTTPResponse.OK) {
              const { data } = response;
              ShowCartPage(!data);
            } else if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            }
          }
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };
  //For int state check against null if 0 is valid since 0 is considered falsy.
  const Validate = () => {
    let result = true;
    
    //Q49
    if (
      !tpeDetails.chk_q49_anti_cd20_mab &&
      !tpeDetails.chk_q49_anthracyclines &&
      !tpeDetails.chk_q49_alkylating_agents &&
      !tpeDetails.chk_q49_autologous_stem_cell_transplant &&
      !tpeDetails.chk_q49_allogenic_stem_cell_transplant &&
      !tpeDetails.chk_q49_car_t_cell_therapy &&
      !tpeDetails.chk_q49_bendamustine &&
      !tpeDetails.chk_q49_glofitamab &&
      !tpeDetails.chk_q49_imvotamab &&
      !tpeDetails.chk_q49_lenalidomide &&
      !tpeDetails.chk_q49_loncastatuximab &&
      !tpeDetails.chk_q49_mosunetuzumab &&
      !tpeDetails.chk_q49_odronextamab &&
      !tpeDetails.chk_q49_plamotamab &&
      !tpeDetails.chk_q49_polatuzumab_vedotin &&
      !tpeDetails.chk_q49_rituximab &&
      !tpeDetails.chk_q49_selinexor &&
      !tpeDetails.chk_q49_tafasitamab &&
      !tpeDetails.chk_q49_tmt_received_oth &&
      !tpeDetails.rb_q49_tmt_received_unk
    ) {
      result = false;
      setHardErrList((prev) => ({
        ...prev,
        q49_tmt_received_empty: true,
      }));
    } else if (
      tpeDetails.chk_q49_tmt_received_oth &&
      isStringEmpty(tpeDetails.txt_q49_tmt_received_oth)
    ) {
      result = false;
      setHardErrList((prev) => ({
        ...prev,
        q49_tmt_received_oth_txt_empty: true,
      }));
    }
    if (tpeDetails.chk_q49_car_t_cell_therapy) {
      //Q50
      if (
        !tpeDetails.chk_q50_axicabtagene &&
        !tpeDetails.chk_q50_brexucabtagene &&
        !tpeDetails.chk_q50_ciltacabtagene &&
        !tpeDetails.chk_q50_idecabtagene &&
        !tpeDetails.chk_q50_lisocabtagene &&
        !tpeDetails.chk_q50_tisagenlecleucel &&
        !tpeDetails.chk_q50_cart_type_oth
      ) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q50_cart_type_empty: true,
        }));
      } else if (
        tpeDetails.chk_q50_cart_type_oth &&
        isStringEmpty(tpeDetails.txt_q50_cart_type_oth)
      ) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q50_cart_type_oth_txt_empty: true,
        }));
      }
      //Q51
      if (!tpeDetails.rbl_q51_apheresis) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q51_apheresis_empty: true,
        }));
      } else if (tpeDetails.rbl_q51_apheresis === 1) {
        if (IsDateEmpty(tpeDetails.q51_apheresis_date)) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q51_apheresis_empty: true,
          }));
        }
        
        else if(!IsDateEmpty(tpeDetails.q51_apheresis_date) && IsDateValid(tpeDetails.q51_apheresis_date) && IsValidDate1(tpeDetails.q51_apheresis_date,index_date) ){
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q51_apheresisDateValid: true,
          }));
        }     
        else if (!IsDateValid(tpeDetails.q51_apheresis_date)) {
          result = false;
        }
      }
      //Q52
      if (tpeDetails.rbl_q52_receive_bridging_therapy == null) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q52_receive_bridging_therapy_empty: true,
        }));
      }
      if (tpeDetails.rbl_q52_receive_bridging_therapy === 1) {
        //Q53
        if (
          !tpeDetails.chk_q53_acalabrutinib &&
          !tpeDetails.chk_q53_cisplatin &&
          !tpeDetails.chk_q53_cyclophosphamide &&
          !tpeDetails.chk_q53_cytarabine &&
          !tpeDetails.chk_q53_dexamethasone &&
          !tpeDetails.chk_q53_doxorubicin &&
          !tpeDetails.chk_q53_etoposide &&
          !tpeDetails.chk_q53_fludarabine &&
          !tpeDetails.chk_q53_gemcitabine &&
          !tpeDetails.chk_q53_hydroxyurea &&
          !tpeDetails.chk_q53_ibrutinib &&
          !tpeDetails.chk_q53_idarubicin &&
          !tpeDetails.chk_q53_lenalidomide &&
          !tpeDetails.chk_q53_mercaptopurine &&
          !tpeDetails.chk_q53_methotrexate &&
          !tpeDetails.chk_q53_rituximab &&
          !tpeDetails.chk_q53_steroids &&
          !tpeDetails.chk_q53_tyrosine &&
          !tpeDetails.chk_q53_vincristine &&
          !tpeDetails.chk_q53_bridging_therapy_oth &&
          !tpeDetails.rb_q53_bridging_therapy_unk
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_empty: true,
          }));
        } else if (
          tpeDetails.chk_q53_bridging_therapy_oth &&
          isStringEmpty(tpeDetails.txt_q53_bridging_therapy_oth)
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q53_bridging_therapy_oth_txt_empty: true,
          }));
        }
        //Q54
        if (!tpeDetails.rbl_q54_initiate_bridging_therapy) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q54_initiate_bridging_therapy_empty: true,
          }));
        } else if (tpeDetails.rbl_q54_initiate_bridging_therapy === 1) {
          if (IsDateEmpty(tpeDetails.q54_initiate_bridging_therapy_date)) {
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q54_initiate_bridging_therapy_empty: true,
            }));
          }
          
          else if(!IsDateEmpty(tpeDetails.q54_initiate_bridging_therapy_date) && IsDateValid(tpeDetails.q54_initiate_bridging_therapy_date) && IsValidDate1(tpeDetails.q54_initiate_bridging_therapy_date,index_date) ){
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q54_initiate_bridging_therapy_dateValidate: true,
            }));
          }     
          
          else if (
            !IsDateValid(tpeDetails.q54_initiate_bridging_therapy_date)
          ) {
            result = false;
          }
        }
        //Q55
        if (tpeDetails.rbl_q55_bridging_therapy_cycles == null) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q55_bridging_therapy_cycles_empty: true,
          }));
        } else if (tpeDetails.rbl_q55_bridging_therapy_cycles === 1) {
          if (isStringEmpty(tpeDetails.txt_q55_bridging_therapy_cycles)) {
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q55_bridging_therapy_cycles_txt_empty: true,
            }));
          } else if (
            !ValueInRange(tpeDetails.txt_q55_bridging_therapy_cycles, 0, 100)
          ) {
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q55_bridging_therapy_cycles_range_limit: true,
            }));
          }
        }
      }
      //Q56
      if (tpeDetails.rbl_q56_receive_lympho_therapy == null) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q56_receive_lympho_therapy_empty: true,
        }));
      }
      if (tpeDetails.rbl_q56_receive_lympho_therapy === 1) {
        //Q57
        if (
          !tpeDetails.chk_q57_cyclo &&
          !tpeDetails.chk_q57_fluda &&
          !tpeDetails.chk_q57_oth &&
          !tpeDetails.rb_q57_tmt_lympho_therapy_unk
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_empty: true,
          }));
        } else if (
          tpeDetails.chk_q57_oth &&
          isStringEmpty(tpeDetails.txt_q57_oth)
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q57_tmt_lympho_therapy_oth_txt_empty: true,
          }));
        }
        //Q58
        if (!tpeDetails.rbl_q58_initiate_lympho_therapy) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q58_initiate_lympho_therapy_empty: true,
          }));
        } else if (tpeDetails.rbl_q58_initiate_lympho_therapy === 1) {
          if (IsDateEmpty(tpeDetails.q58_initiate_lympho_therapy_date)) {
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q58_initiate_lympho_therapy_empty: true,
            }));
          }
          
          else if(!IsDateEmpty(tpeDetails.q58_initiate_lympho_therapy_date) && IsDateValid(tpeDetails.q58_initiate_lympho_therapy_date) && IsValidDate1(tpeDetails.q58_initiate_lympho_therapy_date,index_date) ){
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q58_initiate_lympho_therapy_datevalidate: true,
            }));
          }     
          
          
          else if (
            !IsDateValid(tpeDetails.q58_initiate_lympho_therapy_date)
          ) {
            result = false;
          }
        }
      }
    }
    //Q59
    if (!tpeDetails.rbl_q59_initiate_q49_tx) {
      result = false;
      setHardErrList((prev) => ({
        ...prev,
        q59_initiate_q49_tx_empty: true,
      }));
    } else if (tpeDetails.rbl_q59_initiate_q49_tx === 1) {
      if (IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date)) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q59_initiate_q49_tx_empty: true,
        }));
      } else if (!IsDateValid(tpeDetails.q59_initiate_q49_tx_date)) {
        result = false;
      } else {
        //date must be before “index_date”
        if (
          !IsDateEmpty(index_date) &&
          !Is_DateBefore(tpeDetails.q59_initiate_q49_tx_date, index_date)
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q59_initiate_before_index_date: true,
          }));
        }
      }
    }
    //Q60
    if (!tpeDetails.rbl_q60_ipi_risk_score) {
      result = false;
      setHardErrList((prev) => ({
        ...prev,
        q60_ipi_risk_score_empty: true,
      }));
    }
    //Q61
    if (tpeDetails.rbl_q60_ipi_risk_score !== 99) {
      if (!tpeDetails.rbl_q61_ipi_risk_score_date) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q61_ipi_risk_score_date_empty: true,
        }));
      } else if (tpeDetails.rbl_q61_ipi_risk_score_date === 1) {
        if (IsDateEmpty(tpeDetails.q61_ipi_risk_score_date)) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_date_empty: true,
          }));
        }
        
        else if(!IsDateEmpty(tpeDetails.q61_ipi_risk_score_date) && IsDateValid(tpeDetails.q61_ipi_risk_score_date) && IsValidDate1(tpeDetails.q61_ipi_risk_score_date,index_date) ){
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q61_ipi_risk_score_datevalidate: true,
          }));
        }     
        
        else if (!IsDateValid(tpeDetails.q61_ipi_risk_score_date)) {
          result = false;
        }
      }
    }
    //Q62
    if (!tpeDetails.rbl_q62_discontinue_q49_tx) {
      result = false;
      setHardErrList((prev) => ({
        ...prev,
        q62_discontinue_q49_tx_date_empty: true,
      }));
    } else if (tpeDetails.rbl_q62_discontinue_q49_tx === 1) {
      if (IsDateEmpty(tpeDetails.q62_discontinue_q49_tx_date)) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q62_discontinue_q49_tx_date_empty: true,
        }));
      } else if (!IsDateValid(tpeDetails.q62_discontinue_q49_tx_date)) {
        result = false;
      } else {
        //date must be after LOT_X_tx_in_date in Q59.
        if (
          !IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date) &&
          !Is_DateBefore(
            tpeDetails.q59_initiate_q49_tx_date,
            tpeDetails.q62_discontinue_q49_tx_date
          )
        ) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q62_discontinue_after_q59_date: true,
          }));
        }
      }
    }
    //Q63
    if (tpeDetails.rbl_q62_discontinue_q49_tx === 99) {
      if (!tpeDetails.rbl_q63_last_known_admin_date) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q63_last_known_admin_q49_tx_date_empty: true,
        }));
      } else if (tpeDetails.rbl_q63_last_known_admin_date === 1) {
        if (IsDateEmpty(tpeDetails.q63_last_known_admin_date)) {
          result = false;
          setHardErrList((prev) => ({
            ...prev,
            q63_last_known_admin_q49_tx_date_empty: true,
          }));
        } else if (!IsDateValid(tpeDetails.q63_last_known_admin_date)) {
          result = false;
        } else {
          //date must be after LOT_X_tx_in_date in Q59.
          if (
            !IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date) &&
            !Is_DateBefore(
              tpeDetails.q59_initiate_q49_tx_date,
              tpeDetails.q63_last_known_admin_date
            )
          ) {
            result = false;
            setHardErrList((prev) => ({
              ...prev,
              q63_last_known_admin_after_q59_date: true,
            }));
          }
        }
      }
    }

    //Q64-68 Radiological Assessment rpt
    if (radioAssessmentDetails && radioAssessmentDetails.length > 0) {
      const updatedArray = radioAssessmentDetails.map((item) => {
        let q64_radio_assess_date_empty_hard = false;
        let q64_date_invalid = false;
        let q64_after_q59_hard = false;
        let q64_before_q62_hard = false;

        if (!item.rbl_q64_radio_assess) {
          q64_radio_assess_date_empty_hard = true;
        } else if (item.rbl_q64_radio_assess === 1) {
          if (IsDateEmpty(item.q64_radio_assess_date)) {
            q64_radio_assess_date_empty_hard = true;
          } else if (!IsDateValid(item.q64_radio_assess_date)) {
            q64_date_invalid = true;
          } else {
            //date must be after LOT_X_tx_in_date in Q59 and before LOT_X_tx_dis_date in Q62
            if (
              !IsDateEmpty(tpeDetails.q59_initiate_q49_tx_date) &&
              !Is_DateBefore(
                tpeDetails.q59_initiate_q49_tx_date,
                item.q64_radio_assess_date
              )
            ) {
              q64_after_q59_hard = true;
            }
            if (
              !IsDateEmpty(tpeDetails.q62_discontinue_q49_tx_date) &&
              !Is_DateBefore(
                item.q64_radio_assess_date,
                tpeDetails.q62_discontinue_q49_tx_date
              )
            ) {
              q64_before_q62_hard = true;
            }
          }
        }

        const q65_lbcl_status_empty_hard = item.rbl_q65_lbcl_status == null;
        const q65_lbcl_status_oth_txt_empty_hard = q65_lbcl_status_empty_hard
          ? false
          : item.rbl_q65_lbcl_status === 5 &&
            isStringEmpty(item.txt_q65_lbcl_status_oth);
        const q66_criteria_empty_hard = item.rbl_q66_criteria == null;
        const q67_lyric_assess_empty_hard =
          (item.rbl_q66_criteria === 1 || item.rbl_q66_criteria === 3) &&
          item.rbl_q67_lyric_assess == null;
        const q68_radiology_type_empty_hard =
          item.rbl_q68_radiology_type == null;
        const q68_radiology_type_oth_txt_empty_hard =
          q68_radiology_type_empty_hard
            ? false
            : item.rbl_q68_radiology_type === 5 &&
              isStringEmpty(item.txt_q68_radiology_type_oth);

        if (
          q64_radio_assess_date_empty_hard ||
          q64_date_invalid ||
          q64_after_q59_hard ||
          q64_before_q62_hard ||
          q65_lbcl_status_empty_hard ||
          q65_lbcl_status_oth_txt_empty_hard ||
          q66_criteria_empty_hard ||
          q67_lyric_assess_empty_hard ||
          q68_radiology_type_empty_hard ||
          q68_radiology_type_oth_txt_empty_hard
        ) {
          result = false;
        }
        return {
          ...item,
          q64_radio_assess_date_unk_soft: false,
          q65_lbcl_status_unk_soft: false,
          q68_radiology_type_unk_soft: false,
          q65_lbcl_status_empty_hard,
          q65_lbcl_status_oth_txt_empty_hard,
          q66_criteria_empty_hard,
          q67_lyric_assess_empty_hard,
          q68_radiology_type_empty_hard,
          q68_radiology_type_oth_txt_empty_hard,
          q64_radio_assess_date_empty_hard,
          q64_after_q59_hard,
          q64_before_q62_hard,
        };
      });
      setRadioAssessmentDetails(updatedArray);
    }

    //repeater section for yes on question 75: (76 repeater question)

    if (
      selectedOptions.parent[6] === true &&
      tpeDetails.dosesOfTocilizumabNumOfDoses === 1 &&
      doseOfTocilizumabDetails &&
      doseOfTocilizumabDetails.length > 0
    ) {
      const updatedArray = doseOfTocilizumabDetails.map((item) => {
        const siteOfCare_rd_empty = item.siteOfCare_rd === null;
        const siteOfCare_other_empty = siteOfCare_rd_empty
          ? false
          : item.siteOfCare_rd === 4 && isStringEmpty(item.siteOfCare_other);

        if (siteOfCare_rd_empty || siteOfCare_other_empty) {
          result = false;
        }
        return {
          ...item,
          siteOfCare_rd_empty,
          siteOfCare_other_empty,
        };
      });
      setDoseOfTocilizumabDetails(updatedArray);
    }



    if (tpeDetails.dosesOfTocilizumabNumOfDoses == null && selectedOptions.parent[6] === true) {
      setHardErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_empty: true,
      }));
      result = false;
    }








//Q74. checkbox validations

const isParentEmpty = Object.keys(selectedOptions.parent).length === 0;
const allFalse = Object.values(selectedOptions.parent).every(value => value === false);
const isSubEmpty = selectedOptions.sub[7] && Object.keys(selectedOptions.sub[7]).length === 0;
const hasUncheckedSubForSeven = selectedOptions.parent[7] && selectedOptions.sub[7] && Object.values(selectedOptions.sub[7]).every(subChecked => !subChecked);

const subsubDexaEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone])?.length ===0;
const subsubDexaFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone]).every(value => value === false);

const subsubHydroEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone])?.length ===0;
const Hydrocortisone = selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone];      
const subsubHydroFalse = Hydrocortisone && Object.keys(Hydrocortisone)?.length>0 &&  Object.values(Hydrocortisone).every(value => value === false);

const subsubPrednisoneEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])?.length ===0;
const subsubPrednisoneFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone]).every(value => value === false);

const subsubPrednisoloneEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone])?.length ===0;
const subsubPrednisoloneFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] &&  Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone]).every(value => value === false);

const subsubMethylEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone])?.length ===0;
const subsubMethylFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone]).every(value => value === false);

const subsubOtherEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Other] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Other])?.length ===0;

const subOtherTextIsEmpty = selectedOptions.sub && selectedOptions.sub[7] && selectedOptions.sub[7][6];


if (isParentEmpty && radioSelection === "") {
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Empty: true,
  }));
  result = false;
} 


if(selectedOptions.parent[8] && (otherInputs.parent === ""))
    {
        setHardErrList((prev) => ({
            ...prev,
            epco_parent_othertext_empty: true
          }));
          result = false;
       
    }

 if (allFalse && radioSelection === "") {
    result = false;
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Empty: true,
  }));
} 

if (!allFalse && !isParentEmpty && selectedOptions.parent[7] 

) {
 if (isSubEmpty || hasUncheckedSubForSeven 

) {
    result = false;
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Sub_Empty: true,
  }));
} 

  }


if(subsubDexaEmpty || subsubDexaFalse)
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Dexamethasone]: true,
        }));
    }

if(subsubHydroEmpty || subsubHydroFalse)
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Hydrocortisone]: true,
        }));
    }
if(subsubPrednisoneEmpty || subsubPrednisoneFalse)
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Prednisone]: true,
        }));
    }

if(subsubPrednisoloneEmpty || subsubPrednisoloneFalse)
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Prednisolone]: true,
        }));
    }

if(subsubMethylEmpty || subsubMethylFalse)
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Methylprednisolone]: true,
        }));
    }

if((subOtherTextIsEmpty && otherInputs.sub === ""))
    {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            epco_sub_othertext_empty: true,

        }));
    }


    return result;
  };

  useEffect(() => {
    
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0 && lot != 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, [lot]);
  async function LoadData() {
    
    try {
      
      const response =
        await TreatmentResponseService.GetTreatmentPriorEpcoritamabData(
          patientId,
          lot
        );
      if (response?.status === HTTPResponse.OK) {
        setMedicationTypes(response.data.patientMedicationData.medicationTypes);
                    initialSelections(
                        response.data.patientMedicationData.patientTreatment,
                        response.data.patientMedicationData.treatmentMedicationBridges
                      );
            

        const { data } = response;

        const indexDate = response.data.indexDate;
        if (!isStringEmpty(indexDate)) {
          setIndexDate(indexDate);
        }
        const lbclDxDate = response.data.lbclDxDate;
        if (!isStringEmpty(lbclDxDate)) {
          setLBCL_dx_date(lbclDxDate);
        }
        if (data.lotDetails != null) {
          const { lotDetails } = data;


          setTPEDetails((prev) => ({
            ...prev,
            lotId: lotDetails.lotId != null ? lotDetails.lotId : 0,
            chk_q49_anti_cd20_mab: lotDetails.treatmentAnticd20,
            chk_q49_anthracyclines: lotDetails.treatmentAnthracyclines,
            chk_q49_alkylating_agents: lotDetails.treatmentAlkylating,
            chk_q49_autologous_stem_cell_transplant:
              lotDetails.treatmentAutologous,
            chk_q49_allogenic_stem_cell_transplant:
              lotDetails.treatmentAllogenic,
            chk_q49_car_t_cell_therapy: lotDetails.treatmentCartcelltherapy,
            chk_q49_bendamustine: lotDetails.treatmentBendamustine,
            chk_q49_glofitamab: lotDetails.treatmentGlofitamab,
            chk_q49_imvotamab: lotDetails.treatmentImvotamab,
            chk_q49_lenalidomide: lotDetails.treatmentLenalidomide,
            chk_q49_loncastatuximab: lotDetails.treatmentLoncastatuximab,
            chk_q49_mosunetuzumab: lotDetails.treatmentMosunetuzumab,
            chk_q49_odronextamab: lotDetails.treatmentOdronextamab,
            chk_q49_plamotamab: lotDetails.treatmentPlamotamab,
            chk_q49_polatuzumab_vedotin: lotDetails.treatmentPolatuzumab,
            chk_q49_rituximab: lotDetails.treatmentRituximab,
            chk_q49_selinexor: lotDetails.treatmentSelinexor,
            chk_q49_tafasitamab: lotDetails.treatmentTafasitamab,
            chk_q49_tmt_received_oth: lotDetails.treatmentOth,
            txt_q49_tmt_received_oth: lotDetails.treatmentOthTxt,
            rb_q49_tmt_received_unk: lotDetails.treatmentUnk,

            chk_q50_axicabtagene: lotDetails.cartTherapyAxicabtagene,
            chk_q50_brexucabtagene: lotDetails.cartTherapyBrexucabtagene,
            chk_q50_ciltacabtagene: lotDetails.cartTherapyCiltacabtagene,
            chk_q50_idecabtagene: lotDetails.cartTherapyIdecabtagene,
            chk_q50_lisocabtagene: lotDetails.cartTherapyLisocabtagene,
            chk_q50_tisagenlecleucel: lotDetails.cartTherapyTisagenlecleucel,
            chk_q50_cart_type_oth: lotDetails.cartTherapyOth,
            txt_q50_cart_type_oth: lotDetails.cartTherapyOthTxt,

            q51_apheresis_date: !isStringEmpty(lotDetails.undergoApheresisDate)
              ? GetDisplayDate(
                  lotDetails.undergoApheresisDate,
                  lotDetails.undergoApheresisDateDmuid
                )
              : DefaultDate,
            rbl_q51_apheresis: lotDetails.undergoApheresisDateUnk
              ? 99
              : lotDetails.undergoApheresisDate
              ? 1
              : null,

            rbl_q52_receive_bridging_therapy: lotDetails.receiveBridgingTherapy,

            chk_q53_acalabrutinib: lotDetails.bridgingTreatmentAcalabrutinib,
            chk_q53_cisplatin: lotDetails.bridgingTreatmentCisplatin,
            chk_q53_cyclophosphamide:
              lotDetails.bridgingTreatmentCyclophosphamide,
            chk_q53_cytarabine: lotDetails.bridgingTreatmentCytarabine,
            chk_q53_dexamethasone: lotDetails.bridgingTreatmentDexamethasone,
            chk_q53_doxorubicin: lotDetails.bridgingTreatmentDoxorubicin,
            chk_q53_etoposide: lotDetails.bridgingTreatmentEtoposide,
            chk_q53_fludarabine: lotDetails.bridgingTreatmentFludarabine,
            chk_q53_gemcitabine: lotDetails.bridgingTreatmentGemcitabine,
            chk_q53_hydroxyurea: lotDetails.bridgingTreatmentHydroxyurea,
            chk_q53_ibrutinib: lotDetails.bridgingTreatmentIbrutinib,
            chk_q53_idarubicin: lotDetails.bridgingTreatmentIdarubicin,
            chk_q53_lenalidomide: lotDetails.bridgingTreatmentLenalidomide,
            chk_q53_mercaptopurine: lotDetails.bridgingTreatmentMercaptopurine,
            chk_q53_methotrexate: lotDetails.bridgingTreatmentMethotrexate,
            chk_q53_rituximab: lotDetails.bridgingTreatmentRituximab,
            chk_q53_steroids: lotDetails.bridgingTreatmentSteroids,
            chk_q53_tyrosine:
              lotDetails.bridgingTreatmentTyrosineKinaseInhibitor,
            chk_q53_vincristine: lotDetails.bridgingTreatmentVincristine,
            chk_q53_bridging_therapy_oth: lotDetails.bridgingTreatmentOth,
            txt_q53_bridging_therapy_oth: lotDetails.bridgingTreatmentOthTxt,
            rb_q53_bridging_therapy_unk: lotDetails.bridgingTreatmentUnk,

            dosesOfTocilizumabNumOfDoses: lotDetails.tocilizumabDosesReceived,
            
            q54_initiate_bridging_therapy_date: !isStringEmpty(
              lotDetails.bridgingTherapyDate
            )
              ? GetDisplayDate(
                  lotDetails.bridgingTherapyDate,
                  lotDetails.bridgingTherapyDateDmuid
                )
              : DefaultDate,
            rbl_q54_initiate_bridging_therapy: lotDetails.bridgingTherapyDateUnk
              ? 99
              : lotDetails.bridgingTherapyDate
              ? 1
              : null,

            rbl_q55_bridging_therapy_cycles:
              lotDetails.bridgingTherapyCycleReceived,
            txt_q55_bridging_therapy_cycles:
              lotDetails.bridgingTherapyNoOfCycleTxt?.toString() ?? "",

            rbl_q56_receive_lympho_therapy:
              lotDetails.receiveLymphodepletingTherapy,

            chk_q57_cyclo: lotDetails.lymphodepletingTherapyCyclophosphamide,
            chk_q57_fluda: lotDetails.lymphodepletingTherapyFludarabine,
            chk_q57_oth: lotDetails.lymphodepletingTherapyOth,
            txt_q57_oth: lotDetails.lymphodepletingTherapyOthTxt,
            rb_q57_tmt_lympho_therapy_unk: lotDetails.lymphodepletingTherapyUnk,

            q58_initiate_lympho_therapy_date: !isStringEmpty(
              lotDetails.lymphodepletingTherapyDate
            )
              ? GetDisplayDate(
                  lotDetails.lymphodepletingTherapyDate,
                  lotDetails.lymphodepletingTherapyDateDmuid
                )
              : DefaultDate,
            rbl_q58_initiate_lympho_therapy:
              lotDetails.lymphodepletingTherapyDateUnk
                ? 99
                : lotDetails.lymphodepletingTherapyDate
                ? 1
                : null,

            q59_initiate_q49_tx_date: !isStringEmpty(
              lotDetails.lotInitiationDate
            )
              ? GetDisplayDate(
                  lotDetails.lotInitiationDate,
                  lotDetails.lotInitiationDateDmuid
                )
              : DefaultDate,
            rbl_q59_initiate_q49_tx: lotDetails.lotInitiationDateUnk
              ? 99
              : lotDetails.lotInitiationDate
              ? 1
              : null,

            rbl_q60_ipi_risk_score: lotDetails.ipiRiskScore,

            q61_ipi_risk_score_date: !isStringEmpty(
              lotDetails.lotRiskAssessmentDate
            )
              ? GetDisplayDate(
                  lotDetails.lotRiskAssessmentDate,
                  lotDetails.lotRiskAssessmentDateDmuid
                )
              : DefaultDate,
            rbl_q61_ipi_risk_score_date: lotDetails.lotRiskAssessmentDateUnk
              ? 99
              : lotDetails.lotRiskAssessmentDate
              ? 1
              : null,

            q62_discontinue_q49_tx_date: !isStringEmpty(
              lotDetails.discontinueLotDate
            )
              ? GetDisplayDate(
                  lotDetails.discontinueLotDate,
                  lotDetails.discontinueLotDateDmuid
                )
              : DefaultDate,
            rbl_q62_discontinue_q49_tx: lotDetails.discontinueLotDateUnk
              ? 99
              : lotDetails.treatmentStillOn
              ? 2
              : lotDetails.discontinueLotDate
              ? 1
              : null,

            q63_last_known_admin_date: !isStringEmpty(
              lotDetails.lastknownLotAdminDate
            )
              ? GetDisplayDate(
                  lotDetails.lastknownLotAdminDate,
                  lotDetails.lastknownLotAdminDateDmuid
                )
              : DefaultDate,
            rbl_q63_last_known_admin_date: lotDetails.lastknownLotAdminDateUnk
              ? 99
              : lotDetails.lastknownLotAdminDate != null
              ? 1
              : null,

            //this state keeps record of the status before saving to determine whether CAR t page is to be shown or hidden
            chk_q49_car_t_cell_therapy_before:
              lotDetails.treatmentCartcelltherapy,
          }));
          //sifter- 11500 fix
          const initialSelectedTmts = new Map();
          if (lotDetails.treatmentAnticd20) {
            initialSelectedTmts.set(1, "Anti-CD20 mAb");
          }
          if (lotDetails.treatmentAnthracyclines) {
            initialSelectedTmts.set(2, "Anthracyclines");
          }
          if (lotDetails.treatmentAlkylating) {
            initialSelectedTmts.set(3, "Alkylating agents");
          }
          if (lotDetails.treatmentAutologous) {
            initialSelectedTmts.set(4, "Autologous stem cell transplant");
          }
          if (lotDetails.treatmentAllogenic) {
            initialSelectedTmts.set(5, "Allogenic stem cell transplant");
          }
          if (lotDetails.treatmentCartcelltherapy) {
            initialSelectedTmts.set(6, "CAR T cell therapy");
          }
          if (lotDetails.treatmentBendamustine) {
            initialSelectedTmts.set(7, "Bendamustine");
          }
          if (lotDetails.treatmentGlofitamab) {
            initialSelectedTmts.set(8, "Glofitamab");
          }
          if (lotDetails.treatmentImvotamab) {
            initialSelectedTmts.set(9, "Imvotamab");
          }
          if (lotDetails.treatmentLenalidomide) {
            initialSelectedTmts.set(10, "Lenalidomide");
          }
          if (lotDetails.treatmentLoncastatuximab) {
            initialSelectedTmts.set(11, "Loncastatuximab");
          }
          if (lotDetails.treatmentMosunetuzumab) {
            initialSelectedTmts.set(12, "Mosunetuzumab");
          }
          if (lotDetails.treatmentOdronextamab) {
            initialSelectedTmts.set(13, "Odronextamab");
          }
          if (lotDetails.treatmentPlamotamab) {
            initialSelectedTmts.set(14, "Plamotamab");
          }
          if (lotDetails.treatmentPolatuzumab) {
            initialSelectedTmts.set(15, "Polatuzumab vedotin");
          }
          if (lotDetails.treatmentRituximab) {
            initialSelectedTmts.set(16, "Rituximab");
          }
          if (lotDetails.treatmentSelinexor) {
            initialSelectedTmts.set(17, "Selinexor");
          }
          if (lotDetails.treatmentTafasitamab) {
            initialSelectedTmts.set(18, "Tafasitamab");
          }
          if (lotDetails.treatmentOth) {
            initialSelectedTmts.set(19, lotDetails.treatmentOthTxt);
          }
          setSelectedReceivedTmts(initialSelectedTmts);
          const selTmtStr = DisplaySelectedText(new Map(initialSelectedTmts));
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
        }
        //Radiological Assessment rpt
        if (
          data.lotRadiologicalAssessmentDetailsList != null &&
          data.lotRadiologicalAssessmentDetailsList.length > 0
        ) {
          const { lotRadiologicalAssessmentDetailsList } = data;
          const detailsArray = lotRadiologicalAssessmentDetailsList.map(
            (item) => {
              return {
                ...RadioAssessmentRow,
                lotRadiologicalAssessmentId:
                  item.lotRadiologicalAssessmentId != null
                    ? item.lotRadiologicalAssessmentId
                    : 0,
                //lotId: item?.lotId ?? 0,
                rbl_q64_radio_assess: item.radiologicalAssessmentDateUnk
                  ? 99
                  : !isStringEmpty(item.radiologicalAssessmentDate)
                  ? 1
                  : null,
                q64_radio_assess_date: !isStringEmpty(
                  item.radiologicalAssessmentDate
                )
                  ? GetDisplayDate(
                      item.radiologicalAssessmentDate,
                      item.radiologicalAssessmentDateDmuid
                    )
                  : DefaultDate,
                rbl_q65_lbcl_status: item.lbclStatus,
                txt_q65_lbcl_status_oth: item.lbclStatusOthTxt,
                rbl_q66_criteria: item.diseaseStageAssessedPerLyricLugano,
                rbl_q67_lyric_assess: item.diseaseAssessmentPerLyric,
                rbl_q68_radiology_type: item.radiologicalAssessmentType,
                txt_q68_radiology_type_oth:
                  item.radiologicalAssessmentTypeOthTxt,
              };
            }
          );
          setRadioAssessmentDetails(detailsArray);
        }


// siteofcare rpt


if (
  data.siteofcareDetails != null &&
  data.siteofcareDetails.length > 0
) {
  const { siteofcareDetails } = data;
  const tmtpriorDetailsListArray = siteofcareDetails.map((item, index) => {
    const lotsitecarebridgeid =
      item.lotsitecarebridgeid != null
        ? item.lotsitecarebridgeid
        : 0;
   


    return {
      ...DoseOfTocilizumabRow,
      lotsitecarebridgeid,
  
      
      siteOfCare_rd: item.treatmentpriorSiteofcare,
      siteOfCare_other:
        item.treatmentpriorSiteofcareOthTxt,
    };
  });
  setDoseOfTocilizumabDetails(tmtpriorDetailsListArray);
}






        // else{
        //   setRadioAssessmentDetails([RadioAssessmentRow]);
        // }
      } else {
        if (response?.status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else throw response.error;
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  //Q64-68 ECOG Assessment rpt
  const DeleteRadioAssessmentRow = (id) => {
    const newArray = [...radioAssessmentDetails];
    newArray.splice(id, 1);
    setRadioAssessmentDetails(newArray);
  };
  const AddRadioAssessmentRow = () => {
    setRadioAssessmentDetails((prev) => [
      ...prev,
      {
        ...RadioAssessmentRow,
      },
    ]);
  };

  //Repeater
  const HandleRepeaterDateChange = (name, date, datePart) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    switch (name) {
      case "q64_radio_assess_date-" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          const idx = name.slice(lastIndex + 1);
          updatedArray[id].q64_radio_assess_date = SetDateState(
            updatedArray[id].q64_radio_assess_date,
            date,
            datePart
          );
          updatedArray[id].rbl_q64_radio_assess = 1;

          updatedArray[id].q64_radio_assess_date_unk_soft = false;
          updatedArray[id].q64_radio_assess_date_empty_hard = false;
          updatedArray[id].q64_before_q62_hard = false;
          updatedArray[id].q64_after_q59_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      default:
        break;
    }
  };
  const HandleRepeaterFieldChange = (e) => {
    

    const { name, id, value } = e.target;
    const value_int = Number(value);
    switch (name) {
      case "rbl_q64_radio_assess" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q64_radio_assess = value_int;
          updatedArray[id].q64_radio_assess_date = DefaultDate;

          updatedArray[id].q64_radio_assess_date_unk_soft = value_int === 99;
          updatedArray[id].q64_radio_assess_date_empty_hard = false;
          updatedArray[id].q64_before_q62_hard = false;
          updatedArray[id].q64_after_q59_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "rbl_q65_lbcl_status" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q65_lbcl_status = value_int;
          updatedArray[id].txt_q65_lbcl_status_oth = "";

          updatedArray[id].q65_lbcl_status_unk_soft = value_int === 99;
          updatedArray[id].q65_lbcl_status_empty_hard = false;
          updatedArray[id].q65_lbcl_status_oth_txt_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "txt_q65_lbcl_status_oth":
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q65_lbcl_status = 5;
          updatedArray[id].txt_q65_lbcl_status_oth = value;

          updatedArray[id].q65_lbcl_status_unk_soft = false;
          updatedArray[id].q65_lbcl_status_empty_hard = false;
          updatedArray[id].q65_lbcl_status_oth_txt_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "rbl_q66_criteria" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q66_criteria = value_int;
          updatedArray[id].q66_criteria_empty_hard = false;

          // Reset Q67
          updatedArray[id].rbl_q67_lyric_assess = null;
          updatedArray[id].q67_lyric_assess_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "rbl_q67_lyric_assess" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q67_lyric_assess = value_int;

          updatedArray[id].q67_lyric_assess_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "rbl_q68_radiology_type" + id:
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q68_radiology_type = value_int;
          updatedArray[id].txt_q68_radiology_type_oth = "";

          updatedArray[id].q68_radiology_type_unk_soft = value_int === 99;
          updatedArray[id].q68_radiology_type_empty_hard = false;
          updatedArray[id].q68_radiology_type_oth_txt_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;
      case "txt_q68_radiology_type_oth":
        {
          let updatedArray = [...radioAssessmentDetails];
          updatedArray[id].rbl_q68_radiology_type = 5;
          updatedArray[id].txt_q68_radiology_type_oth = value;

          updatedArray[id].q68_radiology_type_unk_soft = false;
          updatedArray[id].q68_radiology_type_empty_hard = false;
          updatedArray[id].q68_radiology_type_oth_txt_empty_hard = false;
          setRadioAssessmentDetails(updatedArray);
        }
        break;

      case "siteOfCare_rd" + id: {
        let updatedArray = [...doseOfTocilizumabDetails];
        updatedArray[id].siteOfCare_rd = value_int;
        updatedArray[id].siteOfCare_other = "";

        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;

        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }
      case "siteOfCare_other": {
        let updatedArray = [...doseOfTocilizumabDetails];
        updatedArray[id].siteOfCare_rd = 4;
        updatedArray[id].siteOfCare_other = value;

        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;

        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }

      default:
        break;
    }
  };
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu
            activePageId={
              SideMenuItems.TreatmentsPriorEpcoritamab.toString() +
              lot.toString()
            }
          />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Treatments Prior to Initiating Epcoritamab</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to provide information
                      about any treatment received after the date of LBCL
                      diagnosis and prior to initiating epcoritamab (
                      <span style={{ color: PageDesignConstants.fontColor }}>
                        {LBCL_dx_date} – {index_date}
                      </span>
                      ).
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q53.</span>
                        <span className="quest-text-pad">
                          What treatments did the patient receive in LOT {lot}?{" "}
                          {""}
                          <i>
                            (select all treatments received as part of the same
                            LOT)
                          </i>
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={1}
                              name="chk_q49_anti_cd20_mab"
                              checked={tpeDetails.chk_q49_anti_cd20_mab}
                              onChange={HandleFieldChange}
                              value="Anti-CD20 mAb"
                            />
                            <span className="radio-text-padding">
                              Anti-CD20 mAb
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={2}
                              name="chk_q49_anthracyclines"
                              checked={tpeDetails.chk_q49_anthracyclines}
                              onChange={HandleFieldChange}
                              value="Anthracyclines"
                            />
                            <span className="radio-text-padding">
                              Anthracyclines
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={3}
                              name="chk_q49_alkylating_agents"
                              checked={tpeDetails.chk_q49_alkylating_agents}
                              onChange={HandleFieldChange}
                              value="Alkylating agents"
                            />
                            <span className="radio-text-padding">
                              Alkylating agents
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={4}
                              name="chk_q49_autologous_stem_cell_transplant"
                              checked={
                                tpeDetails.chk_q49_autologous_stem_cell_transplant
                              }
                              onChange={HandleFieldChange}
                              value="Autologous stem cell transplant"
                            />
                            <span className="radio-text-padding">
                              Autologous stem cell transplant
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={5}
                              name="chk_q49_allogenic_stem_cell_transplant"
                              checked={
                                tpeDetails.chk_q49_allogenic_stem_cell_transplant
                              }
                              onChange={HandleFieldChange}
                              value="Allogenic stem cell transplant"
                            />
                            <span className="radio-text-padding">
                              Allogenic stem cell transplant
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={6}
                              name="chk_q49_car_t_cell_therapy"
                              checked={tpeDetails.chk_q49_car_t_cell_therapy}
                              onChange={HandleFieldChange}
                              value="CAR T cell therapy"
                            />
                            <span className="radio-text-padding">
                              CAR T cell therapy
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={7}
                              name="chk_q49_bendamustine"
                              checked={tpeDetails.chk_q49_bendamustine}
                              onChange={HandleFieldChange}
                              value="Bendamustine"
                            />
                            <span className="radio-text-padding">
                              Bendamustine
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={8}
                              name="chk_q49_glofitamab"
                              checked={tpeDetails.chk_q49_glofitamab}
                              onChange={HandleFieldChange}
                              value="Glofitamab"
                            />
                            <span className="radio-text-padding">
                              Glofitamab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={9}
                              name="chk_q49_imvotamab"
                              checked={tpeDetails.chk_q49_imvotamab}
                              onChange={HandleFieldChange}
                              value="Imvotamab"
                            />
                            <span className="radio-text-padding">
                              Imvotamab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={10}
                              name="chk_q49_lenalidomide"
                              checked={tpeDetails.chk_q49_lenalidomide}
                              onChange={HandleFieldChange}
                              value="Lenalidomide"
                            />
                            <span className="radio-text-padding">
                              Lenalidomide
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={11}
                              name="chk_q49_loncastatuximab"
                              checked={tpeDetails.chk_q49_loncastatuximab}
                              onChange={HandleFieldChange}
                              value="Loncastatuximab"
                            />
                            <span className="radio-text-padding">
                              Loncastatuximab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={12}
                              name="chk_q49_mosunetuzumab"
                              checked={tpeDetails.chk_q49_mosunetuzumab}
                              onChange={HandleFieldChange}
                              value="Mosunetuzumab"
                            />
                            <span className="radio-text-padding">
                              Mosunetuzumab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={13}
                              name="chk_q49_odronextamab"
                              checked={tpeDetails.chk_q49_odronextamab}
                              onChange={HandleFieldChange}
                              value="Odronextamab"
                            />
                            <span className="radio-text-padding">
                              Odronextamab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={14}
                              name="chk_q49_plamotamab"
                              checked={tpeDetails.chk_q49_plamotamab}
                              onChange={HandleFieldChange}
                              value="Plamotamab"
                            />
                            <span className="radio-text-padding">
                              Plamotamab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={15}
                              name="chk_q49_polatuzumab_vedotin"
                              checked={tpeDetails.chk_q49_polatuzumab_vedotin}
                              onChange={HandleFieldChange}
                              value="Polatuzumab vedotin"
                            />
                            <span className="radio-text-padding">
                              Polatuzumab vedotin
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={16}
                              name="chk_q49_rituximab"
                              checked={tpeDetails.chk_q49_rituximab}
                              onChange={HandleFieldChange}
                              value="Rituximab"
                            />
                            <span className="radio-text-padding">
                              Rituximab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={17}
                              name="chk_q49_selinexor"
                              checked={tpeDetails.chk_q49_selinexor}
                              onChange={HandleFieldChange}
                              value="Selinexor"
                            />
                            <span className="radio-text-padding">
                              Selinexor
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={18}
                              name="chk_q49_tafasitamab"
                              checked={tpeDetails.chk_q49_tafasitamab}
                              onChange={HandleFieldChange}
                              value="Tafasitamab"
                            />
                            <span className="radio-text-padding">
                              Tafasitamab
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              id={19}
                              name="chk_q49_tmt_received_oth"
                              checked={tpeDetails.chk_q49_tmt_received_oth}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                id={19}
                                name="txt_q49_tmt_received_oth"
                                className="input-dash"
                                type="text"
                                value={tpeDetails.txt_q49_tmt_received_oth}
                                onChange={HandleFieldChange}
                                maxLength={100}
                              />
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rb_q49_tmt_received_unk"
                              name="rb_q49_tmt_received_unk"
                              //value={99}
                              checked={tpeDetails.rb_q49_tmt_received_unk}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q49_tmt_received_unk}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.q49_tmt_received_empty}
                        message={TreatmentPriorEpco_Msgs.tmt_received_empty.replace(
                          "{id}",
                          lot
                        )}
                      />
                      <ErrorField
                        show={hardErrList.q49_tmt_received_oth_txt_empty}
                        message={
                          TreatmentPriorEpco_Msgs.tmt_received_oth_txt_empty
                        }
                      />
                    </div>
                    {tpeDetails.chk_q49_car_t_cell_therapy && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q54.</span>
                          <span className="quest-text-pad">
                            What type of CAR T therapy did the patient undergo?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_axicabtagene"
                                name="chk_q50_axicabtagene"
                                checked={tpeDetails.chk_q50_axicabtagene}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Axicabtagene ciloleuce
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_brexucabtagene"
                                name="chk_q50_brexucabtagene"
                                checked={tpeDetails.chk_q50_brexucabtagene}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Brexucabtagene autoleucel
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_ciltacabtagene"
                                name="chk_q50_ciltacabtagene"
                                checked={tpeDetails.chk_q50_ciltacabtagene}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Ciltacabtagene autoleucel
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_idecabtagene"
                                name="chk_q50_idecabtagene"
                                checked={tpeDetails.chk_q50_idecabtagene}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Idecabtagene vicleucel
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_lisocabtagene"
                                name="chk_q50_lisocabtagene"
                                checked={tpeDetails.chk_q50_lisocabtagene}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Lisocabtagene maraleucel
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_tisagenlecleucel "
                                name="chk_q50_tisagenlecleucel"
                                checked={tpeDetails.chk_q50_tisagenlecleucel}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Tisagenlecleucel
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                id="chk_q50_cart_type_oth"
                                name="chk_q50_cart_type_oth"
                                checked={tpeDetails.chk_q50_cart_type_oth}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Other, specify:{" "}
                                <input
                                  name="txt_q50_cart_type_oth"
                                  className="input-dash"
                                  type="text"
                                  value={tpeDetails.txt_q50_cart_type_oth}
                                  onChange={HandleFieldChange}
                                  maxLength={100}
                                />
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={hardErrList.q50_cart_type_empty}
                          message={TreatmentPriorEpco_Msgs.cart_type_empty}
                        />
                        <ErrorField
                          show={hardErrList.q50_cart_type_oth_txt_empty}
                          message={
                            TreatmentPriorEpco_Msgs.cart_type_oth_txt_empty
                          }
                        />
                      </div>
                    )}
                    {tpeDetails.chk_q49_car_t_cell_therapy && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q55.</span>
                          <span className="quest-text-pad">
                            When did the patient undergo apheresis?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="rbl_q51_apheresis_1"
                              name="rbl_q51_apheresis"
                              value={1}
                              checked={tpeDetails.rbl_q51_apheresis === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"q51_apheresis_date"}
                                HandleDateChange={HandleDateChange}
                                date={tpeDetails.q51_apheresis_date}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                            </span>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="rbl_q51_apheresis_99"
                                name="rbl_q51_apheresis"
                                value={99}
                                checked={tpeDetails.rbl_q51_apheresis === 99}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={softErrList.q51_apheresis_date_unk}
                          message={CommonError_Msgs.UnknownSelect}
                        />
                        <ErrorField
                          show={hardErrList.q51_apheresis_empty}
                          message={TreatmentPriorEpco_Msgs.apheresis_empty}
                        />
                        
                        <ErrorField
                          show={hardErrList.q51_apheresisDateValid}
                          message={TreatmentPriorEpco_Msgs.Date_before_Index}
                        />
                      </div>
                    )}
                    {tpeDetails.chk_q49_car_t_cell_therapy && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q56.</span>
                          <span className="quest-text-pad">
                            Did the patient receive bridging therapy prior to
                            initiating CAR T cell therapy?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q52_receive_bridging_therapy_1"
                                name="rbl_q52_receive_bridging_therapy"
                                value={1}
                                checked={
                                  tpeDetails.rbl_q52_receive_bridging_therapy ===
                                  1
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">Yes</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q52_receive_bridging_therapy_0"
                                name="rbl_q52_receive_bridging_therapy"
                                value={0}
                                checked={
                                  tpeDetails.rbl_q52_receive_bridging_therapy ===
                                  0
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">No</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q52_receive_bridging_therapy_99"
                                name="rbl_q52_receive_bridging_therapy"
                                value={99}
                                checked={
                                  tpeDetails.rbl_q52_receive_bridging_therapy ===
                                  99
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={softErrList.q52_receive_bridging_therapy_unk}
                          message={CommonError_Msgs.UnknownSelect}
                        />
                        <ErrorField
                          show={hardErrList.q52_receive_bridging_therapy_empty}
                          message={
                            TreatmentPriorEpco_Msgs.receive_bridging_therapy_empty
                          }
                        />
                      </div>
                    )}
                    {tpeDetails.chk_q49_car_t_cell_therapy &&
                      tpeDetails.rbl_q52_receive_bridging_therapy === 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q57.</span>
                            <span className="quest-text-pad">
                              What treatments did the patient receive as
                              bridging therapies prior to initiating CAR T cell
                              therapy?{" "}
                              <i>
                                (select all treatments received as part of the
                                same LOT)
                              </i>
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_acalabrutinib_1"
                                  name="chk_q53_acalabrutinib"
                                  checked={tpeDetails.chk_q53_acalabrutinib}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Acalabrutinib
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_cisplatin_2"
                                  name="chk_q53_cisplatin"
                                  checked={tpeDetails.chk_q53_cisplatin}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Cisplatin
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_cyclophosphamide_3"
                                  name="chk_q53_cyclophosphamide"
                                  checked={tpeDetails.chk_q53_cyclophosphamide}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Cyclophosphamide
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_cytarabine_4"
                                  name="chk_q53_cytarabine"
                                  checked={tpeDetails.chk_q53_cytarabine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Cytarabine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_dexamethasone_5"
                                  name="chk_q53_dexamethasone"
                                  checked={tpeDetails.chk_q53_dexamethasone}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Dexamethasone
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_doxorubicin_6"
                                  name="chk_q53_doxorubicin"
                                  checked={tpeDetails.chk_q53_doxorubicin}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Doxorubicin
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_etoposide_7"
                                  name="chk_q53_etoposide"
                                  checked={tpeDetails.chk_q53_etoposide}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Etoposide
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_fludarabine_8"
                                  name="chk_q53_fludarabine"
                                  checked={tpeDetails.chk_q53_fludarabine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Fludarabine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_gemcitabine_9"
                                  name="chk_q53_gemcitabine"
                                  checked={tpeDetails.chk_q53_gemcitabine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Gemcitabine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_hydroxyurea_10"
                                  name="chk_q53_hydroxyurea"
                                  checked={tpeDetails.chk_q53_hydroxyurea}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Hydroxyurea
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_ibrutinib_11"
                                  name="chk_q53_ibrutinib"
                                  checked={tpeDetails.chk_q53_ibrutinib}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Ibrutinib
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_idarubicin_12"
                                  name="chk_q53_idarubicin"
                                  checked={tpeDetails.chk_q53_idarubicin}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Idarubicin
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_lenalidomide_13"
                                  name="chk_q53_lenalidomide"
                                  checked={tpeDetails.chk_q53_lenalidomide}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Lenalidomide
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_mercaptopurine_14"
                                  name="chk_q53_mercaptopurine"
                                  checked={tpeDetails.chk_q53_mercaptopurine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Mercaptopurine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_methotrexate_15"
                                  name="chk_q53_methotrexate"
                                  checked={tpeDetails.chk_q53_methotrexate}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Methotrexate
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_rituximab_16"
                                  name="chk_q53_rituximab"
                                  checked={tpeDetails.chk_q53_rituximab}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Rituximab
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_steroids_17"
                                  name="chk_q53_steroids"
                                  checked={tpeDetails.chk_q53_steroids}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Steroids
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_tyrosine_18"
                                  name="chk_q53_tyrosine"
                                  checked={tpeDetails.chk_q53_tyrosine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Tyrosine kinase inhibitor
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_vincristine_19"
                                  name="chk_q53_vincristine"
                                  checked={tpeDetails.chk_q53_vincristine}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Vincristine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q53_bridging_therapy_oth_20"
                                  name="chk_q53_bridging_therapy_oth"
                                  checked={
                                    tpeDetails.chk_q53_bridging_therapy_oth
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                    name="txt_q53_bridging_therapy_oth"
                                    className="input-dash"
                                    type="text"
                                    value={
                                      tpeDetails.txt_q53_bridging_therapy_oth
                                    }
                                    onChange={HandleFieldChange}
                                    maxLength={100}
                                  />
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  id="rb_q53_bridging_therapy_unk"
                                  name="rb_q53_bridging_therapy_unk"
                                  //value={99}
                                  checked={
                                    tpeDetails.rb_q53_bridging_therapy_unk
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q53_bridging_therapy_unk}
                            message={CommonError_Msgs.UnknownSelect}
                          />
                          <ErrorField
                            show={hardErrList.q53_bridging_therapy_empty}
                            message={
                              TreatmentPriorEpco_Msgs.bridging_therapy_empty
                            }
                          />
                          <ErrorField
                            show={
                              hardErrList.q53_bridging_therapy_oth_txt_empty
                            }
                            message={
                              TreatmentPriorEpco_Msgs.bridging_therapy_oth_txt_empty
                            }
                          />
                        </div>
                      )}
                    {tpeDetails.chk_q49_car_t_cell_therapy &&
                      tpeDetails.rbl_q52_receive_bridging_therapy === 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q58.</span>
                            <span className="quest-text-pad">
                              When did the patient initiate the bridging
                              therapies prior to initiating CAR T cell therapy?
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id="rbl_q54_initiate_bridging_therapy_1"
                                name="rbl_q54_initiate_bridging_therapy"
                                value={1}
                                checked={
                                  tpeDetails.rbl_q54_initiate_bridging_therapy ===
                                  1
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"q54_initiate_bridging_therapy_date"}
                                  HandleDateChange={HandleDateChange}
                                  date={
                                    tpeDetails.q54_initiate_bridging_therapy_date
                                  }
                                  showUnkInfoTxt={true}
                                />
                                {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                              </span>
                            </div>
                            <div className="">
                              <label>
                                <input
                                  type="radio"
                                  id="rbl_q54_initiate_bridging_therapy_99"
                                  name="rbl_q54_initiate_bridging_therapy"
                                  value={99}
                                  checked={
                                    tpeDetails.rbl_q54_initiate_bridging_therapy ===
                                    99
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={
                              softErrList.q54_initiate_bridging_therapy_date_unk
                            }
                            message={CommonError_Msgs.UnknownSelect}
                          />
                          <ErrorField
                            show={
                              hardErrList.q54_initiate_bridging_therapy_empty
                            }
                            message={
                              TreatmentPriorEpco_Msgs.initiate_bridging_therapy_empty
                            }  
                          />
                                <ErrorField
                            show={
                              hardErrList.q54_initiate_bridging_therapy_dateValidate
                            }
                            message={
                              TreatmentPriorEpco_Msgs.Date_before_Index
                            }  
                          />
                        </div>
                      )}
                    {tpeDetails.chk_q49_car_t_cell_therapy &&
                      tpeDetails.rbl_q52_receive_bridging_therapy === 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q59.</span>
                            <span className="quest-text-pad">
                              How many cycles of bridging therapy did the
                              patient receive? {""}
                              <i>(Please only input numbers)</i>
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  id="rbl_q55_bridging_therapy_cycles_1"
                                  name="rbl_q55_bridging_therapy_cycles"
                                  value={1}
                                  checked={
                                    tpeDetails.rbl_q55_bridging_therapy_cycles ===
                                    1
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Specify number of cycles:{" "}
                                  <input
                                    name="txt_q55_bridging_therapy_cycles"
                                    className="sub-input-dash"
                                    type="text"
                                    value={
                                      tpeDetails.txt_q55_bridging_therapy_cycles
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  id="rbl_q55_bridging_therapy_cycles_99"
                                  name="rbl_q55_bridging_therapy_cycles"
                                  value={99}
                                  checked={
                                    tpeDetails.rbl_q55_bridging_therapy_cycles ===
                                    99
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q55_bridging_therapy_cycles_unk}
                            message={CommonError_Msgs.UnknownSelect}
                          />
                          <ErrorField
                            show={
                              hardErrList.q55_bridging_therapy_cycles_empty ||
                              hardErrList.q55_bridging_therapy_cycles_txt_empty
                            }
                            message={
                              TreatmentPriorEpco_Msgs.bridging_therapy_cycles_empty
                            }
                          />
                          <ErrorField
                            show={
                              hardErrList.q55_bridging_therapy_cycles_range_limit
                            }
                            message={TreatmentPriorEpco_Msgs.RangeLimit}
                          />
                        </div>
                      )}
                    {tpeDetails.chk_q49_car_t_cell_therapy && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q60.</span>
                          <span className="quest-text-pad">
                            Did the patient receive lymphodepleting therapy
                            prior to initiating CAR T cell therapy?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q56_receive_lympho_therapy_1"
                                name="rbl_q56_receive_lympho_therapy"
                                value={1}
                                checked={
                                  tpeDetails.rbl_q56_receive_lympho_therapy ===
                                  1
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">Yes</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q56_receive_lympho_therapy_0"
                                name="rbl_q56_receive_lympho_therapy"
                                value={0}
                                checked={
                                  tpeDetails.rbl_q56_receive_lympho_therapy ===
                                  0
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">No</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                id="rbl_q56_receive_lympho_therapy_99"
                                name="rbl_q56_receive_lympho_therapy"
                                value={99}
                                checked={
                                  tpeDetails.rbl_q56_receive_lympho_therapy ===
                                  99
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={softErrList.q56_receive_lympho_therapy_unk}
                          message={CommonError_Msgs.UnknownSelect}
                        />
                        <ErrorField
                          show={hardErrList.q56_receive_lympho_therapy_empty}
                          message={
                            TreatmentPriorEpco_Msgs.receive_lymphodepleting_therapy_empty
                          }
                        />
                      </div>
                    )}
                    {tpeDetails.chk_q49_car_t_cell_therapy &&
                      tpeDetails.rbl_q56_receive_lympho_therapy === 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q61.</span>
                            <span className="quest-text-pad">
                              What treatments did the patient receive as
                              lymphodepleting therapies prior to initiating CAR
                              T cell therapy?{" "}
                              <i>
                                (select all treatments received as part of the
                                same LOT)
                              </i>
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q57_cyclo"
                                  name="chk_q57_cyclo"
                                  checked={tpeDetails.chk_q57_cyclo}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Cyclophosphamide
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q57_fluda"
                                  name="chk_q57_fluda"
                                  checked={tpeDetails.chk_q57_fluda}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Fludarabine
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  id="chk_q57_oth"
                                  name="chk_q57_oth"
                                  checked={tpeDetails.chk_q57_oth}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                    name="txt_q57_oth"
                                    className="input-dash"
                                    type="text"
                                    value={tpeDetails.txt_q57_oth}
                                    onChange={HandleFieldChange}
                                    maxLength={100}
                                  />
                                </span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  id="rb_q57_tmt_lympho_therapy_unk"
                                  name="rb_q57_tmt_lympho_therapy_unk"
                                  //value={99}
                                  checked={
                                    tpeDetails.rb_q57_tmt_lympho_therapy_unk
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q57_tmt_lympho_therapy_unk}
                            message={CommonError_Msgs.UnknownSelect}
                          />
                          <ErrorField
                            show={hardErrList.q57_tmt_lympho_therapy_empty}
                            message={
                              TreatmentPriorEpco_Msgs.tmt_lympho_therapy_empty
                            }
                          />
                          <ErrorField
                            show={
                              hardErrList.q57_tmt_lympho_therapy_oth_txt_empty
                            }
                            message={
                              TreatmentPriorEpco_Msgs.tmt_lympho_therapy_oth_txt_empty
                            }
                          />
                        </div>
                      )}
                    {tpeDetails.chk_q49_car_t_cell_therapy &&
                      tpeDetails.rbl_q56_receive_lympho_therapy === 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q62.</span>
                            <span className="quest-text-pad">
                              When did the patient initiate the lymphodepleting
                              therapies prior to initiating CAR T cell therapy?
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id="rbl_q58_initiate_lympho_therapy_1"
                                name="rbl_q58_initiate_lympho_therapy"
                                value={1}
                                checked={
                                  tpeDetails.rbl_q58_initiate_lympho_therapy ===
                                  1
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"q58_initiate_lympho_therapy_date"}
                                  HandleDateChange={HandleDateChange}
                                  date={
                                    tpeDetails.q58_initiate_lympho_therapy_date
                                  }
                                  showUnkInfoTxt={true}
                                />
                                {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                              </span>
                            </div>
                            <div className="">
                              <label>
                                <input
                                  type="radio"
                                  id="rbl_q58_initiate_lympho_therapy_99"
                                  name="rbl_q58_initiate_lympho_therapy"
                                  value={99}
                                  checked={
                                    tpeDetails.rbl_q58_initiate_lympho_therapy ===
                                    99
                                  }
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={
                              softErrList.q58_initiate_lympho_therapy_date_unk
                            }
                            message={CommonError_Msgs.UnknownSelect}
                          />
                          <ErrorField
                            show={hardErrList.q58_initiate_lympho_therapy_empty}
                            message={
                              TreatmentPriorEpco_Msgs.initiate_lymphodepleting_therapy_empty
                            }
                          />
                          <ErrorField
                            show={hardErrList.q58_initiate_lympho_therapy_datevalidate}
                            message={
                              TreatmentPriorEpco_Msgs.Date_before_Index
                            }
                          />
                        </div>
                      )}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q63.</span>
                        <span className="quest-text-pad">
                          When did the patient initiate {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {selectedReceivedTmtsStr}
                            {/* {isStringEmpty(DisplaySelectedText(selectedReceivedTmts))
                              ? "[Q49_treatment]"
                              : DisplaySelectedText(selectedReceivedTmts)
                            } */}
                          </span>
                          ?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="rbl_q59_initiate_q49_tx_1"
                            name="rbl_q59_initiate_q49_tx"
                            value={1}
                            checked={tpeDetails.rbl_q59_initiate_q49_tx === 1}
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"q59_initiate_q49_tx_date"}
                              HandleDateChange={HandleDateChange}
                              date={tpeDetails.q59_initiate_q49_tx_date}
                              showUnkInfoTxt={true}
                            />
                            {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                          </span>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="rbl_q59_initiate_q49_tx_99"
                              name="rbl_q59_initiate_q49_tx"
                              value={99}
                              checked={
                                tpeDetails.rbl_q59_initiate_q49_tx === 99
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q59_initiate_q49_tx_date_unk}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.q59_initiate_q49_tx_empty}
                        message={TreatmentPriorEpco_Msgs.initiate_tx_empty.replace(
                          "{tx}",
                          selectedReceivedTmtsStr
                        )}
                      />
                      <ErrorField
                        show={hardErrList.q59_initiate_before_index_date}
                        message={TreatmentPriorEpco_Msgs.initiate_before_index_date.replace(
                          "{tx}",
                          selectedReceivedTmtsStr
                        )}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q64.</span>
                        <span className="quest-text-pad">
                          What was the patient’s International Prognostic Index
                          (IPI) risk score closest to or at {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {selectedReceivedTmtsStr}
                          </span>{" "}
                          initiation?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rbl_q60_ipi_risk_score_1"
                              name="rbl_q60_ipi_risk_score"
                              value={1}
                              checked={tpeDetails.rbl_q60_ipi_risk_score === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              0-1 points (low risk)
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rbl_q60_ipi_risk_score_2"
                              name="rbl_q60_ipi_risk_score"
                              value={2}
                              checked={tpeDetails.rbl_q60_ipi_risk_score === 2}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              2 points (low-intermediate risk)
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rbl_q60_ipi_risk_score_3"
                              name="rbl_q60_ipi_risk_score"
                              value={3}
                              checked={tpeDetails.rbl_q60_ipi_risk_score === 3}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              3 points (high-intermediate risk)
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rbl_q60_ipi_risk_score_4"
                              name="rbl_q60_ipi_risk_score"
                              value={4}
                              checked={tpeDetails.rbl_q60_ipi_risk_score === 4}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              4-5 points (high-risk)
                            </span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="rbl_q60_ipi_risk_score_99"
                              name="rbl_q60_ipi_risk_score"
                              value={99}
                              checked={tpeDetails.rbl_q60_ipi_risk_score === 99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q60_ipi_risk_score_unk}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.q60_ipi_risk_score_empty}
                        message={TreatmentPriorEpco_Msgs.ipi_risk_score_empty.replace(
                          "{tx}",
                          selectedReceivedTmtsStr
                        )}
                      />
                    </div>
                    {tpeDetails.rbl_q60_ipi_risk_score !== 99 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q65.</span>
                          <span className="quest-text-pad">
                            What was the date of assessment of the patient’s
                            International Prognostic Index (IPI) risk score?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="rbl_q61_ipi_risk_score_date_1"
                              name="rbl_q61_ipi_risk_score_date"
                              value={1}
                              checked={
                                tpeDetails.rbl_q61_ipi_risk_score_date === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"q61_ipi_risk_score_date"}
                                HandleDateChange={HandleDateChange}
                                date={tpeDetails.q61_ipi_risk_score_date}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                            </span>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="rbl_q61_ipi_risk_score_date_99"
                                name="rbl_q61_ipi_risk_score_date"
                                value={99}
                                checked={
                                  tpeDetails.rbl_q61_ipi_risk_score_date === 99
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={softErrList.q61_ipi_risk_score_date_unk}
                          message={CommonError_Msgs.UnknownSelect}
                        />
                        <ErrorField
                          show={hardErrList.q61_ipi_risk_score_date_empty}
                          message={
                            TreatmentPriorEpco_Msgs.ipi_risk_score_date_empty
                          }
                        />
                        
                        <ErrorField
                          show={hardErrList.q61_ipi_risk_score_datevalidate}
                          message={
                            TreatmentPriorEpco_Msgs.Date_before_Index
                          }
                        />
                      </div>
                    )}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q66.</span>
                        <span className="quest-text-pad">
                          When did the patient discontinue {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {selectedReceivedTmtsStr}
                          </span>
                          ?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="rbl_q62_discontinue_q49_tx_1"
                            name="rbl_q62_discontinue_q49_tx"
                            value={1}
                            checked={
                              tpeDetails.rbl_q62_discontinue_q49_tx === 1
                            }
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"q62_discontinue_q49_tx_date"}
                              HandleDateChange={HandleDateChange}
                              date={tpeDetails.q62_discontinue_q49_tx_date}
                              showUnkInfoTxt={true}
                            />
                            {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                          </span>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="rbl_q62_discontinue_q49_tx_2"
                              name="rbl_q62_discontinue_q49_tx"
                              value={2}
                              checked={
                                tpeDetails.rbl_q62_discontinue_q49_tx === 2
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              The patient is still on treatment{" "}
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="rbl_q62_discontinue_q49_tx_99"
                              name="rbl_q62_discontinue_q49_tx"
                              value={99}
                              checked={
                                tpeDetails.rbl_q62_discontinue_q49_tx === 99
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q62_discontinue_q49_tx_date_unk}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.q62_discontinue_q49_tx_date_empty}
                        message={TreatmentPriorEpco_Msgs.discontinue_date_empty.replace(
                          "{tx}",
                          selectedReceivedTmtsStr
                        )}
                      />
                      <ErrorField
                        show={hardErrList.q62_discontinue_after_q59_date}
                        message={TreatmentPriorEpco_Msgs.discontinue_after_LOT_X_tx_in_date.replace(
                          "{tx}",
                          selectedReceivedTmtsStr
                        )}
                      />
                    </div>
                    {tpeDetails.rbl_q62_discontinue_q49_tx === 99 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q67.</span>
                          <span className="quest-text-pad">
                            What was the last known date of{" "}
                            <span
                              style={{ color: PageDesignConstants.fontColor }}
                            >
                              {selectedReceivedTmtsStr}
                            </span>{" "}
                            administration?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="rbl_q63_last_known_admin_date_1"
                              name="rbl_q63_last_known_admin_date"
                              value={1}
                              checked={
                                tpeDetails.rbl_q63_last_known_admin_date === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"q63_last_known_admin_date"}
                                HandleDateChange={HandleDateChange}
                                date={tpeDetails.q63_last_known_admin_date}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                            </span>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="rbl_q63_last_known_admin_date_99"
                                name="rbl_q63_last_known_admin_date"
                                value={99}
                                checked={
                                  tpeDetails.rbl_q63_last_known_admin_date ===
                                  99
                                }
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={
                            softErrList.q63_last_known_admin_q49_tx_date_unk
                          }
                          message={CommonError_Msgs.UnknownSelect}
                        />
                        <ErrorField
                          show={
                            hardErrList.q63_last_known_admin_q49_tx_date_empty
                          }
                          message={TreatmentPriorEpco_Msgs.last_known_admin_date_empty.replace(
                            "{tx}",
                            selectedReceivedTmtsStr
                          )}
                        />
                        <ErrorField
                          show={hardErrList.q63_last_known_admin_after_q59_date}
                          message={TreatmentPriorEpco_Msgs.last_known_admin_after_LOT_X_tx_in_date.replace(
                            "{tx}",
                            selectedReceivedTmtsStr
                          )}
                        />
                      </div>
                    )}
                    {/* Q64-68 Radiological Assessment rpt */}
                    <div className="dependent-section">
                      {radioAssessmentDetails &&
                        radioAssessmentDetails.length > 0 &&
                        radioAssessmentDetails.map((data, idx) => (
                          <div className="loop-section-ul">
                            <div className="loop-head">
                              <span className="question-weight">
                                {GetOrdinal(idx + 1)} Radiological Assessment
                              </span>
                              {idx != 0 && (
                                <div className="delete-btn-div">
                                  <img
                                    width="16px"
                                    src="../Assets/images/Icon-material-delete.png"
                                    alt=""
                                    onClick={() =>
                                      DeleteRadioAssessmentRow(idx)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q68.</span>
                                <span className="quest-text-pad">
                                  What was the date of the{" "}
                                  {GetOrdinalInWord(idx + 1)} radiological
                                  assessment after the patient initiated{" "}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {selectedReceivedTmtsStr}
                                  </span>
                                  ?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="date-field-padding">
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"rbl_q64_radio_assess" + idx}
                                    value={1}
                                    checked={data.rbl_q64_radio_assess === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId={"q64_radio_assess_date-" + idx}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      date={data.q64_radio_assess_date}
                                      showUnkInfoTxt={true}
                                    />
                                    {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                                  </span>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q64_radio_assess" + idx}
                                      value={99}
                                      checked={data.rbl_q64_radio_assess === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q64_radio_assess_date_unk_soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q64_radio_assess_date_empty_hard}
                                message={TreatmentPriorEpco_Msgs.radio_assess_date_empty
                                  .replace("{id}", GetOrdinalInWord(idx + 1))
                                  .replace("{tx}", selectedReceivedTmtsStr)}
                              />
                              <ErrorField
                                show={data.q64_after_q59_hard}
                                message={TreatmentPriorEpco_Msgs.LOT_X_tx_in_date.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                ).replace("{tx}", selectedReceivedTmtsStr)}
                              />
                              <ErrorField
                                show={data.q64_before_q62_hard}
                                message={TreatmentPriorEpco_Msgs.LOT_X_tx_dis_date.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                ).replace("{tx}", selectedReceivedTmtsStr)}
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q69.</span>
                                <span className="quest-text-pad">
                                  Which of the following categories best
                                  describes the patient’s LBCL disease status at
                                  this radiological assessment? This can be
                                  based on the conclusion of the radiologic
                                  report or physician’s notes in the chart.
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={1}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 1}
                                    />
                                    <span className="radio-text-padding">
                                      Clinician-assessed complete response
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={2}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 2}
                                    />
                                    <span className="radio-text-padding">
                                      Clinician-assessed less-than-complete
                                      response (significant tumor shrinkage)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label className="long-text-option">
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={3}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 3}
                                    />
                                    <span className="radio-text-padding">
                                      Clinician-assessed stable disease (may
                                      include minimal increases or decreases in
                                      size of tumor or permitting ongoing
                                      systemic therapy)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={4}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 4}
                                    />
                                    <span className="radio-text-padding">
                                      Progressive disease
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={5}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 5}
                                    />
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"txt_q65_lbcl_status_oth"}
                                        id={idx}
                                        type="text"
                                        value={data.txt_q65_lbcl_status_oth}
                                        onChange={HandleRepeaterFieldChange}
                                        maxLength={100}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q65_lbcl_status" + idx}
                                      value={99}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.rbl_q65_lbcl_status === 99}
                                    />
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q65_lbcl_status_unk_soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q65_lbcl_status_empty_hard}
                                message={
                                  TreatmentPriorEpco_Msgs.lbcl_status_empty
                                }
                              />
                              <ErrorField
                                show={data.q65_lbcl_status_oth_txt_empty_hard}
                                message={
                                  TreatmentPriorEpco_Msgs.lbcl_status_oth_txt_empty
                                }
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q70.</span>
                                <span className="quest-text-pad">
                                  Was the disease stage assessed per LYRIC or
                                  Lugano criteria?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q66_criteria" + idx}
                                      value={1}
                                      checked={data.rbl_q66_criteria === 1}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      LYRIC
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q66_criteria" + idx}
                                      value={2}
                                      checked={data.rbl_q66_criteria === 2}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Lugano
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q66_criteria" + idx}
                                      value={3}
                                      checked={data.rbl_q66_criteria === 3}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      LYRIC and Lugano
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q66_criteria" + idx}
                                      value={4}
                                      checked={data.rbl_q66_criteria === 4}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Neither
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q66_criteria_empty_hard}
                                message={TreatmentPriorEpco_Msgs.criteria_empty}
                              />
                            </div>
                            {(data.rbl_q66_criteria === 1 ||
                              data.rbl_q66_criteria === 3) && (
                              <div className="sub-question-bot-sapce answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q71.</span>
                                  <span className="quest-text-pad">
                                    What was the disease assessment per LYRIC
                                    criteria?
                                  </span>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={1}
                                        checked={
                                          data.rbl_q67_lyric_assess === 1
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        IR1
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={2}
                                        checked={
                                          data.rbl_q67_lyric_assess === 2
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        IR2
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={3}
                                        checked={
                                          data.rbl_q67_lyric_assess === 3
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        IR1 and IR3
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={4}
                                        checked={
                                          data.rbl_q67_lyric_assess === 4
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        IR2 and IR3
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={5}
                                        checked={
                                          data.rbl_q67_lyric_assess === 5
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        IR3
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={6}
                                        checked={
                                          data.rbl_q67_lyric_assess === 6
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        Stable disease
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={7}
                                        checked={
                                          data.rbl_q67_lyric_assess === 7
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        Partial response
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={8}
                                        checked={
                                          data.rbl_q67_lyric_assess === 8
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        Complete response
                                      </span>
                                    </label>
                                  </div>
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"rbl_q67_lyric_assess" + idx}
                                        value={9}
                                        checked={
                                          data.rbl_q67_lyric_assess === 9
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        Progressive disease
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={data.q67_lyric_assess_empty_hard}
                                  message={
                                    TreatmentPriorEpco_Msgs.lyric_assess_empty
                                  }
                                />
                              </div>
                            )}
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q72.</span>
                                <span className="quest-text-pad">
                                  What type of radiological assessment was used?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={1}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 1
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      PET or PET/CT
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={2}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 2
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      CT
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={3}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 3
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      Other imaging
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={4}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 4
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      Clinical only (no imaging)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={5}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 5
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"txt_q68_radiology_type_oth"}
                                        id={idx}
                                        type="text"
                                        value={data.txt_q68_radiology_type_oth}
                                        onChange={HandleRepeaterFieldChange}
                                        maxLength={100}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"rbl_q68_radiology_type" + idx}
                                      value={99}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={
                                        data.rbl_q68_radiology_type === 99
                                      }
                                    />
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q68_radiology_type_unk_soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q68_radiology_type_empty_hard}
                                message={
                                  TreatmentPriorEpco_Msgs.radiology_type_empty
                                }
                              />
                              <ErrorField
                                show={
                                  data.q68_radiology_type_oth_txt_empty_hard
                                }
                                message={
                                  TreatmentPriorEpco_Msgs.radiology_type_oth_txt_empty
                                }
                              />
                            </div>
                          </div>
                        ))}
                      {radioAssessmentDetails &&
                        radioAssessmentDetails.length < 8 && (
                          <div className="sub-question-bot-sapce-ul mt-2">
                            <div
                              className="answer-list-text"
                              
                            >
                              <a onClick={AddRadioAssessmentRow}>
                                <img
                                  width="18px"
                                  src="../Assets/images/plus.png"
                                  alt=""
                                />
                              </a>
                              <a onClick={AddRadioAssessmentRow} className="radio-text-padding">
                                Click here to add another radiological
                                assessment
                              </a>
                            </div>
                          </div>
                        )}
                    </div>
                    
                    {/* Q74. */}
                    <div className="sub-question-bot-sapce answer-pad-left">
                    <div className="question question-weight">
                        <span>Q74.</span>
                        <span className="quest-text-pad">
                          Did the patient receive any of the following
                          supportive care or concomitant medication while being
                          treated with {" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {selectedReceivedTmtsStr}
                          </span>?
                        </span>
                      </div>
  <div className="double-dig-answer">
    {medicationTypes.map((medType) => (
      <div key={medType.medicationTypeID}>
        <label>
          <input
            type="checkbox"
            id={`medType-${medType.medicationTypeID}`}
            checked={!!selectedOptions.parent[medType.medicationTypeID]}
            onChange={() => handleParentCheckboxChange(medType.medicationTypeID)}
          />
          <span className="radio-text-padding">{medType.name}</span>
        

        {medType.medicationTypeID === 8 &&
        //   selectedOptions.parent[medType.medicationTypeID] && 
          (
            <>
              , Specify:
              <input
                type="text"
                className="input-dash"
                value={otherInputs["parent"] || ""}
                onChange={(e) =>{
                  handleOtherInputChange("parent", e.target.value)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    parent: {
                      ...prevState.parent,
                      [medType.medicationTypeID]: true,
                    },
                    
                  }));
                  setRadioSelection("");
                }
                }
                placeholder=""
                maxLength={100}
              />
            </>
          )}
          </label>

        {selectedOptions.parent[medType.medicationTypeID] &&
          medType.subMedicationTypes && (
            <div className="child-checkbox">
              {medType.subMedicationTypes.map((subMedType) => (
                <div key={subMedType.subMedicationTypeID}>
                  <label>
                    <input
                      type="checkbox"
                      id={`subMedType-${subMedType.subMedicationTypeID}`}
                      checked={
                        !!selectedOptions.sub[medType.medicationTypeID]?.[
                          subMedType.subMedicationTypeID
                        ]
                      }
                      onChange={() =>
                        handleSubCheckboxChange(
                          medType.medicationTypeID,
                          subMedType.subMedicationTypeID
                        )
                      }
                    />
                    <span className="radio-text-padding">
                      {subMedType.name}
                    </span>
                  

                  {subMedType.subMedicationTypeID === 6 &&
                    // selectedOptions.sub[medType.medicationTypeID]?.[
                    //   subMedType.subMedicationTypeID
                    // ] && 
                    (
                      <>
                        , Specify:
                        <input
                          type="text"
                          className="input-dash"
                          value={otherInputs["sub"] || ""}
                          onChange={(e) => {
                            handleOtherInputChange("sub", e.target.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              sub: {
                                ...prevState.sub,
                                [medType.medicationTypeID]: {
                                  ...prevState.sub[medType.medicationTypeID],
                                  [subMedType.subMedicationTypeID]: true,
                                },
                              },
                            }));
                            setRadioSelection("");
                          }}
                          
                          placeholder=""
                          maxLength={100}
                        />
                      </>
                    )
                    }
                    </label>

                  {selectedOptions.sub[medType.medicationTypeID]?.[
                    subMedType.subMedicationTypeID
                  ] &&
                    subMedType.subSubMedicationTypes && (
                      <div className="child-checkbox">
                        {subMedType.subSubMedicationTypes.map(
                          (subSubMedType,index) => (
                            <div key={subSubMedType.subSubMedicationTypeID}>
                                
                              <label>
                                <input
                                  type="checkbox"
                                  id={`subSubMedType-${subSubMedType.subSubMedicationTypeID}`}
                                  checked={
                                    !!selectedOptions.subSub[
                                      subMedType.subMedicationTypeID
                                    ]?.[
                                      subSubMedType.subSubMedicationTypeID
                                    ]
                                  }
                                  onChange={() =>
                                    handleSubSubCheckboxChange(
                                      subMedType.subMedicationTypeID,
                                      subSubMedType.subSubMedicationTypeID,
                                      index 
                                    )
                                  }
                                />
                                <span className="radio-text-padding">
                                  {subSubMedType.name}
                                </span>
                              </label>
                            </div>

                            
                          )
                          
                        )}
                        
                        <ErrorField
                        show={hardErrList[subMedType.subMedicationTypeID]}
                        message={Epcoritamab_Msgs.subsub}
                        />
                        

                      </div>
                      
                    )}
                    

                </div>
              ))}
               
            </div>
          )}
      </div>
    ))}
<ErrorField
        show={hardErrList.epco_parent_othertext_empty}
        message={Epcoritamab_Msgs.epco_parent_othertext_empty}
    />
    <div>
      <label>
        <input
          type="radio"
          value="None"
          checked={radioSelection === "None"}
          onChange={(e) => handleRadioChange(e, "None")}
        />
        <span className="radio-text-padding">None</span>
      </label>
    </div>

    <div>
      <label>
        <input
          type="radio"
          value="Unknown"
          checked={radioSelection === "Unknown"}
          onChange={(e) => handleRadioChange(e, "Unknown")}
        />
        <span className="radio-text-padding">Unknown</span>
      </label>
    </div>
  </div>
  <ErrorField
                    show={hardErrList.selectedOptions_Sub_Empty}
                    message={Epcoritamab_Msgs.sub}
                />
  <ErrorField
        show={hardErrList.selectedOptions_Empty}
        message={Epcoritamab_Msgs.parent}
    />
    <ErrorField
                                      show={softErrList.epco_parent_unknown}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                                                            <ErrorField
                        show={hardErrList.epco_sub_othertext_empty}
                        message={Epcoritamab_Msgs.epco_sub_othertext_empty}
                        />
                                                           
                                                            
</div>

{selectedOptions.parent[6] === true && (<>

                    {/* Q75. */}
                    <div className="sub-question-bot-sapce answer-pad-left">
                      <div className="question question-weight">
                        <span>Q75.</span>
                        <span className="quest-text-pad">
                        Did the patient receive any doses of tocilizumab?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div>
                          <label>
                            <input
                              type="radio"
                              value={1}
                              name="dosesOfTocilizumabNumOfDoses"
                              id="dosesOfTocilizumabNumOfDoses_Yes"
                              onClick={HandleFieldChange}
                              checked={tpeDetails.dosesOfTocilizumabNumOfDoses ===1}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              value={99}
                              name="dosesOfTocilizumabNumOfDoses"
                              id="dosesOfTocilizumabNumOfDoses_Unk"
                              onClick={HandleFieldChange}
                              checked={tpeDetails.dosesOfTocilizumabNumOfDoses ===99}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.dosesOfTocilizumabNumOfDoses_empty}
                        message={Epcoritamab_Msgs.epco_doses_of_tocilizumab_empty}
                      />
                      <ErrorField
                        show={softErrList.dosesOfTocilizumabNumOfDoses_unk}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                    </div>

                    {/* loop 76 */}
                    {tpeDetails.dosesOfTocilizumabNumOfDoses === 1 && (
                      <div className="dependent-section">
                        {doseOfTocilizumabDetails &&
                          doseOfTocilizumabDetails.length > 0 &&
                          doseOfTocilizumabDetails.map((data, index) => (
                            <div key={index} className="loop-section-ul">
                              <div className="loop-head">
                                <span className="question-weight">
                                  {GetOrdinal(index + 1)} Dose
                                </span>
                                {index !== 0 && (
                                  <div className="delete-btn-div">
                                    <img
                                      width="16px"
                                      src="../Assets/images/Icon-material-delete.png"
                                      alt=""
                                      onClick={() =>
                                        DeleteDoseOfTocilizumabRow(index)
                                      }
                                    />
                                  </div>
                                )}
                              </div>

                              {/* Q76. */}
                              <div className="sub-question-bot-sapce answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q76.</span>
                                  <span className="quest-text-pad">
                                    What best describes the site of care where
                                    the patient received the {" "}
                                          <span className="question-weight">
                                        {GetOrdinal(index + 1)} 
                                        {" "}
                                      </span>tocilizumab?
                                  </span>
                                </div>
                                <div className="double-dig-answer">
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value={1}
                                        name={"siteOfCare_rd" + index}
                                        checked={data.siteOfCare_rd === 1}
                                        onChange={HandleRepeaterFieldChange}
                                        id={index}
                                      />
                                      <span className="radio-text-padding">
                                        Outpatient clinic
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value={2}
                                        name={"siteOfCare_rd" + index}
                                        checked={data.siteOfCare_rd === 2}
                                        onChange={HandleRepeaterFieldChange}
                                        id={index}
                                      />
                                      <span className="radio-text-padding">
                                        Hospital
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value={3}
                                        name={"siteOfCare_rd" + index}
                                        checked={data.siteOfCare_rd === 3}
                                        onChange={HandleRepeaterFieldChange}
                                        id={index}
                                      />
                                      <span className="radio-text-padding">
                                        At home
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value={4}
                                        name={"siteOfCare_rd" + index}
                                        checked={data.siteOfCare_rd === 4}
                                        onChange={HandleRepeaterFieldChange}
                                        id={index}
                                      />
                                      <span className="radio-text-padding">
                                        Other, specify:{" "}
                                        <input
                                          className="input-dash"
                                          name={"siteOfCare_other"}
                                          value={data.siteOfCare_other}
                                          onChange={HandleRepeaterFieldChange}
                                          type="text"
                                          maxLength={100}
                                          id={index}
                                        />
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value={99}
                                        name={"siteOfCare_rd" + index}
                                        checked={data.siteOfCare_rd === 99}
                                        onChange={HandleRepeaterFieldChange}
                                        id={index}
                                      />
                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <ErrorField
                                show={data.siteOfCare_other_unk_soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.siteOfCare_rd_empty}
                                message={
                                  Epcoritamab_Msgs.epco_siteofcare
                                }
                              />
                              <ErrorField
                                show={data.siteOfCare_other_empty}
                                message={
                                  Epcoritamab_Msgs.epco_siteofcare_othertext
                                }
                              />
                            </div>
                          ))}
                        {doseOfTocilizumabDetails &&
                          doseOfTocilizumabDetails.length < 10 && (
                            <div className="sub-question-bot-sapce-ul mt-2">
                              <div
                                className="answer-list-text"
                                
                              >
                                <a onClick={AddDoseOfTocilizumabRow}>
                                  <img
                                    width="18px"
                                    src="../Assets/images/plus.png"
                                    alt=""
                                  />
                                </a>
                                <a onClick={AddDoseOfTocilizumabRow} className="radio-text-padding">
                                Click here to add another dose of Tocilizumab
                                </a>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                    </>)}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TreatmentsPriorEpcoritamab;
