export const UserLoginMessage = {
  LogginFailure: "Login Failed. Incorrect Email and Password.",
  LogginSuccess: "User logged in",
  LoginEmailBlank: "Enter Email Address",
  LoginPasswordBlank: "Enter Password",
  LoginInvalidEmail: "Enter valid Email",
};
export const Abstractor_Msgs = {
  FIRSTNAME: "Enter first name",
  LASTNAME: "Enter last name",
  EMAIL_REQUIRED: "Enter email address",
  EMAIL_VALID: "Please enter a valid email",
  CENTER: "Please select center",
  REQUIRED: "This field is required",
  PASSWORD_VALID:
    "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
  EMAIL_EXISTS: "Email address already exists",
  CENTER_LOAD_ERROR: "Error occurred while loading centers",
  ADD_SUCCESS: "Abstractor added successfully",
  EDIT_SUCCESS: "Abstractor updated successfully",
  DELETE_SUCCESS: "Abstractor deleted successfully",
  DELETE_FAIL: "Error occurred when deleting abstractor",
  EMAIL_SENDOUT_SUCCESS: "Email sent successfully", //"An email has been sent to the email address regarding the further process",
  EMAIL_SENDOUT_ERROR: "Email address does not exists",
};
export const Center_Msgs = {
  NAME: "Enter center name",
  NAME_EXISTS: "The center name already exists",
  ADD_SUCCESS: "Center added successfully",
  EDIT_SUCCESS: "Center updated successfully",
  DELETE_SUCCESS: "Center deleted successfully",
  DELETE_FAIL: "Error occurred when deleting center",
};
export const ResetPasswordMessages = {
  PASSWORD_BLANK: "Enter Password",
  CONFIRM_PASSWORD_BLANK: "Re-Enter Password",
  PASSWORD_MISMATCH: "Passwords did not match!",
  PASSWORD_INVALID:
    "Your password should include at least one lowercase letter, one uppercase letter, one number and one symbol with a minimum length of eight characters.",
  LINK_EXPIRED: "Link has expired",
  EMAIL_NOTEXISTS: "Email address does not exists",
  //100:"Some error!!! "
};
export const CommonError_Msgs = {
  LOAD_ERR: "Error occurred while loading.",
  SAVE_ERR: "Error occurred while saving.",
  REQUIRED: "This field is required.",
  DATE_BEFORE_1920: "Date entered is before 1920.",
  Hard_Empty:
    "Please ensure that all mandatory fields are filled out before proceeding.",
  UnknownSelect:
    "You have selected “Unknown” for this question. Please confirm that this is your choice before moving to the next question.",
  InvalidDate: "Date does not exist",
};
export const MessageRequired_Msg = {
  Q24: "please select any one option for treatment",
  Required: "This field is required.",
};
export const ForgotPassword = {
  EmailNotExistent:
    "This Email Address has not been set up - Please contact your Administrator to set this up.",
  EmailSuccess: "An Email has been sent to you regarding the further process.",
  ErrorOccurred: "Error occurred.",
};
export const CommonMsgs = {
  DeleteConfirmMsg: "Are you sure you want to delete this data?",
  SessionTimeout: "Your session has expired, you will be logged out now.",
  SubmitSuccess: "Patient Submitted Successfully",
  Unknown:
    "You have selected “Unknown” for this question. Please confirm that this is your choice before moving to the next question.",
};
export const PatientDashboard_Msgs = {
  ExportSuccess: "Data exported successfully",
  UnlockConfirmMsg:
    "Would you like to unlock and allow the abstractors to edit this patient?",
  UnlockSuccessMsg: "Patient Unlocked successfully",
};

export const CarTCellTherapy_Msgs = {
    Q92: "Please specify whether the patient was planning to receive CAR T cell therapy at any point during the course of treatment and if they were unable to undergo the infusion.",
    Q93: "Please specify the reason why the patient was ultimately unable to receive CAR T cell therapy.",
    q93EmptyOtherTxt: "Please enter the text.",
};

export const Demographic_Msgs = {
  Q1: "Please select the patient's sex.",
  Q2: "Please select the patient's race",
  Q3: "Please select the patient's ethnicity",
  Q4: "Please specify the patient's height at, or closest to, the date of initiation of treatment with epcoritamab.",
    Q4_units: "Please specify  height unit.",
  Q5: "Please specify the patient's weight at, or closest to, the date of initiation of treatment with epcoritamab.",
    Q5_units: "Please specify weight unit.",
  Q6: "Please specify which vaccines the patient received during the 12 months prior to, and including, the date of initiation of treatment with epcoritamab.",
  Q7: "Please specify if the patient is alive.",

  Q8: "Please select the patient’s date of death.",
  q8_InvalidDate1:
    "The date of the patient's death is before the date of initiation. Please revise your response.",
  q8_InvalidDate2:
    "The date of the patient's death is after the date of abstraction. Please revise your response.",

  Q9: "Please specify the primary cause of death.",

  Q10: "Please select the date of the last clinical visit.",
  Q10_Invalid1:
    "The date of the last clinical visit should be before the date of initiation with epcoritamab. Please revise the response.",
  Q10_Invalid2:
    "The date of the last clinical visit should be before the death date. Please revise the response.",
  Q10_Invalid3:
    "The date of the last clinical visit is after the date of abstraction. Please revise the response.",
};


export const PatientScreening_Msgs = {
    EligiblePatient:
        "This patient chart meets the study eligibility criteria. Please proceed to the next section of the chart abstraction form.",
    IneligiblePatient:
        "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected.",
    INELIGIBLE: "This patient is not eligible.",
    INVALID:
        "Patient must be at least 18 years of age at the date of Epcoritamab initiation.",
    DateCriteria1:
        "The date of treatment initiation with epcoritamab is before the date of LBCL diagnosis. Please revise your response.",
    DateCriteria2:
        "The date of treatment initiation with epcoritamab is on or before 05/19/2023. Please revise your response.",

    QS1: "Please specify if patient receive treatment with epcoritamab.",
    QS2: "Please select the date of initiation of treatment with epcoritamab.",
    QS3: "Please specify if the patient has a confirmed diagnosis of large B-cell lymphoma (LBCL).",
    QS4: "Please select the subtype of LBCL with which the patient is diagnosed.",
    QS4OtherTxtEmpty: "Please enter the text.",
    QS5: "Please select the date when the patient was first diagnosed with LBCL.",
    QS6: "Please select the patient’s date of birth.",
};

export const ClinicalCharacteristics_Msgs = {
  //Hard
    q11Empty: "Please specify if the patient had any comorbid conditions in the 12 months before the date of initiation of treatment with epcoritamab.",
  q11TxtEmpty: "Please enter the text.",
    q12Empty: "Please specify the date when the patient was diagnosed with cancer.",
    q12DateValid: "Date must be between {ID minus Index} and {index date}.",
  q12TypeEmpty: "Please select the cancer type.",
  q12TypeTxtEmpty: "Please enter the text.",
    q13Empty: "Please confirm whether the patient has a confirmed diagnosis of any other malignancies at the date of initiation of treatment with epcoritamab.",
  q13TxtEmpty: "Please enter the text.",
    q14StatusEmpty: "Please specify the patient’s ECOG status.",
    q14DateEmpty: "Please enter the date of assessment.",
    q14Validation:"Date must be between {ID minus Index} and {index date}.",
    q15Empty: "Please specify whether the patient has had any ECOG assessments conducted after the date of initiation of treatment with epcoritamab.",
    
    
  

 
  
 
    q18GradeEmpty: "Please specify the patient’s histologic grade at  the date of initiation of treatment with epcoritamab.",
  q18LymClassEmpty: "Please select the patient’s lymphoma classification.",
    q19Empty: "Please specify the molecular classification of the patient’s lymphoma at the date of initiation of treatment with epcoritamab.",
    q20StageEmpty: "Please specify the patient’s Ann Arbor disease stage.",
  q20SubEmpty: "Please select an option.",
    q21StageDescrEmpty: "Please select the best description of the patient’s disease stage.",
  q21SubEmpty: "Please select an option.",
    q22Empty: "Please specify whether there was more than 1 extranodal site.",
  NodeNotSpecified: "Please specify the number of nodes.",
    q23Empty: "Please specify whether there was more than 1 nodal site.",
    q24DateEmpty: "Please provide the date of assessment.",
    q24DateValidation: "Date must be between {ID minus Index} and {index date}.",
  q24SizeEmpty: "Please specify the size of the patient’s largest lymph node.",
    q25Empty: "Please specify the patient’s International Prognostic Index (IPI) risk score.",
    q26Empty: "Please specify the patient’s CD20 status.",
    q27Empty: "Please specify any of the B symptoms the patient experienced.",
  q27TxtEmpty: "Please enter the text.",
    q29Empty: "Please specify if the patient received a COVID-19 vaccine at any point in their medical history.",
    q33Empty: "Please specify if the patient has any recorded COVID-19 diagnosis at any point in their medical history.",
  RangeLimit: "Allowed range - 0 to 100.",

  //Repeaters
  //ECOG ssessment
  //Hard
  q16StatusEmpty: "Please select the ECOG status.",
    q16DateEmpty: "Please specify the patient’s {id} ECOG score after the date of initiation of treatment with epcoritamab.",
    q16Validation: "Date must be between {ID minus Index} and {index date}.",


  //COVID 19 Vaccine
  //Hard
    covidVaxEmpty: "Please specify the date the patient received their {id} COVID-19 vaccine.",
    companyEmpty: "Please specify the company which produced the patient’s COVID-19 vaccination.",
    companyTxtEmpty: "Please enter the text.",
  //COVID-19 Diagnosis
    //Hard 
    covidDxEmpty: "Please specify the date of the patient's {id} COVID-19 diagnosis.",

  //Q28 Lab Table
  //Hard
    labCollectionDtEmpty: "Please specify the date of lab collection.",
    q28AssessEmpty: "Please specify the Lab result",
  q28UpperLimitEmpty: "Please specify the upper limit.",
  q28LowerLimitEmpty: "Please specify the lower limit.",
  q28UnitEmpty: "Please specify the unit.",
  q28RangeLimit: "Allowed range - 0.00 to 9999.99.",
};
export const Epcoritamab_Msgs = {

DaysRangeLimit:"Allowed range - 1 to 30.",

  Q36: "Please specify in which line of therapy the patient received epcoritamab.",
  Q37: "Please select whether the patient received epcoritamab in combination with any other treatment.",
  Q38: "Please specify how many days were between the patient’s epcoritamab step up dose 1 and 2.",
  Q39: "Please specify how many days were between the patient’s epcoritamab step up dose 2 and 3.",
  Q40: "Please specify if the patient discontinued epcoritamab.",
  Q41: "Please specify the date of epcoritamab discontinuation.",
  Q41_Invalid1:
    "The date of epcoritamab discontinuation is on or before the date of treatment initiation. Please revise your response.",
  Q41_Invalid2:
    "The date of epcoritamab discontinuation is on or after the date of death or date of chart abstraction. Please revise your response.",
  Q41_Invalid3:
    "The date of epcoritamab discontinuation is after the date of abstraction. Please revise your response.",
  Q42: "Please specify the reason for discontinuation of epcoritamab.",
  After_index_dt:
    "The date of {id} assessment is on or before the date of treatment initiation. Please revise your response.",
  Before_discnt_dt:
    "The date of {id} assessment is on or after the date of epcoritamab discontinuation . Please revise your response.",

  q44HardEmpty:
    "Please select the category that best describes the patient’s LBCL disease status at this radiological assessment.",
  q44lbclTxtEmpty: "Please enter the text.",
  q45StatusEmpty:
    "Please specify if the disease stage was assessed per LYRIC or Lugano criteria.",
  radiologyDxEmpty:
    "Please specify the date of the {id} radiological assessment after the patient initiated epcoritamab.",
  q46LyricEmptyHard:
    "Please specify the disease assessment per LYRIC criteria.",
  q47HardEmpty:
    "Please specify the type of radiological assessment that was used.",
  q47TxtEmpty: "Please enter the text.",

  epco_discont_reasonEmpty:"Please specify the reason for discontinuation of epcoritamab.",
  parent: "Please select if the patient received any supportive care or concomitant medication in addition to epcoritamab.",
  sub: "Please select an option",
  subsub: "Please select an option",
  epco_doses_of_tocilizumab_empty: "Please specify if patient has received any doses of tocilizumab",
  epco_parent_othertext_empty: "Please enter the text",
  epco_sub_othertext_empty: "Please enter the text",
  epco_siteofcare: "Please select the site of care where the patient received their first dose of tocilizumab",
  epco_siteofcare_othertext: "Please enter the text",
  epco_site_of_care_for_each_dose_empty: "Please select the site of care for each dose."

};
export const TreatmentPriorEpco_Msgs = {

  Date_before_Index:"Date must be before index date",
  //Hard
  //q49 
  tmt_received_empty: "Please select the treatments the patient received in LOT {id}.",
  tmt_received_oth_txt_empty: "Please enter the text.",
  //Q50
  cart_type_empty: "Please select the type of CAR T therapy the patient underwent.",
  cart_type_oth_txt_empty: "Please enter the text.",
  //Q51
  apheresis_empty: "Please specify when the patient underwent apheresis.",
  //Q52
  receive_bridging_therapy_empty:
    "Please specify if the patient received bridging therapy prior to initiating CAR T cell therapy.",
  //Q53
  bridging_therapy_empty:
    "Please specify the treatments the patient received as bridging therapies prior to initiating CAR T cell therapy.",
  bridging_therapy_oth_txt_empty: "Please enter the text.",
  //Q54
  initiate_bridging_therapy_empty:
    "Please specify when the patient initiated the bridging therapies prior to initiating CAR T cell therapy.",
  //Q55
  bridging_therapy_cycles_empty: "Please specify the number of cycles of bridging therapy the patient received.",
    RangeLimit: "Allowed range - 0 to 100.",
  //Q56
  receive_lymphodepleting_therapy_empty:
    "Please specify if the patient received lymphodepleting therapy prior to initiating CAR T cell therapy.",
  //Q57
  tmt_lympho_therapy_empty:
    "Please specify the treatments the patient received as lymphodepleting therapies prior to initiating CAR T cell therapy.",
  tmt_lympho_therapy_oth_txt_empty: "Please enter the text.",
  //Q58
  initiate_lymphodepleting_therapy_empty:
    "Please specify when the patient initiated the lymphodepleting therapies prior to initiating CAR T cell therapy.",
  //Q59
  initiate_tx_empty: "Please specify when the patient initiated {tx}.",


    initiate_before_index_date: "The date of {tx} initiation is on or after the date of initiation Please revise your response.",
  //Q60

  ipi_risk_score_empty:
    "Please specify the patient’s International Prognostic Index (IPI) risk score closest to or at {tx} initiation.",

  //Q61
  ipi_risk_score_date_empty:
    "Please specify the date of assessment for the patient’s International Prognostic Index (IPI) risk score.",
    
  //Q62
    discontinue_date_empty: " Please specify the date when the patient discontinued  {tx}.",
    discontinue_after_LOT_X_tx_in_date:"The patient's discontinuation date of {tx} is on or before the date of initiation. Please revise your response.",
  //Q63
  last_known_admin_date_empty:
    "Please specify the last known date of {tx} administration.",
    last_known_admin_after_LOT_X_tx_in_date: "The last known date of {tx} administration is on or before the date of initiation. Please revise your response.",


    //Repeater 
    //Q64 
    radio_assess_date_empty: "Please specify the date of the {id} radiological assessment after the patient initiated {tx} treatment.",
    LOT_X_tx_in_date: "The date of {id} radiological assessment is on or before the date of {tx} initiation. Please revise your response.",
    LOT_X_tx_dis_date: "The date of {id} radiological assessment is on or after the date of {tx} discontinuation . Please revise your response.",
    //Q65
    lbcl_status_empty: "Please select the appropriate category that best describes the patient’s LBCL disease status at this radiological assessment.",
    lbcl_status_oth_txt_empty: "Please enter the text.",
    //Q66
    criteria_empty: "Please specify whether the disease stage was assessed per LYRIC or Lugano criteria.",
    //Q67
    lyric_assess_empty: "Please provide the disease assessment per LYRIC criteria.",
    //Q68 
    radiology_type_empty: "Please specify the type of radiological assessment that was used.",
    radiology_type_oth_txt_empty: "Please enter the text.",

    //Q75
    radioBtn: "Please select radio button",
    //Q76
    siteOfCare_TxtEmpty:"Please enter the text.",
    siteOfCare_RdEmpty:"Please select radio button"

};

export const Treatment_After_Msgs = {

  Date_before_Index:"Date must be before index date",
  DaysRangeLimit:"Allowed range - 0 to 100.",
  Q70: "Please select line of therapy",
  Q71: "Please select treatments patient received",
  Q72: "Please specify the type of CAR T therapy the patient underwent.",
  Q73: "Please specify when the patient underwent apheresis",
  Q74: " Please specify if the patient received bridging therapy prior to initiating CAR T cell therapy.",
  Q75: "Please select all treatments the patient received as bridging therapies prior to initiating CAR T cell therapy",
  Q76: "Please specify when the patient initiated the bridging therapies prior to initiating CAR T cell therapy.",
  Q77: "Please specify the number of cycles of bridging therapy the patient received",
  Q78: "Please specify if the patient received lymphodepleting therapy prior to initiating CAR T cell therapy.",
  Q79: "Please select all treatments the patient received as lymphodepleting therapies prior to initiating CAR T cell therapy.",
  Q80: "Please specify when the patient initiated the lymphodepleting therapies prior to initiating CAR T cell therapy",
  Q81: "Please specify when the patient initiated the treatment.",
  Q81_Date:
    "The date of treatment initiation is before  the date of initiation with epcoritamab. Please revise your response.",

  Q82: "Please specify the patient's International Prognostic Index (IPI) risk score closest to or at treatment initiation",
  Q83: "Please specify the date of assessment of the patient’s International Prognostic Index (IPI) risk score",
  Q84: "Please specify when the patient discontinued Treatment.",
Q84After:"The date of treatment discontinuation is before the date of treatment initiation. Please revise your response.",

  Q85_Date:"Please specify the date of the radiological assessment after the patient initiated treatments.",
  Q85_Date_Before:"The date of radiological assessment is before the date of treatment initiation. Please revise your response.",
  Q85_Date_After:"The date of radiological assessment is after the date of treatment discontinuation. Please revise your response.",

  Q86:"Please select the LBCL disease status category for the radiological assessment.",
  Q87:"Please specify if the disease stage was assessed per LYRIC or Lugano criteria.",
  Q88:"Please specify the disease assessment per LYRIC criteria",
  Q89:"Please specify the type of radiological assessment that was used.",
    Q91: "choose if patient received additional lines of therapy",
};



export const HRU_MSGS = {
  RangeLimit: "Allowed range - 0 to 1000.",

  q94hospitalizationEmpty:
    "Please specify whether the patient had any hospitalizations related to LBCL.",
  q94ValueTxtEmpty: "Please specify the Number of hospitalizations.",

  q95ERvisitsEmpty:
    "Please specify whether the patient had any ER visits related to LBCL.",
  q95ERvisitsTxtEmpty: "Please specify Number of Emergency Room(ER) visits.",

  q96Outpatientvisits:
    "Please specify whether the patient had any outpatient visits related to LBCL.",
  q96OutpatientvisitsTxtEmpty: "Please specify Number of Outpatient visits.",

  q97LabTests:
    "Please specify whether the patient underwent renal function tests.",
  q97LabTestsTxtEmpty: "Please specify renal function tests.",

  q98liverEmpty:
    "Please specify whether the patient underwent liver function tests.",
  q98liverTxtEmpty: "Please specify Number of liver function tests.",

  //Repeater
  hospitaladmissionDateEmpty:"Please specify the hospital admission date.",
  hospitaldischargeDateEmpty:"Please specify the hospitalization discharge date.",
  hospitalreasonEmpty:"Please specify Primary reason for hospitalization.",
  hospitalreasonTxtEmpty:"Please enter the text.",
  hospital_dis_diagEmpty:"Please specify the discharge diagnosis.",
  primary_reasonEmptyHard:"Please specify primary reason for visit.",

  emergencyvisitDateEmpty:"Please specify the date of Emergency room (ER) visits.",
  ERLbcl:"The ER visit date is before the date of LBCL diagnosis. Please revise your response.",
  ervisitafterdtca:"The ER visit date is on or after the date of death or date of chart abstraction. Please revise your response.",

  outpatientvisitDateEmpty:"Please specify the date of Outpatient visits.",
  outpatientLbcl:"The Outpatient visit date is before the date of LBCL diagnosis. Please revise your response.",
  outpatientvisitafterdtca:"The Outpatient visit date is on or after the date of death or date of chart abstraction. Please revise your response.",

  healthcareEmptyHard:"please specify type of healthcare professional visited.",
  visitreasonEmptyHard:"please specify primary reason for visit.",


renalEmpty:"Please specify the date of the Renal functional test undergone by patient.",
OnAfterRenal_index_dt:"The Renal function test date is before the date of LBCL diagnosis. Please revise your response.",

BeforeRenal_death_dt:"The Renal function test date is on or after the date of death or date of chart abstraction. Please revise your response.",

LiverEmpty:"Please specify the date of the Liver functional test undergone by patient.",
OnAfter_index_dt: "The Liver function test date is before the date of LBCL diagnosis. Please revise your response.",
Before_death_dt: "The Liver function test date is on or after the date of death or date of chart abstraction. Please revise your response.",
AfterAdmission:"The hospital discharge date is on or before the hospital admission date. Please revise your response.",
condition_2_dis:"The hospital discharge date is on or after the date of death or date of chart abstraction. Please revise your response.",


Hosp_OnAfter_index_dt:"The hospital admission date is before the date of LBCL diagnosis. Please revise your response.",
Hosp_con2:"The hospital admission date is on or after the date of death or date of chart abstraction. Please revise your response."
};
