import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import {
  PageDesignConstants,
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
  DefaultDate,
  SupportiveCareEpcoritamab
} from "../../../Helpers/Enums";
import SideBarMenu from "../../../Components/SideBarMenu";
import { Dropdown } from "react-bootstrap";
import ErrorField from "../../../Components/ErrorField";
//import { CommonError_Msgs, demographicScreen_Msgs } from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  AllowOnlyIntegers,
  GetDMUID,
  GetDisplayDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  GetOrdinalInWord,
  IsAfterIndex,
  IsDateBefore,
  IsDateEmpty,
  IsDateValid,
  Is_DateBefore,
  ParseDate,
  SetDateState,
  ValueInRange,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import DateControl from "../../../Components/DateControl";
import {
  CommonError_Msgs,
  CommonMsgs,
  Epcoritamab_Msgs,
} from "../../../Helpers/HelperText";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";

const Epcoritamab = () => {
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  // const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  // const surveyDate = patientData?.surveyDate ?? "";
  const userId = GetLoggedInUserID() ?? 0;
  const pid = GetLocalStorageData("patientId") ?? 0;
  const patientId = GetLocalStorageData("patientId");

  const [index_date, setIndexDate] = useState("");
  const [chartAbstractionDate, setChartAbstractionDate] = useState("");
  const [death_Date, set_Death_Date] = useState("");

  // State to manage visibility of Q46 based on LYRIC or Lugano selection
  const [showQ46, setShowQ46] = useState([]);
  const {
    ShowToast,
    ToggleLoader,
    ShowActionPopUp,
    HandleSessionTimeout,
    appState,
  } = useContext(AppContext);

      //New Chanegs Q50.
      const [medicationTypes, setMedicationTypes] = useState([]);
      const [otherInput, setOtherInput] = useState("");
      const [otherInputs, setOtherInputs] = useState({});
      const [subOtherInputs, setSubOtherInputs] = useState("");
      const [radioSelection, setRadioSelection] = useState("");
      const [selectedOptions, setSelectedOptions] = useState({
          parent: {},
          sub: {},
          subSub: {},
        });
  
    
  
  
        const initialSelections = (patientTreatment, treatmentMedicationBridges) => {
         
      
          if (
            patientTreatment.medicationNone === null &&
            patientTreatment.medicationUnk === null
          ) {
            const newSelectedOptions = { parent: {}, sub: {}, subSub: {} };
            const newOtherInputs = { parent: "", sub: "" };
      
            treatmentMedicationBridges.forEach((bridge) => {
              newSelectedOptions.parent[bridge.medicationTypeId] = true;
      
              if (bridge.medicationTypeId === 8 && bridge.medicationTypeOth) {
                newOtherInputs.parent = bridge.medicationTypeOth;
                setOtherInput(bridge.medicationTypeOth);
                // setSubOtherInput(value);
              }
      
              if (bridge.subMedicationTypeId) {
                if (!newSelectedOptions.sub[bridge.medicationTypeId]) {
                  newSelectedOptions.sub[bridge.medicationTypeId] = {};
                }
                newSelectedOptions.sub[bridge.medicationTypeId][
                  bridge.subMedicationTypeId
                ] = true;
      
                if (bridge.subMedicationTypeId === 6 && bridge.subMedicationTypeOth) {
                  newOtherInputs.sub = bridge.subMedicationTypeOth;
                  setSubOtherInputs(bridge.subMedicationTypeOth);
                }
              }
      
              if (bridge.subSubMedicationTypeId) {
                if (!newSelectedOptions.subSub[bridge.subMedicationTypeId]) {
                  newSelectedOptions.subSub[bridge.subMedicationTypeId] = {};
                }
                newSelectedOptions.subSub[bridge.subMedicationTypeId][
                  bridge.subSubMedicationTypeId
                ] = true;
              }
            });
           
            setSelectedOptions(newSelectedOptions);
            setOtherInputs(newOtherInputs);
          } else {
            if (patientTreatment.medicationNone) {
              setRadioSelection("None");
            } else {
              setRadioSelection("Unknown");
            }
          }
        };

  // State to manage visibility of Q47 based on LYRIC or Lugano selection
  // const [showQ47, setShowQ47] = useState([]);

  const navigatePaths = {
    prevPage: "/Demographics",
    currPage: "/Epcoritamab",
    nextPage:
      appState.tmtPriorEpcoLOTCnt ?? 0 > 0
        ? "/TreatmentsPriorEpcoritamab/1"
        : "/CARTCellTherapy",
  };

  const InitialSoftErrList = {
    q38Unk_err: false,
    q39Unk_err: false,
    q40Unk_err: false,
    q41Unk_err: false,
    q42Unk_err: false,
    epco_discont_unk_err: false,
    dosesOfTocilizumabNumOfDosesUnk_err: false,
    epco_parent_unknown: false,
    epco_doses_of_tocilizumab_unknown: false,

  };

  const InitialHardErrList = {
    q36Empty: false,
    q37Empty: false,
    q37EmptyagentTxt: false,
    q37EmptyOtherTxt: false,

    q38Empty: false,
    q39Empty: false,
    q38SizeOutOfRange: false,
    q39SizeOutOfRange: false,

    q40Empty: false,
    q41Empty: false,
    q42Empty: false,
    q42txtEmpty: false,
    q41_date_afterIndex: false,
    q41_date_beforeDeathorChart: false,
    SiteOfCareEmpty: false,
    //43
    epco_discont_reasonEmpty: false,
    epco_discont_reasonEmpty_txtEmpty: false,

    //Q50. checkbox hard error validation
    selectedOptions_Empty : false,
    selectedOptions_Sub_Empty : false,
    // selectedOptions_SubSub_Empty: false,

    [SupportiveCareEpcoritamab.Dexamethasone] : false,
    [SupportiveCareEpcoritamab.Hydrocortisone] : false,
    [SupportiveCareEpcoritamab.Prednisone] : false,
    [SupportiveCareEpcoritamab.Prednisolone] : false,
    [SupportiveCareEpcoritamab.Methylprednisolone] : false,
    
    [SupportiveCareEpcoritamab.Other] : false,
    
    epco_parent_othertext_empty: false,
    epco_sub_othertext_empty: false,


    //Q51. hard error message
    dosesOfTocilizumabNumOfDoses_Empty: false,
  };

  const EPCORAssesmentRow = {
    epcoritamabradiologicalassessmentid: 0, // pk of child table

    //Question 43
    radiological_assess_dx: null,
    radiological_assess_dx_date: DefaultDate,

    q43afterindexEmptyHard: false,
    q43beforeQ41dateEmptyHard: false,

    radiologyDxDateUnkSoft: false,
    radiologyDxEmptyHard: false,

    q44_LBCL_status: null,
    q44_lbcl_oth_txt: "",

    q44StatusUnkSoft: false,
    q44StatusEmptyHard: false,
    q44lbclTxtEmptyHard: false,

    //question 45
    q45_stage_status: null,
    q45statusEmptyHard: false,

    //question 46
    q46LyricAssess: null,
    q46LyricEmptyHard: false,
    q46UnkSoft: false,

    //question 47
    q47_radiology_type: null,
    q47RadioAssessTypeOtherTxt: "",
    q47radiologyTypeUnkSoft: false,
    q47radiologyTypeEmptyHard: false,
    q47radiologyTypeTxtEmptyHard: false,

    //SiteOfCare
    siteOfCare_rd: null,
    siteOfCare_other: "",
  };
  const [epcorAssessmentsDetails, setEPCORAssessmentsDetails] = useState([
    EPCORAssesmentRow,
  ]);

  //Q43-Q48 EPCOR Assessment Repeater
  const DeleteEPCORAssessmentRow = (id) => {
    const newArray = [...epcorAssessmentsDetails];
    newArray.splice(id, 1);
    setEPCORAssessmentsDetails(newArray);
  };

  const AddEPCORAssessmentRow = () => {
    setEPCORAssessmentsDetails((prev) => [
      ...prev,
      {
        ...EPCORAssesmentRow,
      },
    ]);
  };

  //Repeater
  const HandleRepeaterDateChange = (name, date, datePart) => {

    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    switch (name) {
      case "radiological_dx_date-" + id:
        {
          let updatedArray = [...epcorAssessmentsDetails];
          const idx = name.slice(lastIndex + 1);
          updatedArray[id].radiological_assess_dx_date = SetDateState(
            updatedArray[id].radiological_assess_dx_date,
            date,
            datePart
          );
          updatedArray[id].radiological_assess_dx = 1;

          updatedArray[id].radiologyDxEmptyHard = false;
          updatedArray[id].radiologyDxDateUnkSoft = false;

          updatedArray[id].q43afterindexEmptyHard = false;
          updatedArray[id].q43beforeQ41dateEmptyHard = false;
          setEPCORAssessmentsDetails(updatedArray);
        }
        break;
      default:
        break;
    }
  };


  const DoseOfTocilizumabRow  = {
    epcoritamabtreatmentsitecarebridgeid:0,
    siteOfCare_rd: null,
    siteOfCare_other: "",
    siteOfCare_rd_empty: false,
    siteOfCare_other_empty: false,
    siteOfCare_other_unk_soft: false,

  }


  const DeleteDoseOfTocilizumabRow = (id) => {
    const newArray = [...doseOfTocilizumabDetails];
    newArray.splice(id, 1)
    setDoseOfTocilizumabDetails(newArray);
  }



  const AddDoseOfTocilizumabRow = () => {
   
    setDoseOfTocilizumabDetails((prev) => [
        ...prev,
        {
            ...DoseOfTocilizumabRow
        },
    ]);
  }

  const [doseOfTocilizumabDetails,setDoseOfTocilizumabDetails] = useState([DoseOfTocilizumabRow]);









  const HandleRepeaterFieldChange = (e) => {
    debugger
    const { name, id, value } = e.target;
    const value_int = Number(value);

    switch (name) {
      //43
      case "radiological_assess_dx" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].radiological_assess_dx = value_int;
        updatedArray[id].radiological_assess_dx_date = DefaultDate;

        updatedArray[id].radiologyDxDateUnkSoft = value_int === 99;
        updatedArray[id].radiologyDxEmptyHard = false;

        updatedArray[id].q43afterindexEmptyHard = false;
        updatedArray[id].q43beforeQ41dateEmptyHard = false;

        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      //44
      case "q44_LBCL_status" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q44_LBCL_status = value_int;
        updatedArray[id].q44_lbcl_oth_txt = "";

        updatedArray[id].q44StatusUnkSoft = value_int === 99;
        updatedArray[id].q44StatusEmptyHard = false;
        updatedArray[id].q44lbclTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      case "q44_lbcl_oth_txt": {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q44_LBCL_status = 5;
        updatedArray[id].q44_lbcl_oth_txt = value;

        updatedArray[id].q44StatusUnkSoft = value_int === 99;
        updatedArray[id].q44StatusEmptyHard = false;
        updatedArray[id].q44lbclTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }
      //45
      case "q45_stage_status" + id: {
        let updatedArray = [...epcorAssessmentsDetails];

        // Reset dependent fields when q45_stage_status changes
        if (value_int === 1 || value_int === 3) {
          updatedArray[id].q46LyricAssess = null;
          // updatedArray[id].q47_radiology_type = null;
          // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

          setShowQ46((prev) => ({ ...prev, [id]: true }));
          // setShowQ47((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 4 || value_int === 99) {
          updatedArray[id].q46LyricAssess = null;
          // updatedArray[id].q47_radiology_type = null;
          // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

          setShowQ46((prev) => ({ ...prev, [id]: false }));
          // setShowQ47((prev) => ({ ...prev, [id]: true }));
        }

        updatedArray[id].q45_stage_status = value_int;
        updatedArray[id].q46UnkSoft = value_int === 99;
        updatedArray[id].q45statusEmptyHard = false;
        updatedArray[id].q46LyricEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);

        // Determine visibility for Q46 and Q47 based on q45_stage_status value
        if (value_int === 1 || value_int === 3) {
          setShowQ46((prev) => ({ ...prev, [id]: true }));
          //  setShowQ47((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 4) {
          setShowQ46((prev) => ({ ...prev, [id]: false }));
          //  setShowQ47((prev) => ({ ...prev, [id]: true }));
        }

        break;
      }

      //46
      case "q46LyricAssess" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q46LyricAssess = value_int;
      

        updatedArray[id].q46LyricEmptyHard = false;

        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      //47
      case "q47_radiology_type" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q47_radiology_type = value_int;
        updatedArray[id].q47RadioAssessTypeOtherTxt = "";

        updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
        updatedArray[id].q47radiologyTypeEmptyHard = false;
        updatedArray[id].q47radiologyTypeTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      case "q47RadioAssessTypeOtherTxt": {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q47_radiology_type = 5;
        updatedArray[id].q47RadioAssessTypeOtherTxt = value;

        updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
        updatedArray[id].q47radiologyTypeEmptyHard = false;
        updatedArray[id].q47radiologyTypeTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }


//52 repeater


case "siteOfCare_rd" + id: {
    let updatedArray = [...doseOfTocilizumabDetails];

    updatedArray[id].siteOfCare_rd = Number(value);

    updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;
    updatedArray[id].siteOfCare_other ="";

    updatedArray[id].siteOfCare_rd_empty = false;
    updatedArray[id].siteOfCare_other_empty = false;
    setDoseOfTocilizumabDetails(updatedArray);
    break;
  }


  case "siteOfCare_other" : {
    let updatedArray = [...doseOfTocilizumabDetails];
    updatedArray[id].siteOfCare_rd = 4;
    updatedArray[id].siteOfCare_other = value;


    updatedArray[id].siteOfCare_other_unk_soft = false;
    updatedArray[id].siteOfCare_rd_empty = false;
    updatedArray[id].siteOfCare_other_empty = false;
    setDoseOfTocilizumabDetails(updatedArray);
    break;
  }






      default:
        break;
    }
  };

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const [epcoritamabDetails, setepcoritamabDetails] = useState({
    selectedLOT: null,
    q36_state: null,
    q37Mono: null,
    q37Lena: false,
    q37Lonca: false,
    q37Ritux: false,
    q37Vene: false,
    q37InvestAgent: false,
    q37InvestAgent_txt: "",
    q37Other: false,
    q37Other_txt: "",
    q38step_up: null,
    q38_days_txt: "",
    q38Unk: null,
    q39step_up: null,
    q39_days_txt: "",
    q40Discont: null,
    q41EpDiscontDtUnk: null,
    dt_discont: DefaultDate,
    q42DiscontReason: null,

    //40
    siteOfCare_Dose1: null,
    siteOfCare_Dose2: null,
    siteOfCare_FullDose: null,

    //43

    epcoDiscontLbcl: false,
    epcoDiscontEligibility: false,
    epcoDiscontTransplant: false,
    epcoDiscontCompleteResponse: false,
    epcoDiscontInadequateResponse: false,
    epcoDiscontCovid19Infection: false,
    epcoDiscontCartTherapy: false,
    epcoDiscontPatientChoiceDecision: false,
    epcoDiscontOther: false,
    epcoDiscontUnknown: false,
    epcoDiscontReasonOtherTxt: "",

    //51
    dosesOfTocilizumabNumOfDoses: null,
  });

  const ValidateForm = () => {

    let isValid = true;
    //q36

    if (epcoritamabDetails.q36_state === null) {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: true,
      }));
      isValid = false;
    } else if (
      epcoritamabDetails.q36_state === 1 &&
      epcoritamabDetails.selectedLOT === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: false,
      }));
    }

    // Validation for q37
    const {
      q37Lena,
      q37Lonca,
      q37Vene,
      q37Ritux,
      q37InvestAgent,
      q37Other,
      q37InvestAgent_txt,
      q37Other_txt,
      q37Mono,
    } = epcoritamabDetails;

    if (
      !q37Lena &&
      !q37Lonca &&
      !q37Vene &&
      !q37Ritux &&
      !q37InvestAgent &&
      !q37Other &&
      q37Mono !== 1
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q37Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q37Empty: false,
      }));
    }

    if (q37Other && q37Other_txt.trim() === "") {
      setHardErrList((prev) => ({
        ...prev,
        q37EmptyOtherTxt: true,
      }));
      isValid = false;
    }
    if (q37InvestAgent && q37InvestAgent_txt.trim() === "") {
      setHardErrList((prev) => ({
        ...prev,
        q37EmptyagentTxt: true,
      }));
      isValid = false;
    }

    //Question 38 validation
    if (epcoritamabDetails.q38step_up === null) {
      setHardErrList((prev) => ({
        ...prev,
        q38Empty: true,
      }));
      isValid = false;
    } else if (epcoritamabDetails.q38step_up === 1) {
      if (epcoritamabDetails.q38_days_txt === "") {
        setHardErrList((prev) => ({
          ...prev,
          q38Empty: true,
        }));
        isValid = false;
      } else if (
        !ValueInRange(parseInt(epcoritamabDetails.q38_days_txt), 1, 30)
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q38SizeOutOfRange: true,
        }));
        isValid = false;
      }
    }

    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q38Empty: false,
    //   }));
    // }

    // question 39

    if (epcoritamabDetails.q39step_up === null) {
      setHardErrList((prev) => ({
        ...prev,
        q39Empty: true,
      }));
      isValid = false;
    }
    // else if (
    //   epcoritamabDetails.q39step_up === 1 &&
    //   epcoritamabDetails.q39_days_txt === ""
    // ) {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q39Empty: true,
    //   }));
    //   isValid = false;
    // }

    if (epcoritamabDetails.q39step_up === 1) {
      if (epcoritamabDetails.q39_days_txt === "") {
        setHardErrList((prev) => ({
          ...prev,
          q39Empty: true,
        }));
        isValid = false;
      } else if (
        !ValueInRange(parseInt(epcoritamabDetails.q39_days_txt), 1, 30)
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q39SizeOutOfRange: true,
        }));
        isValid = false;
      }
    }
    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q39Empty: false,
    //   }));
    // }

    //q40 SiteOfCare Validation

    if (
      epcoritamabDetails.siteOfCare_Dose1 === null ||
      epcoritamabDetails.siteOfCare_Dose2 === null ||
      epcoritamabDetails.siteOfCare_FullDose === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        SiteOfCareEmpty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        SiteOfCareEmpty: false,
      }));
    }

    //question 40 validate

    if (epcoritamabDetails.q40Discont === null) {
      setHardErrList((prev) => ({
        ...prev,
        q40Empty: true,
      }));
      isValid = false;
    } else if (
      epcoritamabDetails.q40Discont === 0 ||
      epcoritamabDetails.q40Discont === 99
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: false,
        q42Empty: false,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q40Empty: false,
      }));
    }

    //validation for 41

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.q41EpDiscontDtUnk === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: true,
      }));
      isValid = false;
    }

    if (epcoritamabDetails.q40Discont === 1 && epcoritamabDetails.dt_discont) {
      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        IsDateValid(epcoritamabDetails.dt_discont) &&
        (!IsDateEmpty(chartAbstractionDate) || !IsDateEmpty(death_Date)) &&
        !IsDateBefore(
          epcoritamabDetails.dt_discont,
          chartAbstractionDate,
          death_Date
        )
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_beforeDeathorChart: true,
        }));
        isValid = false;
      }
    } else if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.dt_discont === DefaultDate
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: true,
      }));
      isValid = false;
    }

    if (epcoritamabDetails.q40Discont === 1 && epcoritamabDetails.dt_discont) {
      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        IsDateValid(epcoritamabDetails.dt_discont) &&
        !IsDateEmpty(index_date) &&
        !IsAfterIndex(epcoritamabDetails.dt_discont, index_date)
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_afterIndex: true,
        }));
        isValid = false;
      }
    }

    // else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q41Empty: false,
    //     }));
    // }

    //question 42 //q42DiscontReasonOtherTxt
    // if (
    //     epcoritamabDetails.q40Discont === 1 &&
    //     epcoritamabDetails.q42DiscontReason === null
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q42Empty: true,
    //     }));
    //     isValid = false;
    // } else if (
    //     epcoritamabDetails.q40Discont === 1 &&
    //     epcoritamabDetails.q42DiscontReason === 10 &&
    //     epcoritamabDetails.q42DiscontReasonOtherTxt === ""
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    // q42txtEmpty: true,
    //     }));
    //     isValid = false;
    // } else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    // q42txtEmpty: false,
    //     }));
    // }

    const {
      epcoDiscontLbcl,
      epcoDiscontEligibility,
      epcoDiscontTransplant,
      epcoDiscontCompleteResponse,
      epcoDiscontInadequateResponse,
      epcoDiscontCovid19Infection,
      epcoDiscontCartTherapy,
      epcoDiscontPatientChoiceDecision,
      epcoDiscontOther,
      epcoDiscontUnknown,
      epcoDiscontReasonOtherTxt,
    } = epcoritamabDetails;

    if (epcoritamabDetails.q40Discont === 1) {
      if (
        !epcoDiscontLbcl &&
        !epcoDiscontEligibility &&
        !epcoDiscontTransplant &&
        !epcoDiscontCompleteResponse &&
        !epcoDiscontInadequateResponse &&
        !epcoDiscontCovid19Infection &&
        !epcoDiscontCartTherapy &&
        !epcoDiscontPatientChoiceDecision &&
        !epcoDiscontUnknown &&
        !epcoDiscontOther
      ) {
        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
        }));
      }
    }

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoDiscontOther &&
      epcoDiscontReasonOtherTxt.trim() === ""
    ) {
      setHardErrList((prev) => ({
        ...prev,
        epco_discont_reasonEmpty_txtEmpty: true,
      }));
      isValid = false;
    }

    // if (epcoritamabDetails.dosesOfTocilizumabNumOfDoses === null) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //       //  q36Empty: true,
    //     }));
    //     isValid = false;
    // } else if (
    //     epcoritamabDetails.q36_state === 1 &&
    //     epcoritamabDetails.selectedLOT === null
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q36Empty: true,
    //     }));
    //     isValid = false;
    // } else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q36Empty: false,
    //     }));
    // }

    //repeater
    if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
      const updatedArray = epcorAssessmentsDetails.map((item) => {
        let radiologyDxEmptyHard = false;
        if (
          item.radiological_assess_dx == null ||
          (item.radiological_assess_dx === 1 &&
            IsDateEmpty(item.radiological_assess_dx_date))
        ) {
          radiologyDxEmptyHard = true;
        }

        let q43afterindexEmptyHard = false;
        if (
          IsDateValid(item.radiological_assess_dx_date) &&
          IsDateValid(index_date)
        ) {
          q43afterindexEmptyHard =
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateEmpty(index_date) &&
            !IsAfterIndex(item.radiological_assess_dx_date, index_date);
        }

        let q43beforeQ41dateEmptyHard = false;
        if (
          IsDateValid(item.radiological_assess_dx_date) &&
          IsDateValid(epcoritamabDetails.dt_discont)
        ) {
          q43beforeQ41dateEmptyHard =
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateEmpty(epcoritamabDetails.dt_discont) &&
            !Is_DateBefore(
              item.radiological_assess_dx_date,
              epcoritamabDetails.dt_discont
            );
        }
        const q44StatusEmptyHard = item.q44_LBCL_status == null;
        const q44lbclTxtEmptyHard = q44StatusEmptyHard
          ? false
          : item.q44_LBCL_status === 5 && isStringEmpty(item.q44_lbcl_oth_txt);

        const q45statusEmptyHard = item.q45_stage_status == null;

        const q46LyricEmptyHard =
          (item.q45_stage_status === 1 || item.q45_stage_status === 3) &&
          item.q46LyricAssess === null
            ? true
            : false;

        const q47radiologyTypeEmptyHard =
          // (item.q45_stage_status === 2 || item.q45_stage_status === 4) &&
          item.q47_radiology_type == null ? true : false;
        const q47radiologyTypeTxtEmptyHard = q47radiologyTypeEmptyHard
          ? false
          : item.q47_radiology_type === 5 &&
            isStringEmpty(item.q47RadioAssessTypeOtherTxt);

        if (
          radiologyDxEmptyHard ||
          (item.radiological_assess_dx == 1 &&
            !IsDateValid(item.radiological_assess_dx_date)) ||
          q44StatusEmptyHard ||
          q44lbclTxtEmptyHard ||
          q45statusEmptyHard ||
          q46LyricEmptyHard ||
          q47radiologyTypeEmptyHard ||
          q47radiologyTypeTxtEmptyHard ||
          q43afterindexEmptyHard ||
          q43beforeQ41dateEmptyHard
        ) {
          isValid = false;
        }

        return {
          ...item,
          radiologyDxDateUnkSoft: false,
          radiologyDxEmptyHard,

          q44StatusUnkSoft: false,
          q44StatusEmptyHard,
          q44lbclTxtEmptyHard,
          q45statusEmptyHard,

          q46LyricEmptyHard,
          q46UnkSoft:false,

          q47radiologyTypeUnkSoft: false,
          q47radiologyTypeEmptyHard,
          q47radiologyTypeTxtEmptyHard,

          q43afterindexEmptyHard,
          q43beforeQ41dateEmptyHard,
        };
      });
      setEPCORAssessmentsDetails(updatedArray);
    }

//repeater

if(selectedOptions.parent[6] === true && epcoritamabDetails.dosesOfTocilizumabNumOfDoses === null){
    setHardErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_Empty: true,
      }));
      isValid = false;
}
if(selectedOptions.parent[6] === true && epcoritamabDetails.dosesOfTocilizumabNumOfDoses === 1){
    if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
        const updatedArray = doseOfTocilizumabDetails.map((item) => {
          let siteOfCare_rd_empty = false;
          if ( //if tocilizumab selected and
            item.siteOfCare_rd == null 
          ) {
            siteOfCare_rd_empty = true;
          }
  
          let siteOfCare_other_empty = false;
          if (  item.siteOfCare_rd === 4 &&
          item.siteOfCare_other === ""
          ) {
            siteOfCare_other_empty = true;
          }
  
          
          if (
            siteOfCare_rd_empty ||
            siteOfCare_other_empty
          ) {
            isValid = false;
          }
  
          return {
            ...item,
            siteOfCare_other_unk_soft:false,
            siteOfCare_rd_empty,
            siteOfCare_other_empty,
          };
        });
        setDoseOfTocilizumabDetails(updatedArray);
      }
    }

    
        //Q50. checkbox validations

       
    

const isParentEmpty = Object.keys(selectedOptions.parent).length === 0;
const allFalse = Object.values(selectedOptions.parent).every(value => value === false);
const isSubEmpty = selectedOptions.sub[7] && Object.keys(selectedOptions.sub[7]).length === 0;
const hasUncheckedSubForSeven = selectedOptions.parent[7] && selectedOptions.sub[7] && Object.values(selectedOptions.sub[7]).every(subChecked => !subChecked);
//const subsubinnerDexa = selectedOptions.subSub[1].length ===0;

const subsubDexaEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone])?.length ===0;
const subsubDexaFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone]).every(value => value === false);

const subsubHydroEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone])?.length ===0;
const Hydrocortisone = selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone];      
const subsubHydroFalse = Hydrocortisone && Object.keys(Hydrocortisone)?.length>0 &&  Object.values(Hydrocortisone).every(value => value === false);

const subsubPrednisoneEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])?.length ===0;
const subsubPrednisoneFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone]).every(value => value === false);

const subsubPrednisoloneEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone])?.length ===0;
const subsubPrednisoloneFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] &&  Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone]).every(value => value === false);

const subsubMethylEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone])?.length ===0;
const subsubMethylFalse = selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone])?.length>0 &&  Object.values(selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone]).every(value => value === false);

const subsubOtherEmpty = selectedOptions.subSub[SupportiveCareEpcoritamab.Other] && Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Other])?.length ===0;

const subOtherTextIsEmpty = selectedOptions.sub && selectedOptions.sub[7] && selectedOptions.sub[7][6];


// Check if any array in subSub contains both false values
// const hasUncheckedSubSubForSeven = selectedOptions.sub[7] && selectedOptions.subSub &&
//   Object.keys(selectedOptions.sub[7]).some(subId => {
//     const subChecked = selectedOptions.sub[7][subId];
//     const subSubArray = selectedOptions.subSub[subId];
//     if (subChecked && subSubArray) {
//       return subSubArray.some(subSubArr => subSubArr.length === 2 && subSubArr.every(subSubChecked => !subSubChecked));
//     }
//     return false;
//   });

// Check if any array in subSub contains both false values
// const hasUncheckedSubSub = Object.keys(selectedOptions.subSub).some(subId => {
//     const subSubArray = Object.values(selectedOptions.subSub[subId]);
//     // Check if any array within subSubArray contains both false values
//     return subSubArray.some(subSubArr => subSubArr.length === 2 && subSubArr.every(subSubChecked => !subSubChecked));
//   });


// const allFalseSubSubItems = [];

// Object.keys(selectedOptions.subSub).forEach(subId => {
//   const innerObject = selectedOptions.subSub[subId];

//   const subsubFalse = Object.values(innerObject).every(value => value === false);
//   const subsubEmpty = Object.keys(innerObject).length === 0;

  

// //   if(subsubFalse || subsubEmpty){
// //     setInvalidSubSub({...invalidSubSub, [subId]:true})


// //   }

// //   if (typeof innerObject === 'object' && innerObject !== null) {
// //     const allFalse = Object.values(innerObject).every(value => value === false);
// //     if (allFalse) {
// //       allFalseSubSubItems.push(subId);
// //     }
// //   }
// });



  



  // Determine if there are invalid sub-sub options
// const hasInvalidSubSub = Object.keys(invalidSubSub).length > 0;

if (isParentEmpty && radioSelection === "") {
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Empty: true,
  }));
  isValid = false;
} 


if(selectedOptions.parent[8] && (otherInputs.parent === ""))
    {
        setHardErrList((prev) => ({
            ...prev,
            epco_parent_othertext_empty: true
          }));
          isValid = false;
       
    }

// else 
// if (subsubinner ) {
//     setHardErrList((prev) => ({
//       ...prev,
//       selectedOptions_SubSub_Empty: true,
//     }));
//   }









 if (allFalse && radioSelection === "") {
    isValid = false;
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Empty: true,
  }));
} 

if (!allFalse && !isParentEmpty && selectedOptions.parent[7] 
    // &&
    // Object.keys(selectedOptions.sub[7]).length === 0 && 
    // Object.values(selectedOptions.sub[7]).some(value => value === true)
) {
 if (isSubEmpty || hasUncheckedSubForSeven 
    // || hasUncheckedSubSubForSeven
) {
    isValid = false;
  setHardErrList((prev) => ({
    ...prev,
    selectedOptions_Sub_Empty: true,
  }));
} 

  }
// else if (hasInvalidSubSub) {
//     setHardErrList(prev => ({
//       ...prev,
//       selectedOptions_SubSub_Empty: true,
//     }));
//   }
//  if(subsubDexaEmpty || subsubDexaFalse){
//     setHardErrList((prev) => ({
//         ...prev,
//         selectedOptions_SubSub_EmptyDexamethasone: true,
//     }));

// }

if(subsubDexaEmpty || subsubDexaFalse)
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Dexamethasone]: true,
        }));
    }

if(subsubHydroEmpty || subsubHydroFalse)
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Hydrocortisone]: true,
        }));
    }
if(subsubPrednisoneEmpty || subsubPrednisoneFalse)
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Prednisone]: true,
        }));
    }

if(subsubPrednisoloneEmpty || subsubPrednisoloneFalse)
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Prednisolone]: true,
        }));
    }

if(subsubMethylEmpty || subsubMethylFalse)
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            [SupportiveCareEpcoritamab.Methylprednisolone]: true,
        }));
    }

if((subOtherTextIsEmpty && otherInputs.sub === ""))
    {
        isValid = false;
        setHardErrList((prev) => ({
            ...prev,
            // [SupportiveCareEpcoritamab.Other]: true,
            epco_sub_othertext_empty: true,

        }));
    }


    // if(subsubDexaFalse)
    //     {
    //         setHardErrList((prev) => ({
    //             ...prev,
    //             selectedOptions_SubSub_EmptyDexamethasone: true,
    //         }));
    //     }

// else {
//   setHardErrList((prev) => ({
//     ...prev,
//     selectedOptions_Empty: false,
//     selectedOptions_Sub_Empty: false,
//     selectedOptions_SubSub_Empty: false,


//   }));
// }

        

        

           
        
        // if (selectedOptions == null && selectedOptions.length == 0  && radioSelection == ""){
        
        //     const updatedArray = selectedOptions.map((item) => {
                
        //     });
           
        // }

        // let errorMessage = '';
        // const parentChecked = Object.values(selectedOptions.parent).some(
        //     (checked) => checked
        //   );
        
        //   if (!parentChecked) {
        //     isValid = false;
        //     errorMessage = 'Please select at least one parent checkbox.';
        //   }
        
        //   if (!isValid) {
        //     alert(errorMessage);
        //   }

        return isValid;
  };

  const HandleFieldChange = (e) => {
    const { name, id, value, checked } = e.target;
   
    switch (name) {
      case "q36_state": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          // q36_other_txt:""
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          // q37EmptyagentTxt: false,
          // q37EmptyOtherTxt:false,
        }));
        break;
      }

      case "selectedLOT": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q36_state: 1,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q36Empty: false,
        }));
        break;
      }
      // case "q36_other_txt": {
      //   setepcoritamabDetails((prev) => ({
      //     ...prev,
      //     [name]: value,
      //     q36_state: 2,
      //   }));

      //   break;
      // }

      case "q37Mono": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q37Lena: false,
          q37Lonca: false,
          q37Vene: false,
          q37Ritux: false,
          q37InvestAgent: false,
          q37Other: false,
          q37InvestAgent_txt: "",
          q37Other_txt: "",
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;
      }

      case "q37Lena":
      case "q37Lonca":
      case "q37Vene":
      case "q37Ritux":
      case "q37InvestAgent":
      case "q37Other":
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "q37Other" && { q37Other_txt: "" }),
          ...(name === "q37InvestAgent" && { q37InvestAgent_txt: "" }),
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;

      case "q37InvestAgent_txt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q37InvestAgent: true,
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;
      }

      case "q37Other_txt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q37Other: true,
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyOtherTxt: false,
          q37EmptyagentTxt: false,
        }));

        break;
      }

      case "q38step_up": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q38_days_txt: "",
        }));

        // if(Number(value) === 99){
        // }

        setSoftErrList((prev) => ({
          ...prev,
          q38Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q38Empty: false,
          q38SizeOutOfRange: false,
        }));

        break;
      }

      case "q38_days_txt": {
       
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q38step_up: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q38Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q38Empty: false,
          q38SizeOutOfRange: false,
        }));

        break;
      }

      case "q39step_up": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q39_days_txt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q39Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q39Empty: false,
          q39SizeOutOfRange: false,
        }));

        break;
      }

      case "q39_days_txt": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q39step_up: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q39Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q39Empty: false,
          q39SizeOutOfRange: false,
        }));

        break;
      }

      case "siteOfCare_Dose1": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "siteOfCare_Dose2": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "siteOfCare_FullDose": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "q40Discont": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q42DiscontReason: null,
          q41EpDiscontDtUnk: null,
          dt_discont: DefaultDate,
          //  q42DiscontReasonOtherTxt:"",

          epcoDiscontLbcl: false,
          epcoDiscontEligibility: false,
          epcoDiscontTransplant: false,
          epcoDiscontCompleteResponse: false,
          epcoDiscontInadequateResponse: false,
          epcoDiscontCovid19Infection: false,
          epcoDiscontCartTherapy: false,
          epcoDiscontPatientChoiceDecision: false,
          epcoDiscontOther: false,
          epcoDiscontUnknown: false,
          epcoDiscontReasonOtherTxt: "",
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q40Unk_err: Number(value) === 99,
          q41Unk_err: false,
          q42Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q40Empty: false,
          q41Empty: false,
          q42Empty: false,
          q42txtEmpty: false,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));
        break;
      }

      // case "dt_discont":{

      // }

      case "q41EpDiscontDtUnk": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          dt_discont: DefaultDate, //reset q8 date
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q41Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_afterIndex: false,
          q41_date_beforeDeathorChart: false,
        }));
        break;
      }

      //q42

      case "q42DiscontReason": {
        const value_int = Number(value);
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value_int,
          q42DiscontReasonOtherTxt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q42Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q42Empty: false,
          q42txtEmpty: false,
        }));

        // if(value_int === 99)
        // setIsEligible(false);
        break;
      }
      case "q42DiscontReasonOtherTxt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q42DiscontReason: 10,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q42Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q42Empty: false,
          q42txtEmpty: false,
        }));

        break;
      }

      case "dosesOfTocilizumabNumOfDoses": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));


        setDoseOfTocilizumabDetails([{
            ...DoseOfTocilizumabRow
        }]);

        setHardErrList((prev) => ({
            ...prev,
            dosesOfTocilizumabNumOfDoses_Empty: false,
          }));

        setSoftErrList((prev) => ({
        ...prev,
        epco_doses_of_tocilizumab_unknown: Number(value) === 99,
        }));

        break;
      }

      case "epcoDiscontLbcl":
      case "epcoDiscontEligibility":
      case "epcoDiscontTransplant":
      case "epcoDiscontCompleteResponse":
      case "epcoDiscontInadequateResponse":
      case "epcoDiscontCovid19Infection":
      case "epcoDiscontCartTherapy":
      case "epcoDiscontPatientChoiceDecision":
      case "epcoDiscontOther":
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "epcoDiscontOther" && { epcoDiscontReasonOtherTxt: "" }),
          epcoDiscontUnknown: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        break;

      case "epcoDiscontReasonOtherTxt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          epcoDiscontOther: true,
          epcoDiscontUnknown: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        break;
      }

      case "epcoDiscontUnknown": {
        // const value_int = Number(value);
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          //   epcoDiscontReasonOtherTxt: "",
          epcoDiscontLbcl: false,
          epcoDiscontEligibility: false,
          epcoDiscontTransplant: false,
          epcoDiscontCompleteResponse: false,
          epcoDiscontInadequateResponse: false,
          epcoDiscontCovid19Infection: false,
          epcoDiscontCartTherapy: false,
          epcoDiscontPatientChoiceDecision: false,
          epcoDiscontOther: false,
          epcoDiscontReasonOtherTxt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: true,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        // if(value_int === 99)
        // setIsEligible(false);
        break;
      }

      default:
        break;
    }
    // Hide top error message after an option is selected
    setShowTopErrMsg(false);
  };

  const handleParentCheckboxChange = (medicationTypeID) => {
    
    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
       
        selectedOptions_SubSub_Empty: false    ,
    }));

    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: false,
      }));
    
    if(medicationTypeID === 7) {
        setHardErrList((prev) => ({
            ...prev,
           
            selectedOptions_Sub_Empty: false    ,
        epco_sub_othertext_empty: false,

            
        }));
    }
    if(medicationTypeID === 8) {
        setHardErrList((prev) => ({
            ...prev,
           
            epco_parent_othertext_empty: false    ,
            
        }));
    }
    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      parent: {
        ...prevState.parent,
        [medicationTypeID]: !prevState.parent[medicationTypeID],
      },
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {},
      },
    }));
    if (medicationTypeID === 8 && selectedOptions.parent[medicationTypeID]) {
      setOtherInputs((prevState) => ({
        ...prevState,
        parent: "",
      }));
    }

    if (medicationTypeID === 6 ){
    setDoseOfTocilizumabDetails([{
        ...DoseOfTocilizumabRow
    }]);
    setSoftErrList((prev) => ({
      ...prev,
      epco_doses_of_tocilizumab_unknown: false,
    }));
    setHardErrList((prev) => ({
      ...prev,
      dosesOfTocilizumabNumOfDoses_Empty: false,
    }));

    setepcoritamabDetails((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses: null,
      }));
}
  };
  const handleSubCheckboxChange = (medicationTypeID, subMedicationTypeID) => {

    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Sub_Empty: false    ,
        selectedOptions_SubSub_Empty: false    ,
        [subMedicationTypeID]: false,
    }));

    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {
          ...prevState.sub[medicationTypeID],
          [subMedicationTypeID]:
            !prevState.sub[medicationTypeID]?.[subMedicationTypeID],
        },
      },
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {},
      },
    }));
    if (
      subMedicationTypeID === 6 &&
      selectedOptions.sub[medicationTypeID]?.[subMedicationTypeID]
    ) {
      setOtherInputs((prevState) => ({
        ...prevState,
        sub: "",
      }));
      setHardErrList((prev) => ({
        ...prev,
       
        epco_sub_othertext_empty: false,
        
    }));
      
      
      
    }
  };
  
  const handleOtherInputChange = (key, value) => {
   
    
    if (key === "parent") {
      setOtherInput(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_parent_othertext_empty: false,
    }));

    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: false,
      }));

    
    
    }
    if (key === "sub") {
      setSubOtherInputs(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_sub_othertext_empty: false,
        
    }));
    }
    setOtherInputs((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleRadioChange = (e, name) => {
    setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_parent_othertext_empty: false,
        epco_sub_othertext_empty: false,


    }));
    
    setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: name === "Unknown",
      }));


    setRadioSelection(name);
    setSelectedOptions({
      parent: {},
      sub: {},
      subSub: {},
    });
    setOtherInputs((prevState) => ({
        ...prevState,
        parent: "",
        sub: ""
      }));
  };
  const handleSubSubCheckboxChange = (
    subMedicationTypeID,
    subSubMedicationTypeID,
    index
  ) => {
    
   
//     setHardErrList((prev) => {
//     const updatedErrors = [...prev.selectedOptions_SubSub_Empty];
//     updatedErrors[index] = false; // Reset error for the specific index
//     return {
//       ...prev,
//       selectedOptions_SubSub_Empty: updatedErrors,
//     };
//   });

    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {
          ...prevState.subSub[subMedicationTypeID],
          [subSubMedicationTypeID]:
            !prevState.subSub[subMedicationTypeID]?.[subSubMedicationTypeID],
        },
      },
    }));
    setHardErrList((prev) => ({
        ...prev,
        [subMedicationTypeID]: false,
      }));

      
  };

  const SavePage = async (validate) => {
    try {
     
      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      let isValid = validate ? ValidateForm() : true;

      // if (!ValidateForm()) {
      //     setShowTopErrMsg(true);
      //     ToggleLoader(false);
      //     return false;
      // }
      // setShowTopErrMsg(false);

      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        !IsDateValid(epcoritamabDetails.dt_discont)
      ) {
        isValid = false;
      }

      if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
        epcorAssessmentsDetails.map((item) => {
          if (
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateValid(item.radiological_assess_dx_date)
          ) {
            isValid = false;
          }
          return item;
        });
      }


      
            //new code Q50.
            const saveData = [];
    for (const [medicationTypeId, isParentSelected] of Object.entries(
      selectedOptions.parent
    )) {
      if (isParentSelected) {
        const parentRecord = {
          pid,
          MedicationTypeId: parseInt(medicationTypeId),
          SubMedicationTypeId: null,
          SubSubMedicationTypeId: null,
          MedicationTypeOther: medicationTypeId === "8" ? otherInput : null,
        };

        saveData.push(parentRecord);

        if (selectedOptions.sub[medicationTypeId]) {
          for (const [subMedicationTypeId, isSubSelected] of Object.entries(
            selectedOptions.sub[medicationTypeId]
          )) {
            if (isSubSelected) {
              const subRecord = {
                pid,
                MedicationTypeId: parseInt(medicationTypeId),
                SubMedicationTypeId: parseInt(subMedicationTypeId),
                SubSubMedicationTypeId: null,
                SubMedicationTypeOther:
                  subMedicationTypeId === "6" ? subOtherInputs : null,
              };

              saveData.push(subRecord);

              if (selectedOptions.subSub[subMedicationTypeId]) {
                for (const [
                  subSubMedicationTypeId,
                  isSubSubSelected,
                ] of Object.entries(
                  selectedOptions.subSub[subMedicationTypeId]
                )) {
                  if (isSubSubSelected) {
                    const subSubRecord = {
                      pid,
                      MedicationTypeId: parseInt(medicationTypeId),
                      SubMedicationTypeId: parseInt(subMedicationTypeId),
                      SubSubMedicationTypeId: parseInt(subSubMedicationTypeId),
                      MedicationTypeOther: null,
                    };

                    saveData.push(subSubRecord);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Add radio button data (None or Unknown)
    saveData.push({
      pid,
      Medication_none: radioSelection === "None",
      Medication_Unk: radioSelection === "Unknown",
      MedicationTypeId: null,
      SubMedicationTypeId: null,
      SubSubMedicationTypeId: null,
      MedicationTypeOther: null,
      SubMedicationTypes: null,
      SubSubMedicationTypes: null,
    });
    const filteredSaveData = saveData.filter(
      (record) =>
        record.MedicationTypeId !== null ||
        record.MedicationTypeOther !== null ||
        record.Medication_Unk ||
        record.Medication_none ||
        record.SubMedicationTypeId !== null ||
        record.SubSubMedicationTypeId !== null ||
        (record.SubMedicationTypes && record.SubMedicationTypes.length > 0) ||
        (record.SubSubMedicationTypes &&
          record.SubSubMedicationTypes.length > 0)
    );
   




      if (isValid) {
        setShowTopErrMsg(false);
        const epcoritamabModel = {
            SaveMedication: filteredSaveData,
          patientId: pid,

          //question 36
          epcorLot:
            epcoritamabDetails.q36_state === 1
              ? epcoritamabDetails.selectedLOT
              : null,

          //question 37 payload
          receivedMonotherapyTreatment: epcoritamabDetails.q37Mono,
          otherTreatmentLenalidomide: epcoritamabDetails.q37Lena,
          otherTreatmentLoncastuximab: epcoritamabDetails.q37Lonca,
          otherTreatmentRituximab: epcoritamabDetails.q37Ritux,
          otherTreatmentVenetoclax: epcoritamabDetails.q37Vene,
          OtherTreatmentInvestigationalagent: epcoritamabDetails.q37InvestAgent,
          otherTreatmentInvestigationalagentTxt:
            epcoritamabDetails.q37InvestAgent_txt,
          OtherTreatmentOth: epcoritamabDetails.q37Other,
          OtherTreatmentOthTxt: epcoritamabDetails.q37Other_txt,

          //question 38 payload
          dose1and2DaysDifference: epcoritamabDetails.q38_days_txt,
          dose1and2DaysDifferenceUnk:
            epcoritamabDetails.q38step_up === 99 ? true : false,

          //question 39 payload
          dose2and3DaysDifference: epcoritamabDetails.q39_days_txt,
          dose2and3DaysDifferenceUnk:
            epcoritamabDetails.q39step_up === 99 ? true : false,

          //question 40
          discontinueEpcoritamab: epcoritamabDetails.q40Discont,

          //Q40. Site Of Care
          StepupDose1: epcoritamabDetails.siteOfCare_Dose1,
          StepupDose2: epcoritamabDetails.siteOfCare_Dose2,
          FirstFullDose: epcoritamabDetails.siteOfCare_FullDose,

          //43 New question changes payload

          EpcoDiscontLbcl: epcoritamabDetails.epcoDiscontLbcl,
          EpcoDiscontEligibility: epcoritamabDetails.epcoDiscontEligibility,
          EpcoDiscontTransplant: epcoritamabDetails.epcoDiscontTransplant,
          EpcoDiscontCompleteResponse:
            epcoritamabDetails.epcoDiscontCompleteResponse,
          EpcoDiscontInadequateResponse:
            epcoritamabDetails.epcoDiscontInadequateResponse,
          EpcoDiscontCovid19Infection:
            epcoritamabDetails.epcoDiscontCovid19Infection,
          EpcoDiscontCartTherapy: epcoritamabDetails.epcoDiscontCartTherapy,
          EpcoDiscontPatientChoiceDecision:
            epcoritamabDetails.epcoDiscontPatientChoiceDecision,
          EpcoDiscontOther: epcoritamabDetails.epcoDiscontOther,
          EpcoDiscontUnknown: epcoritamabDetails.epcoDiscontUnknown,
          EpcoritamabDiscontReasonOthTxt:
            epcoritamabDetails.epcoDiscontReasonOtherTxt,

          //51 new
          TocilizumabDosesReceived:
            epcoritamabDetails.dosesOfTocilizumabNumOfDoses,

          //question 41
          epcoritamabDiscontDate:
            epcoritamabDetails.q40Discont === 1 &&
            !IsDateEmpty(epcoritamabDetails.dt_discont) &&
            epcoritamabDetails.q41EpDiscontDtUnk === 1
              ? ParseDate(epcoritamabDetails.dt_discont)
              : null,
          epcoritamabDiscontDateDmuid:
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q41EpDiscontDtUnk === 1
              ? GetDMUID(epcoritamabDetails.dt_discont)
              : null,
          epcoritamabDiscontDateUnk:
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q41EpDiscontDtUnk === 99
              ? epcoritamabDetails.q41EpDiscontDtUnk
              : false,

          //question 42

          // epcoritamabDiscontReason: epcoritamabDetails.q42DiscontReason,
          // epcoritamabDiscontReasonOthTxt:
          //     epcoritamabDetails.q42DiscontReason === 10
          //         ? epcoritamabDetails.q42DiscontReasonOtherTxt
          //         : null,

          EpcorDetailsList: [],
          EpcorsiteofcareDetails:[],

          createdby: GetLoggedInUserID() ?? 0,
          editedby: GetLoggedInUserID() ?? 0,
          userId: GetLoggedInUserID(),
          isValidated: validate,
        };

        if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
          epcoritamabModel.EpcorDetailsList = epcorAssessmentsDetails.map(
            (a) => ({
              Epcoritamabradiologicalassessmentid:
                a.epcoritamabradiologicalassessmentid,
              RadiologicalAssessmentDate:
                a.radiological_assess_dx === 1 &&
                !IsDateEmpty(a.radiological_assess_dx_date)
                  ? ParseDate(a.radiological_assess_dx_date)
                  : null,
              RadiologicalAssessmentDateDmuid:
                a.radiological_assess_dx === 1
                  ? GetDMUID(a.radiological_assess_dx_date)
                  : null,
              RadiologicalAssessmentDateUnk: a.radiological_assess_dx === 99,

              LbclDiseaseCategory: a.q44_LBCL_status,
              LbclDiseaseCategoryOthTxt: a.q44_lbcl_oth_txt,

              LyricLuganoAssessment: a.q45_stage_status,
              LyricCriteria:
                a.q45_stage_status === 1 || a.q45_stage_status === 3
                  ? a.q46LyricAssess
                  : null,

              RadiologicalAssessmentType:
                // a.q45_stage_status === 2 || a.q45_stage_status === 4
                //   ?
                a.q47_radiology_type,
              // : null,
              RadiologicalAssessmentTypeOthTxt: a.q47RadioAssessTypeOtherTxt,
            })
          );
        }








        if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
            epcoritamabModel.EpcorsiteofcareDetails = doseOfTocilizumabDetails.map(
              (a) => ({
                Epcoritamabtreatmentsitecarebridgeid:
                  a.epcoritamabtreatmentsitecarebridgeid,
               
                  EpcoritamabSiteofcare:a.siteOfCare_rd,
                EpcoritamabSiteofcareOthTxt: a.siteOfCare_other,
  

              })
            );
          }






        if (pid > 0) {
          
          const response = await TreatmentResponseService.SaveTreatmentResponse(
            epcoritamabModel
          );
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            await LoadData();
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            //All lots removed
            if (epcoritamabDetails.selectedLOT > 1) {
              navigatePaths.nextPage = "/TreatmentsPriorEpcoritamab/1";
            } else {
              navigatePaths.nextPage = "/CARTCellTherapy";
            }
          }
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const LoadData = async () => {
    
    try {
      if (patientId > 0) {
        const response =
          await TreatmentResponseService.GetAllEpcoritamabResponse(patientId);

        if (response?.status === HTTPResponse.OK) {
            setMedicationTypes(response.data.patientMedicationData.medicationTypes);
                    initialSelections(
                        response.data.patientMedicationData.patientTreatment,
                        response.data.patientMedicationData.treatmentMedicationBridges
                      );
            


          const { data } = response;
          setepcoritamabDetails((prev) => ({
            ...prev,
            userId: data.userId,

            selectedLOT: data.epcorLot,
            q36_state: data.epcorLot !== null ? 1 : null,

            q37Mono: data.receivedMonotherapyTreatment === true ? 1 : false,
            q37Lena: data.otherTreatmentLenalidomide,
            q37Lonca: data.otherTreatmentLoncastuximab,
            q37Ritux: data.otherTreatmentRituximab,
            q37Vene: data.otherTreatmentVenetoclax,
            q37InvestAgent: data.otherTreatmentInvestigationalagent,
            q37InvestAgent_txt: data.otherTreatmentInvestigationalagentTxt,
            q37Other: data.otherTreatmentOth,
            q37Other_txt: data.otherTreatmentOthTxt,

            q38step_up:
              data.dose1and2DaysDifference !== null
                ? 1
                : data.dose1and2DaysDifferenceUnk === true
                ? 99
                : null,
            q38_days_txt: data.dose1and2DaysDifference ?? "",

            //  q39step_up: data.dose2and3DaysDifference !== null ? 1 : null,
            //  q39step_up: data.dose2and3DaysDifferenceUnk === true ? 99 : 1,

            q39step_up:
              data.dose2and3DaysDifference !== null
                ? 1
                : data.dose2and3DaysDifferenceUnk === true
                ? 99
                : null,
            q39_days_txt: data.dose2and3DaysDifference ?? "",

            q40Discont:
              data.discontinueEpcoritamab != null
                ? data.discontinueEpcoritamab
                : null,

            dt_discont: !isStringEmpty(data.epcoritamabDiscontDate)
              ? GetDisplayDate(
                  data.epcoritamabDiscontDate,
                  data.epcoritamabDiscontDateDmuid
                )
              : DefaultDate,

            //SiteOfCare Q40. new
            siteOfCare_Dose1: data.stepupDose1,
            siteOfCare_Dose2: data.stepupDose2,
            siteOfCare_FullDose: data.firstFullDose,

            //loadData 43 new

            epcoDiscontLbcl: data.epcoDiscontLbcl,
            epcoDiscontEligibility: data.epcoDiscontEligibility,
            epcoDiscontTransplant: data.epcoDiscontTransplant,
            epcoDiscontCompleteResponse: data.epcoDiscontCompleteResponse,
            epcoDiscontInadequateResponse: data.epcoDiscontInadequateResponse,
            epcoDiscontCovid19Infection: data.epcoDiscontCovid19Infection,
            epcoDiscontCartTherapy: data.epcoDiscontCartTherapy,
            epcoDiscontPatientChoiceDecision:
              data.epcoDiscontPatientChoiceDecision,
            epcoDiscontOther: data.epcoDiscontOther,
            epcoDiscontUnknown: data.epcoDiscontUnknown,
            epcoDiscontReasonOtherTxt: data.epcoritamabDiscontReasonOthTxt,

            //51 new
            dosesOfTocilizumabNumOfDoses: data.tocilizumabDosesReceived,

            q41EpDiscontDtUnk: data.epcoritamabDiscontDateUnk
              ? 99
              : data.epcoritamabDiscontDate != null
              ? 1
              : null,

            q42DiscontReason: data.epcoritamabDiscontReasonOthTxt
              ? 10
              : data.epcoritamabDiscontReason,
            q42DiscontReasonOtherTxt: data.epcoritamabDiscontReasonOthTxt ?? "",
          }));

          //Repeater load data
       
          if (
            data.epcorDetailsList != null &&
            data.epcorDetailsList.length > 0
          ) {
            const { epcorDetailsList } = data;
            const epcorDetailsArray = epcorDetailsList.map((item, index) => {
              const epcoritamabradiologicalassessmentid =
                item.epcoritamabradiologicalassessmentid != null
                  ? item.epcoritamabradiologicalassessmentid
                  : 0;
              const radiological_assess_dx_date = !isStringEmpty(
                item.radiologicalAssessmentDate
              )
                ? GetDisplayDate(
                    item.radiologicalAssessmentDate,
                    item.radiologicalAssessmentDateDmuid
                  )
                : DefaultDate;

              // Determine initial visibility for Q46 and Q47 based on q45_stage_status value
              if (
                item.lyricLuganoAssessment === 1 ||
                item.lyricLuganoAssessment === 3
              ) {
                setShowQ46((prev) => ({ ...prev, [index]: true }));
                //   setShowQ47((prev) => ({ ...prev, [index]: false }));
              } else if (
                item.lyricLuganoAssessment === 2 ||
                item.lyricLuganoAssessment === 4
              ) {
                setShowQ46((prev) => ({ ...prev, [index]: false }));
                //  setShowQ47((prev) => ({ ...prev, [index]: true }));
              }
              return {
                ...EPCORAssesmentRow,
                epcoritamabradiologicalassessmentid,
                radiological_assess_dx: item.radiologicalAssessmentDateUnk
                  ? 99
                  : !isStringEmpty(item.radiologicalAssessmentDate)
                  ? 1
                  : null,
                radiological_assess_dx_date,

                q44_LBCL_status: item.lbclDiseaseCategory,
                q44_lbcl_oth_txt: item.lbclDiseaseCategoryOthTxt,

                q45_stage_status: item.lyricLuganoAssessment,

                q46LyricAssess: item.lyricCriteria,

                q47_radiology_type: item.radiologicalAssessmentType,
                q47RadioAssessTypeOtherTxt:
                  item.radiologicalAssessmentTypeOthTxt,
              };
            });
            setEPCORAssessmentsDetails(epcorDetailsArray);
          }






          if (
            data.epcorsiteofcareDetails != null &&
            data.epcorsiteofcareDetails.length > 0
          ) {
            const { epcorsiteofcareDetails } = data;
            const epcorDetailsListArray = epcorsiteofcareDetails.map((item, index) => {
              const epcoritamabtreatmentsitecarebridgeid =
                item.epcoritamabtreatmentsitecarebridgeid != null
                  ? item.epcoritamabtreatmentsitecarebridgeid
                  : 0;
             

    
              return {
                ...DoseOfTocilizumabRow,
                epcoritamabtreatmentsitecarebridgeid,
                
                siteOfCare_rd: item.epcoritamabSiteofcare,
                siteOfCare_other:
                  item.epcoritamabSiteofcareOthTxt,
              };
            });
            setDoseOfTocilizumabDetails(epcorDetailsListArray);
          }










          if (data.indexDate) {
            
            setIndexDate(data.indexDate);
          }

          if (data.chartAbstractDate) {
            
            setChartAbstractionDate(data.chartAbstractDate);
          }

          if (data.deathDate) {
            
            set_Death_Date(data.deathDate);
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  const HandleDateChange = (name, date, datePart) => {
   
    switch (name) {
      case "dt_discont": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          q41EpDiscontDtUnk: 1,
          dt_discont: SetDateState(prev.dt_discont, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q41Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          // q41_date_invalid1:false,
          q41_date_afterIndex: false,
          q41_date_beforeDeathorChart: false,
        }));
        break;
      }

      default:
        break;
    }
  };

  //const [selectedLOT, setSelectedLOT] = useState(null);
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.EpcoritamabTreatment} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Epcoritamab Treatment</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to provide information
                      on the epcoritamab treatment the patient received. You
                      will also be asked to provide information on any
                      additional treatment received as combination therapy with
                      epcoritamab.
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q36.</span>
                        <span className="quest-text-pad">
                          In which line of therapy (LOT) did the patient receive
                          epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q36Select_1"
                            name="q36_state"
                            value={1}
                            checked={epcoritamabDetails.q36_state === 1}
                            onChange={HandleFieldChange}
                          />

                          {/* <label htmlFor="q36Select_1">
                            Select LOT */}
                          <span className="radio-text-padding ">
                            <Dropdown className="custom-dropdown-caret">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="w110 epcoritamab-custom-dropdown-button"
                              >
                                {epcoritamabDetails.selectedLOT !== null
                                  ? epcoritamabDetails.selectedLOT
                                  : "Select LOT"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="epcoritamab-custom-dropdown-menu">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                                  <Dropdown.Item
                                    as="button"
                                    name="selectedLOT"
                                    key={num}
                                    value={num}
                                    className={`custom-dropdown-item ${
                                      num === epcoritamabDetails.selectedLOT &&
                                      "custom-dropdown-item-selected"
                                    }`}
                                    style={{ width: "100% !important" }}
                                    onClick={HandleFieldChange}
                                  >
                                    {num}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                          {/* </label> */}
                        </div>
                        {/* <div className="">
                          <input
                            type="radio"
                            id="q36Select_2"
                            name="q36_state"
                            value={2}
                            checked={
                              epcoritamabDetails.q36_state === 2
                            }
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q36Select_2">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q36_other_txt"
                                type="text"
                                value={
                                  epcoritamabDetails.q36_other_txt }
                                //maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div> */}
                      </div>
                      <ErrorField
                        show={hardErrList.q36Empty}
                        message={Epcoritamab_Msgs.Q36}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q37.</span>
                        <span className="quest-text-pad">
                          Did the patient receive epcoritamab in combination
                          with any other treatment?{" "}
                          <i>(select all that apply)</i>
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q37Mono"
                            name="q37Mono"
                            value={1}
                            checked={epcoritamabDetails.q37Mono === 1}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Mono">
                            <span className="radio-text-padding">
                              The patient received the treatment as monotherapy
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Lena"
                            name="q37Lena"
                            checked={epcoritamabDetails.q37Lena}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Lena">
                            <span className="radio-text-padding">
                              Lenalidomide
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Lonca"
                            name="q37Lonca"
                            checked={epcoritamabDetails.q37Lonca}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Lonca">
                            <span className="radio-text-padding">
                              Loncastuximab
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Ritux"
                            name="q37Ritux"
                            checked={epcoritamabDetails.q37Ritux}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Ritux">
                            <span className="radio-text-padding">
                              Rituximab, cyclophosphamide, doxorubicin
                              hydrochloride, vincristine and prednisone (R-CHOP)
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Vene"
                            name="q37Vene"
                            checked={epcoritamabDetails.q37Vene}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Vene">
                            <span className="radio-text-padding">
                              Venetoclax
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37InvestAgent"
                            name="q37InvestAgent"
                            checked={epcoritamabDetails.q37InvestAgent}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37InvestAgent">
                            <span className="radio-text-padding">
                              Investigational agent:{" "}
                              <input
                                className="input-dash"
                                name="q37InvestAgent_txt"
                                type="text"
                                value={epcoritamabDetails.q37InvestAgent_txt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="raceOther"
                            name="q37Other"
                            checked={epcoritamabDetails.q37Other}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="raceOther">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q37Other_txt"
                                type="text"
                                value={epcoritamabDetails.q37Other_txt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                      </div>

                      <ErrorField
                        show={hardErrList.q37Empty}
                        message={Epcoritamab_Msgs.Q37}
                        style={{ paddingLeft: "24px" }}
                      />
                      <ErrorField
                        show={hardErrList.q37EmptyagentTxt}
                        message="Please enter Investigational agent."
                      />
                      <ErrorField
                        show={hardErrList.q37EmptyOtherTxt}
                        message="Please specify other text."
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q38.</span>
                        <span className="quest-text-pad">
                          How many days were there between the patient’s
                          epcoritamab step up dose 1 and 2?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q38Select_1"
                            name="q38step_up"
                            value={1}
                            checked={epcoritamabDetails.q38step_up === 1}
                            onChange={HandleFieldChange}
                          />
                          {/* <label htmlFor="q38Select_1">
                            <span className="radio-text-padding">Days:</span>
                          </label> */}
                          <span className="radio-text-padding">
                            <input
                              className="sub-input-dash"
                              name="q38_days_txt"
                              type="text"
                              value={epcoritamabDetails.q38_days_txt}
                              maxLength={100}
                              onChange={HandleFieldChange}
                            />
                          </span>{" "}
                          days
                        </div>
                        <div className="">
                        <label>

                          <input
                            type="radio"
                            id="q38Unk_99"
                            name="q38step_up"
                            value={99}
                            checked={epcoritamabDetails.q38step_up === 99}
                            onChange={HandleFieldChange}
                          />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                      <ErrorField
                        show={softErrList.q38Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q38Empty}
                        message={Epcoritamab_Msgs.Q38}
                      />

                      <ErrorField
                        show={hardErrList.q38SizeOutOfRange}
                        message={Epcoritamab_Msgs.DaysRangeLimit}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q39.</span>
                        <span className="quest-text-pad">
                          How many days were there between the patient’s
                          epcoritamab step up dose 2 and 3 (first full dose)?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q39Select_1"
                            name="q39step_up"
                            value={1}
                            checked={epcoritamabDetails.q39step_up === 1}
                            onChange={HandleFieldChange}
                          />
                          {/* <label htmlFor="q39Select_1">
                            <span className="radio-text-padding">Days:</span>
                          </label>  */}
                          <span className="radio-text-padding">
                            <input
                              className="sub-input-dash"
                              name="q39_days_txt"
                              type="text"
                              value={epcoritamabDetails.q39_days_txt}
                              maxLength={100}
                              onChange={HandleFieldChange}
                            />
                          </span>{" "}
                          days
                        </div>
                        <div className="">
                        <label>

                          <input
                            type="radio"
                            id="q39Unk_99"
                            name="q39step_up"
                            value={99}
                            checked={epcoritamabDetails.q39step_up === 99}
                            onChange={HandleFieldChange}
                          />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                      <ErrorField
                        show={softErrList.q39Unk_err}
                        message={CommonMsgs.Unknown}
                      />

                      <ErrorField
                        show={hardErrList.q39Empty}
                        message={Epcoritamab_Msgs.Q39}
                      />
                      <ErrorField
                        show={hardErrList.q39SizeOutOfRange}
                        message={Epcoritamab_Msgs.DaysRangeLimit}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q40.</span>
                        <span className="quest-text-pad">
                          Please select the site of care for each dose:
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <table className="styled-table">
                          <tbody>
                            <tr>
                              <td>
                                <b>Site of care</b>
                              </td>
                              <td>
                                <b>Outpatient</b>
                              </td>
                              <td>
                                <b>Inpatient</b>
                              </td>
                              <td>
                                <b>Unknown</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Step up dose 1</td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose1"
                                  id="siteOfCare_Dose1_Out"
                                  value={1}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose1 === 1
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose1"
                                  id="siteOfCare_Dose1_In"
                                  value={2}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose1 === 2
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose1"
                                  id="siteOfCare_Dose1_Unk"
                                  value={99}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose1 === 99
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Step up dose 2</td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose2"
                                  id="siteOfCare_Dose2_Out"
                                  value={1}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose2 === 1
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose2"
                                  id="siteOfCare_Dose2_In"
                                  value={2}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose2 === 2
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_Dose2"
                                  id="siteOfCare_Dose2_Unk"
                                  value={99}
                                  checked={
                                    epcoritamabDetails.siteOfCare_Dose2 === 99
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>First full dose</td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_FullDose"
                                  id="siteOfCare_FullDose_Out"
                                  value={1}
                                  checked={
                                    epcoritamabDetails.siteOfCare_FullDose === 1
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_FullDose"
                                  id="siteOfCare_FullDose_In"
                                  value={2}
                                  checked={
                                    epcoritamabDetails.siteOfCare_FullDose === 2
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="siteOfCare_FullDose"
                                  id="siteOfCare_FullDose_Unk"
                                  value={99}
                                  checked={
                                    epcoritamabDetails.siteOfCare_FullDose ===
                                    99
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={hardErrList.SiteOfCareEmpty}
                        message={Epcoritamab_Msgs.epco_site_of_care_for_each_dose_empty}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q41.</span>
                        <span className="quest-text-pad">
                          Did the patient discontinue epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                        <label>

                          <input
                            type="radio"
                            id="q40DiscontSelect_1"
                            name="q40Discont"
                            value={1}
                            // checked={true}
                            checked={epcoritamabDetails.q40Discont === 1}
                            onChange={HandleFieldChange}
                          />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>

                          <input
                            type="radio"
                            id="q40DiscontSelect_0"
                            name="q40Discont"
                            value={0}
                            checked={epcoritamabDetails.q40Discont === 0}
                            onChange={HandleFieldChange}
                          />
                            <span className="radio-text-padding">
                              No, the patient is still on treatment
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label>

                          <input
                            type="radio"
                            id="q40DiscontSelect_99"
                            name="q40Discont"
                            value={99}
                            checked={epcoritamabDetails.q40Discont === 99}
                            onChange={HandleFieldChange}
                          />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={softErrList.q40Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q40Empty}
                        message={Epcoritamab_Msgs.Q40}
                      />
                    </div>

                    {epcoritamabDetails.q40Discont === 1 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q42.</span>
                          <span className="quest-text-pad">
                            What was the date of epcoritamab discontinuation?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="q41EpDiscontDtUnk_1"
                              name="q41EpDiscontDtUnk"
                              value={1}
                              checked={
                                epcoritamabDetails.q41EpDiscontDtUnk === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"dt_discont"}
                                HandleDateChange={HandleDateChange}
                                date={epcoritamabDetails.dt_discont}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>
                                If the exact month and/or day is unknown, please
                                enter UNK.
                              </i> */}
                            </span>
                          </div>
                          <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q41EpDiscontDtUnk_99"
                              name="q41EpDiscontDtUnk"
                              value={99}
                              checked={
                                epcoritamabDetails.q41EpDiscontDtUnk === 99
                              }
                              onChange={HandleFieldChange}
                            />
                            
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>

                        <ErrorField
                          show={softErrList.q41Unk_err}
                          message={CommonMsgs.Unknown}
                        />

                        <ErrorField
                          show={hardErrList.q41Empty}
                          message={Epcoritamab_Msgs.Q41}
                        />
                        <ErrorField
                          show={hardErrList.q41_date_afterIndex}
                          message={Epcoritamab_Msgs.Q41_Invalid1}
                        />
                        <ErrorField
                          show={hardErrList.q41_date_beforeDeathorChart}
                          message={Epcoritamab_Msgs.Q41_Invalid2}
                        />
                      </div>
                    )}
                    {epcoritamabDetails.q40Discont === 1 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q43.</span>
                          <span className="quest-text-pad">
                            What was the reason for discontinuation of
                            epcoritamab? (select all that apply)
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontLbcl_Select_1"
                                name="epcoDiscontLbcl"
                                value={1}
                                checked={epcoritamabDetails.epcoDiscontLbcl}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                LBCL disease progression
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontEligibility_Select_2"
                                name="epcoDiscontEligibility"
                                value={2}
                                checked={
                                  epcoritamabDetails.epcoDiscontEligibility
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Eligibility for clinical trial treatment
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontTransplant_Select_3"
                                name="epcoDiscontTransplant"
                                value={3}
                                checked={
                                  epcoritamabDetails.epcoDiscontTransplant
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Decision to proceed with transplant
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCompleteResponse_Select_4"
                                name="epcoDiscontCompleteResponse"
                                value={4}
                                checked={
                                  epcoritamabDetails.epcoDiscontCompleteResponse
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Clinical decision as patient experienced
                                complete response
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontInadequateResponse_Select_5"
                                name="epcoDiscontInadequateResponse"
                                value={5}
                                checked={
                                  epcoritamabDetails.epcoDiscontInadequateResponse
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Physician decision due to inadequate response
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCovid19Infection_Select_6"
                                name="epcoDiscontCovid19Infection"
                                value={6}
                                checked={
                                  epcoritamabDetails.epcoDiscontCovid19Infection
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                COVID-19 infection
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCartTherapy_Select_7"
                                name="epcoDiscontCartTherapy"
                                value={7}
                                checked={
                                  epcoritamabDetails.epcoDiscontCartTherapy
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Decided to proceed with CAR T cell therapy
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontPatientChoiceDecision_Select_8"
                                name="epcoDiscontPatientChoiceDecision"
                                value={8}
                                checked={
                                  epcoritamabDetails.epcoDiscontPatientChoiceDecision
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Patient’s choice/decision
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontOther_Select_9"
                                name="epcoDiscontOther"
                                value={9}
                                checked={epcoritamabDetails.epcoDiscontOther}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Other, specify:{" "}
                                <input
                                  className="input-dash"
                                  name="epcoDiscontReasonOtherTxt"
                                  type="text"
                                  maxLength={100}
                                  value={
                                    epcoritamabDetails.epcoDiscontReasonOtherTxt
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="epcoDiscontUnknown_99"
                                name="epcoDiscontUnknown"
                                //   value={99}
                                checked={epcoritamabDetails.epcoDiscontUnknown}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>

                        <ErrorField
                          show={hardErrList.epco_discont_reasonEmpty}
                          message={Epcoritamab_Msgs.epco_discont_reasonEmpty}
                        />
                        <ErrorField
                          show={softErrList.epco_discont_unk_err}
                          message={CommonMsgs.Unknown}
                        />
                        <ErrorField
                          show={hardErrList.epco_discont_reasonEmpty_txtEmpty}
                          message={Epcoritamab_Msgs.q47TxtEmpty}
                        />
                      </div>
                    )}
                    {/* Set 1 start */}

                    <div className="dependent-section">
                      {epcorAssessmentsDetails &&
                        epcorAssessmentsDetails.length > 0 &&
                        epcorAssessmentsDetails.map((data, idx) => (
                          <div className="loop-section-ul">
                            <div className="loop-head">
                              {/* <span className="question-weight">1<sup>st</sup> Radiological Assessment</span> */}
                              <span className="question-weight">
                                {GetOrdinal(idx + 1)} Radiological Assessment
                              </span>
                              {idx != 0 && (
                                <div className="delete-btn-div">
                                  <img
                                    width="16px"
                                    src="../Assets/images/Icon-material-delete.png"
                                    alt=""
                                    onClick={() =>
                                      DeleteEPCORAssessmentRow(idx)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q44.</span>
                                <span className="quest-text-pad">
                                  What was the date of the{" "}
                                  {GetOrdinalInWord(idx + 1)} radiological
                                  assessment after the initiation of epcoritamab on  {""}
                                  <span
                                  style={{ color: PageDesignConstants.fontColor }}
                                >
                                  {index_date}
                                </span>
                                  ?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="date-field-padding">
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"radiological_assess_dx" + idx}
                                    value={1}
                                    checked={data.radiological_assess_dx === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId={"radiological_dx_date-" + idx}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      date={data.radiological_assess_dx_date}
                                      showUnkInfoTxt={true}
                                    />
                                    {/* <i>
                                      If the exact month and/or day is unknown,
                                      please enter UNK.
                                    </i> */}
                                  </span>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"radiological_assess_dx" + idx}
                                      value={99}
                                      checked={
                                        data.radiological_assess_dx === 99
                                      }
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.radiologyDxDateUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.radiologyDxEmptyHard}
                                message={Epcoritamab_Msgs.radiologyDxEmpty.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />

                              <ErrorField
                                show={data.q43afterindexEmptyHard}
                                message={Epcoritamab_Msgs.After_index_dt.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                              <ErrorField
                                show={data.q43beforeQ41dateEmptyHard}
                                message={Epcoritamab_Msgs.Before_discnt_dt.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q45.</span>
                                <span className="quest-text-pad">
                                  Which of the following categories best
                                  describes the patient’s LBCL disease status at
                                  this radiological assessment? This can be
                                  based on the conclusion of the radiologic
                                  report or physician’s notes in the chart.
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={1}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 1}
                                    />

                                    <span className="radio-text-padding">
                                      Clinician-assessed complete response
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={2}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 2}
                                    />

                                    <span className="radio-text-padding">
                                      Clinician-assessed less-than-complete
                                      response (significant tumor shrinkage)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label className="long-text-option">
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={3}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 3}
                                    />
                                    <span className="radio-text-padding">
                                      Clinician-assessed stable disease (may
                                      include minimal increases or decreases in
                                      size of tumor or permitting ongoing
                                      systemic therapy)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q44_LBCL_status" + idx}
                                    value={4}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q44_LBCL_status === 4}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Progressive disease
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q44_LBCL_status" + idx}
                                    value={5}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q44_LBCL_status === 5}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"q44_lbcl_oth_txt"}
                                        id={idx}
                                        type="text"
                                        maxLength={100}
                                        value={data.q44_lbcl_oth_txt}
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q44_LBCL_status" + idx}
                                    value={99}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q44_LBCL_status === 99}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q44StatusEmptyHard}
                                message={Epcoritamab_Msgs.q44HardEmpty}
                              />
                              <ErrorField
                                show={data.q44StatusUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q44lbclTxtEmptyHard}
                                message={Epcoritamab_Msgs.q44lbclTxtEmpty}
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q46.</span>
                                <span className="quest-text-pad">
                                  Was the disease stage assessed per LYRIC or
                                  Lugano criteria?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={1}
                                      checked={data.q45_stage_status === 1}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      LYRIC
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={2}
                                      checked={data.q45_stage_status === 2}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Lugano
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={3}
                                      checked={data.q45_stage_status === 3}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      LYRIC and Lugano
                                    </span>
                                  </label>
                                </div>




                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={99}
                                      checked={data.q45_stage_status === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>














                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={4}
                                      checked={data.q45_stage_status === 4}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Neither
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q45statusEmptyHard}
                                message={Epcoritamab_Msgs.q45StatusEmpty}
                              />
                                   <ErrorField
                                show={data.q46UnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                            </div>
                            {showQ46[idx] && (
                              <div className="sub-question-bot-sapce answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q47.</span>
                                  <span className="quest-text-pad">
                                    What was the disease assessment per LYRIC
                                    criteria?
                                  </span>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={1}
                                        checked={data.q46LyricAssess === 1}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR1
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={2}
                                        checked={data.q46LyricAssess === 2}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR2
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={3}
                                        checked={data.q46LyricAssess === 3}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR1 and IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={4}
                                        checked={data.q46LyricAssess === 4}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR2 and IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={5}
                                        checked={data.q46LyricAssess === 5}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={6}
                                        checked={data.q46LyricAssess === 6}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Stable disease
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={7}
                                        checked={data.q46LyricAssess === 7}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Partial response
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={8}
                                        checked={data.q46LyricAssess === 8}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Complete response
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={9}
                                        checked={data.q46LyricAssess === 9}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Progressive disease
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={data.q46LyricEmptyHard}
                                  message={Epcoritamab_Msgs.q46LyricEmptyHard}
                                />
                              </div>
                            )}

                            {/* {showQ47[idx] && ( */}
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q48.</span>
                                <span className="quest-text-pad">
                                  What type of radiological assessment was used?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={1}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 1}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      PET or PET/CT
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={2}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 2}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      CT
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={3}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 3}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Other imaging
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={4}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 4}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Clinical only (no imaging)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={5}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 5}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"q47RadioAssessTypeOtherTxt"}
                                        id={idx}
                                        type="text"
                                        maxLength={100}
                                        value={data.q47RadioAssessTypeOtherTxt}
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                <label>
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q47_radiology_type" + idx}
                                    value={99}
                                    onChange={HandleRepeaterFieldChange}
                                    checked={data.q47_radiology_type === 99}
                                  />
                                  
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q47radiologyTypeEmptyHard}
                                message={Epcoritamab_Msgs.q47HardEmpty}
                              />
                              <ErrorField
                                show={data.q47radiologyTypeUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q47radiologyTypeTxtEmptyHard}
                                message={Epcoritamab_Msgs.q47TxtEmpty}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        ))}
                      {epcorAssessmentsDetails &&
                        epcorAssessmentsDetails.length < 8 && (
                          <div className="sub-question-bot-sapce-ul mt-2">
                            <div
                              className="answer-list-text"
                              
                            >
                              <a onClick={AddEPCORAssessmentRow}>
                                <img
                                  width="18px"
                                  src="../Assets/images/plus.png"
                                  alt=""
                                />
                              </a>
                              <a onClick={AddEPCORAssessmentRow} className="radio-text-padding">
                                Click here to add another radiological
                                assessment
                              </a>
                            </div>
                          </div>
                        )}

                      {/* Set 1 end */}
                    </div>

{/* Q50. */}
<div className="sub-question-bot-sapce answer-pad-left">
  <div className="question question-weight">
    <span>Q50.</span>
    <span className="quest-text-pad">
      Did the patient receive any of the following supportive care or
      concomitant medication in addition to epcoritamab?
    </span>
  </div>
  <div className="double-dig-answer">
    {medicationTypes.map((medType) => (
      <div key={medType.medicationTypeID}>
        <label>
          <input
            type="checkbox"
            id={`medType-${medType.medicationTypeID}`}
            checked={!!selectedOptions.parent[medType.medicationTypeID]}
            onChange={() => handleParentCheckboxChange(medType.medicationTypeID)}
          />
          <span className="radio-text-padding">{medType.name}</span>
        

        {medType.medicationTypeID === 8 &&
        //   selectedOptions.parent[medType.medicationTypeID] && 
          (
            <>
              , specify:
              <input
                type="text"
                className="input-dash"
                value={otherInputs["parent"] || ""}
                onChange={(e) =>{
                  handleOtherInputChange("parent", e.target.value)
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    parent: {
                      ...prevState.parent,
                      [medType.medicationTypeID]: true,
                    },
                    
                  }));
                  setRadioSelection("");
                }
                }
                placeholder=""
                maxLength={100}
              />
            </>
          )}
          </label>

        {selectedOptions.parent[medType.medicationTypeID] &&
          medType.subMedicationTypes && (
            <div className="child-checkbox">
              {medType.subMedicationTypes.map((subMedType) => (
                <div key={subMedType.subMedicationTypeID}>
                  <label>
                    <input
                      type="checkbox"
                      id={`subMedType-${subMedType.subMedicationTypeID}`}
                      checked={
                        !!selectedOptions.sub[medType.medicationTypeID]?.[
                          subMedType.subMedicationTypeID
                        ]
                      }
                      onChange={() =>
                        handleSubCheckboxChange(
                          medType.medicationTypeID,
                          subMedType.subMedicationTypeID
                        )
                      }
                    />
                    <span className="radio-text-padding">
                      {subMedType.name}
                    </span>
                  

                  {subMedType.subMedicationTypeID === 6 &&
                    // selectedOptions.sub[medType.medicationTypeID]?.[
                    //   subMedType.subMedicationTypeID
                    // ] && 
                    (
                      <>
                        , Specify:
                        <input
                          type="text"
                          className="input-dash"
                          value={otherInputs["sub"] || ""}
                          onChange={(e) => {
                            handleOtherInputChange("sub", e.target.value);
                            setSelectedOptions((prevState) => ({
                              ...prevState,
                              sub: {
                                ...prevState.sub,
                                [medType.medicationTypeID]: {
                                  ...prevState.sub[medType.medicationTypeID],
                                  [subMedType.subMedicationTypeID]: true,
                                },
                              },
                            }));
                            setRadioSelection("");
                          }}
                          
                          placeholder=""
                          maxLength={100}
                        />
                      </>
                    )
                    }
                    </label>

                  {selectedOptions.sub[medType.medicationTypeID]?.[
                    subMedType.subMedicationTypeID
                  ] &&
                    subMedType.subSubMedicationTypes && (
                      <div className="child-checkbox">
                        {subMedType.subSubMedicationTypes.map(
                          (subSubMedType,index) => (
                            <div key={subSubMedType.subSubMedicationTypeID}>
                                
                              <label>
                                <input
                                  type="checkbox"
                                  id={`subSubMedType-${subSubMedType.subSubMedicationTypeID}`}
                                  checked={
                                    !!selectedOptions.subSub[
                                      subMedType.subMedicationTypeID
                                    ]?.[
                                      subSubMedType.subSubMedicationTypeID
                                    ]
                                  }
                                  onChange={() =>
                                    handleSubSubCheckboxChange(
                                      subMedType.subMedicationTypeID,
                                      subSubMedType.subSubMedicationTypeID,
                                      index 
                                    )
                                  }
                                />
                                <span className="radio-text-padding">
                                  {subSubMedType.name}
                                </span>
                              </label>
                            </div>

                            
                          )
                          
                        )}
                        
                        <ErrorField
                        show={hardErrList[subMedType.subMedicationTypeID]}
                        message={Epcoritamab_Msgs.subsub}
                        />
                        

                      </div>
                      
                    )}
                    

                </div>
              ))}
               
            </div>
          )}
      </div>
    ))}
<ErrorField
        show={hardErrList.epco_parent_othertext_empty}
        message={Epcoritamab_Msgs.epco_parent_othertext_empty}
    />
    <div>
      <label>
        <input
          type="radio"
          value="None"
          checked={radioSelection === "None"}
          onChange={(e) => handleRadioChange(e, "None")}
        />
        <span className="radio-text-padding">None</span>
      </label>
    </div>

    <div>
      <label>
        <input
          type="radio"
          value="Unknown"
          checked={radioSelection === "Unknown"}
          onChange={(e) => handleRadioChange(e, "Unknown")}
        />
        <span className="radio-text-padding">Unknown</span>
      </label>
    </div>
  </div>
  <ErrorField
                    show={hardErrList.selectedOptions_Sub_Empty}
                    message={Epcoritamab_Msgs.sub}
                />
  <ErrorField
        show={hardErrList.selectedOptions_Empty}
        message={Epcoritamab_Msgs.parent}
    />
    <ErrorField
                                      show={softErrList.epco_parent_unknown}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                                                            <ErrorField
                        show={hardErrList.epco_sub_othertext_empty}
                        message={Epcoritamab_Msgs.epco_sub_othertext_empty}
                        />
                                                           
                                                            
</div>
{selectedOptions.parent[6] === true && (<>

                    {/* Q51. */}
                    <div className="sub-question-bot-sapce answer-pad-left">
                      <div className="question question-weight">
                        <span>Q51.</span>
                        <span className="quest-text-pad">
                        Did the patient receive any doses of tocilizumab?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div>
                          <label>
                            <input
                              type="radio"
                              value={1}
                              checked={
                                epcoritamabDetails.dosesOfTocilizumabNumOfDoses ===
                                1
                              }
                              name="dosesOfTocilizumabNumOfDoses"
                              id="dosesOfTocilizumabNumOfDoses_Yes"
                              onClick={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              value={99}
                              name="dosesOfTocilizumabNumOfDoses"
                              id="dosesOfTocilizumabNumOfDoses_Unk"
                              checked={
                                epcoritamabDetails.dosesOfTocilizumabNumOfDoses ===
                                99
                              }
                              onClick={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                        {/* <ErrorField
                        show={softErrList.q40Unk_err}
                        message={CommonMsgs.Unknown}
                      /> */}
                      <ErrorField
                        show={hardErrList.dosesOfTocilizumabNumOfDoses_Empty}
                        message={Epcoritamab_Msgs.epco_doses_of_tocilizumab_empty}
                      />
                      <ErrorField
                                      show={softErrList.epco_doses_of_tocilizumab_unknown}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                        {/* <ErrorField
                                                                show={hardErrList.dosesOfTocilizumabNumOfDosesEmpty}
                                                                message={"Please choose one of the options."}
                                                            />

                                    <ErrorField
                                      show={softErrList.dosesOfTocilizumabNumOfDosesUnk_err}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            /> */}
                      </div>
                    </div>

                          {/* loop 52 */}
                          {epcoritamabDetails.dosesOfTocilizumabNumOfDoses === 1 && (
                            <div className="dependent-section">
                              {doseOfTocilizumabDetails &&
                                doseOfTocilizumabDetails.length > 0 &&
                                doseOfTocilizumabDetails.map((data, index) => (
                                  <div key={index} className="loop-section-ul">
                                    <div className="loop-head">
                                      <span className="question-weight">
                                        {GetOrdinal(index + 1)} Dose
                                      </span>
                                      {index !== 0 && (
                                        <div className="delete-btn-div">
                                          <img
                                            width="16px"
                                            src="../Assets/images/Icon-material-delete.png"
                                            alt=""
                                            onClick={() =>
                                              DeleteDoseOfTocilizumabRow(index)
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* Q52. */}
                                    <div className="sub-question-bot-sapce answer-pad-left">
                                      <div className="question question-weight">
                                        <span>Q52.</span>
                                        <span className="quest-text-pad">
                                          What best describes the site of care
                                          where the patient received the{" "}
                                          <span className="question-weight">
                                        {GetOrdinal(index + 1)} 
                                        {" "}
                                      </span>
                                          tocilizumab?
                                        </span>
                                      </div>
                                      <div className="double-dig-answer">
                                        <div>
                                          <label>
                                            <input
                                              type="radio"
                                              value={1}
                                              name={"siteOfCare_rd" + index}
                                              checked={data.siteOfCare_rd === 1}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              id={index}
                                            />
                                            <span className="radio-text-padding">
                                              Outpatient clinic
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input
                                              type="radio"
                                              value={2}
                                              name={"siteOfCare_rd" + index}
                                              checked={data.siteOfCare_rd === 2}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              id={index}
                                            />
                                            <span className="radio-text-padding">
                                              Hospital
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input
                                              type="radio"
                                              value={3}
                                              name={"siteOfCare_rd" + index}
                                              checked={data.siteOfCare_rd === 3}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              id={index}
                                            />
                                            <span className="radio-text-padding">
                                              At home
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input
                                              type="radio"
                                              value={4}
                                              name={"siteOfCare_rd" + index}
                                              checked={data.siteOfCare_rd === 4}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              id={index}
                                            />
                                            <span className="radio-text-padding">
                                              Other, specify:{" "}
                                              <input
                                                className="input-dash"
                                                name={"siteOfCare_other"}
                                                value={data.siteOfCare_other}
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                                type="text"
                                                maxLength={100}
                                                id={index}
                                              />
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <input
                                              type="radio"
                                              value={99}
                                              name={"siteOfCare_rd" + index}
                                              checked={
                                                data.siteOfCare_rd === 99
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              id={index}
                                            />
                                            <span className="radio-text-padding">
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <ErrorField
                                      show={data.siteOfCare_other_unk_soft}
                                      message={CommonError_Msgs.UnknownSelect}
                                    />
                                    <ErrorField
                                      show={data.siteOfCare_rd_empty}
                                      message={
                                        Epcoritamab_Msgs.epco_siteofcare
                                      }
                                    />
                                    <ErrorField
                                      show={data.siteOfCare_other_empty}
                                      message={
                                        Epcoritamab_Msgs.epco_siteofcare_othertext
                                      }
                                    />
                                  </div>
                                ))}
                              {doseOfTocilizumabDetails &&
                                doseOfTocilizumabDetails.length < 10 && (
                                  <div className="sub-question-bot-sapce-ul mt-2">
                                    <div
                                      className="answer-list-text"
                                      
                                    >
                                      <a onClick={AddDoseOfTocilizumabRow}>
                                        <img
                                          width="18px"
                                          src="../Assets/images/plus.png"
                                          alt=""
                                        />
                                      </a>
                                      <a onClick={AddDoseOfTocilizumabRow} className="radio-text-padding">
                                        Click here to add another dose of Tocilizumab
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}

</>)}
                        
                       
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Epcoritamab;
